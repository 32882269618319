import {Menu} from 'react-admin'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {useCallback, useState} from 'react'
import {Tooltip} from '@mui/material'
import {SubMenu} from './components/common/submenu/submenu'
import BorderAllIcon from '@mui/icons-material/BorderAll'
import {useNotRMAdmin} from './hooks/common'
export const MyMenu = (): JSX.Element => {
    const [fixed, setFixed] = useState(false)
    const fixedHandle = useCallback(() => setFixed(true), [])
    const unfixedHandle = useCallback(() => setFixed(false), [])
    const notRMAdmin = useNotRMAdmin()
    return (
        <div className={`side-bar ${fixed && 'opened'}`}>
            <Tooltip title={'Фиксация меню'} placement={'top'}>
                <div className={'fix-wrapper'}>
                    {fixed ? (
                        <LockIcon color={'primary'} className={'fix-icon'} onClick={unfixedHandle} />
                    ) : (
                        <LockOpenIcon color={'action'} className={'fix-icon'} onClick={fixedHandle} />
                    )}
                </div>
            </Tooltip>
            <div className={'menu'}>
                <Menu.ResourceItem name="users" />
                <Menu.ResourceItem name="partners" />
                <Menu.ResourceItem name="dealers" />
                <Menu.ResourceItem name="amegaemployees" />
                <Menu.ResourceItem name="filials" />
                <Menu.ResourceItem name="orders" />
                <Menu.ResourceItem name="accountoperations" />
                <Menu.ResourceItem name="useractions" />
                <Menu.ResourceItem name="alerts" />
                <Menu.ResourceItem name="adminauditevents" />
                <Menu.ResourceItem name={'userparams'} />
                <Menu.ResourceItem name="amqphandlers" />
                <Menu.ResourceItem name="windrawservices" />
                {notRMAdmin && (
                    <SubMenu dense icon={BorderAllIcon} title={'Стеклопакеты'}>
                        <Menu.ResourceItem name="tintfilms" />
                        <Menu.ResourceItem name="constrglasses" />
                        <Menu.ResourceItem name="framespacers" />
                    </SubMenu>
                )}
                <Menu.ResourceItem name="reclamations" />
            </div>
        </div>
    )
}
