import * as React from 'react'
import {memo, useMemo} from 'react'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import {useRecordContext} from 'ra-core'
import {sanitizeFieldRestProps, TextFieldProps} from 'react-admin'

interface ICustomTextFieldProps extends TextFieldProps {
    source?: string
    emptyText?: string
    className?: string
}
export const CustomTextField = memo((props: ICustomTextFieldProps) => {
    const {className, source, emptyText, ...rest} = props
    const record = useRecordContext(props)
    const value = useMemo(() => {
        const sourceValue = get(record, source)
        const value = sourceValue != null && typeof sourceValue !== 'string' ? JSON.stringify(sourceValue) : sourceValue || emptyText
        return value.replace(/[,| ]/gm, ', ')
    }, [emptyText, record, source])

    return (
        <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
            {value}
        </Typography>
    )
})

// what? TypeScript loses the displayName if we don't set it explicitly
CustomTextField.displayName = 'CustomTextField'
