import {Coordinates} from '../models/coordinates'
import {DADATA_API_TOKEN} from '../consts/const'
const API = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs'
const API_SUGGEST = `${API}/suggest/address`
const API_GEOLOCATE = `${API}/geolocate/address`

export interface IDadataSuggestion {
    value: string
    unrestricted_value: string
    data: IDadataAddress
}

export interface IDadataAddress {
    area: string
    area_fias_id: string
    area_kladr_id: string
    area_type: string
    area_type_full: string
    area_with_type: string
    beltway_distance: null
    beltway_hit: null
    block: string
    block_type: string
    block_type_full: string
    capital_marker: '0' | '1' | '2' | '3' | '4'
    city: string
    city_area: string
    city_district: string
    city_district_fias_id: string
    city_district_kladr_id: string
    city_district_type: string
    city_district_type_full: string
    city_district_with_type: string
    city_fias_id: string
    city_kladr_id: string
    city_type: string
    city_type_full: string
    city_with_type: string
    country: string
    fias_id: string
    fias_level: string
    flat: string
    flat_area: null
    flat_price: null
    flat_type: string
    flat_type_full: string
    geo_lat: string
    geo_lon: string
    history_values: string
    house: string
    house_fias_id: string
    house_kladr_id: string
    house_type: string
    house_type_full: string
    kladr_id: string
    okato: string
    oktmo: string
    postal_box: string
    postal_code: string
    qc: null
    qc_complete: null
    qc_geo: '0' | '1' | '2' | '3' | '4' | '5'
    qc_house: null
    region: string
    region_fias_id: string
    region_kladr_id: string
    region_type: string
    region_type_full: string
    region_with_type: string
    settlement: string
    settlement_fias_id: string
    settlement_kladr_id: string
    settlement_type: string
    settlement_type_full: string
    settlement_with_type: string
    source: string
    square_meter_price: null
    street: string
    street_fias_id: string
    street_kladr_id: string
    street_type: string
    street_type_full: string
    street_with_type: string
    tax_office: string
    tax_office_legal: string
    timezone: null
    unparsed_parts: null
}

export class KladrManager {
    static async getSuggestions(query: string, kladrId: string | null): Promise<IDadataSuggestion[]> {
        const response = await fetch(API_SUGGEST, {
            method: 'POST',
            headers: {
                Authorization: `Token ${DADATA_API_TOKEN}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query,
                count: 20,
                locations: [
                    {
                        kladr_id: kladrId
                    }
                ]
            })
        })

        const json = await response.json()

        return Promise.resolve(json.suggestions)
    }

    static async getSuggestionsByAdress(query: string): Promise<IDadataSuggestion[]> {
        const response = await fetch(API_SUGGEST, {
            method: 'POST',
            headers: {
                Authorization: `Token ${DADATA_API_TOKEN}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query,
                count: 1
            })
        })

        const json = await response.json()

        return Promise.resolve(json.suggestions)
    }

    static async getSuggestionsByCoords(coords: Coordinates): Promise<IDadataSuggestion[]> {
        const response = await fetch(API_GEOLOCATE, {
            method: 'POST',
            headers: {
                Authorization: `Token ${DADATA_API_TOKEN}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lat: coords.latitude,
                lon: coords.longitude,
                radius_meters: 50,
                count: 20
            })
        })

        const json = await response.json()

        return Promise.resolve(json.suggestions)
    }
}
