import {Datagrid, DateField, FunctionField, List, ListProps, ReferenceField} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {useEventTypes} from '../../../hooks/audit_events'
import {AuditEvent} from '../../../models/audit-event'
import {AuditEventsFilter} from './filter'
import {AuditEventsActions} from './actions'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const AuditEventsList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const eventTypes = useEventTypes()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<AuditEventsFilter types={eventTypes} />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<AuditEventsActions />}>
            <Datagrid bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <FunctionField
                    emptyText={'-'}
                    label="Тип"
                    render={record => eventTypes?.find(item => item.id === record.eventType)?.label ?? record?.eventType}
                />
                <ReferenceField emptyText={'-'} source="authorId" label="Автор" reference="users" link="show">
                    <ClipboardTextField source="username" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="orderId" label="Заказ" reference="orders" link="show">
                    <ClipboardTextField source="orderNumber" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="userId" label="Пользователь" reference="users" link="show">
                    <ClipboardTextField source="username" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="dealerId" label="Контрагент" reference="dealers" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="filialId" label="Филиал" reference="filials" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="partnerId" label="Партнер" reference="partners" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={'-'} source="alertId" label="Оповещение" reference="alerts" link="show">
                    <ClipboardTextField source="description" />
                </ReferenceField>
                <FunctionField emptyText={'-'} label={'Изменение'} render={AuditEvent.getChangedEvent} />
                <ClipboardTextField emptyText={'-'} source="comment" label="Комментарий" />
            </Datagrid>
        </List>
    )
}
