import {Stack} from '@mui/material'
import {EditButton} from 'react-admin'
import {AvailableDeleteButton} from '../available-delete-button/available-delete-button'
import {AvailableRestoreButton} from '../available-restore-button/available-restore-button'
import {useRecordContext} from 'ra-core'

export const AvailableListButtons = (): JSX.Element => {
    const record = useRecordContext()
    if (record.isAvailable)
        return (
            <Stack direction={'row'}>
                <EditButton />
                <AvailableDeleteButton />
            </Stack>
        )
    return <AvailableRestoreButton />
}
