import {Show, ShowControllerProps, useRecordContext} from 'react-admin'
import {Box, Card, Grid, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {FunctionalShowField} from '../../../components/common/functional-show-field/functional-show-field'
import {Claim} from '../../../models/claim'
import {ClaimProblemItems} from './components/claim-problem-items/claim-problem-items'
import {ClaimFiles} from './components/claim-files/claim-files'

export const ClaimItem = (props: ShowControllerProps): JSX.Element => {
    const record = useRecordContext()
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Рекламация'} source={'id'} />
                        <ShowField title={'Номер рекламации Framer'} source={'reclamationNumber'} color={theme.palette.grey['200']} />
                        <ShowField sx={{mb: 1}} title={'Номер рекламации 1С'} source={'erpNumber'} />
                        <FunctionalShowField sx={{mb: 1}} title={'Статус'} render={Claim.getClaimStateName} source={'state'} />
                        <ShowField
                            referenceSource={'orderID'}
                            reference={'orders'}
                            link={'show'}
                            title={'Номер заказа Framer'}
                            source={'orderNumber'}
                        />
                        <ShowField
                            referenceSource={'orderID'}
                            reference={'orders'}
                            link={'show'}
                            title={'Номер заказа WinDraw'}
                            source={record?.issueSource === 'constructions' ? 'windrawOrderNumber' : 'windrawGoodsOrderNumber'}
                        />
                        <ShowField type={'date'} title={'Дата обнаружения'} source={'dateFound'} />
                        <ShowField type={'date'} title={'Дата создания'} source={'createdAt'} />
                        <ShowField sx={{mb: 1}} type={'date'} title={'Дата отправки'} source={'sentAt'} />
                        <ShowField direction={'column'} type={'date'} title={'Комментарий СКК'} source={'otk_comment'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'О рекламации'} />
                        <ShowField
                            color={theme.palette.grey['200']}
                            title={'Контрагент'}
                            referenceSource={'dealerId'}
                            source={'name'}
                            reference={'dealers'}
                            link={'show'}
                        />
                        <ShowField
                            sx={{mb: 1}}
                            title={'Пользователь'}
                            referenceSource={'owner.id'}
                            source={'username'}
                            reference={'users'}
                            link={'show'}
                        />
                        <ShowField type={'custom-email'} title={'Эл. адрес'} source={'contactEmail'} />
                        <ShowField type={'custom-text'} title={'Контактный номер телефона'} source={'contactPhone'} />
                        <ClaimProblemItems />
                        <ShowField title={'Описание брака'} direction={'column'} source={'notes'} />
                        <ClaimFiles />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
