import {IconButton, TableCell, TableRow} from '@mui/material'
import {useRecordContext} from 'react-admin'
import {Partner} from '../../../../models/partner'
import {useDiscountEditModal} from '../../../../hooks/partners'
import {useCallback} from 'react'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import {Discount, IDiscountEditForm} from '../../../../models/discount'

interface IMainDiscountsProps {
    onChange: (discounts: Discount, additionalDiscountFramerPoints?: number) => void
}
export const MainDiscounts = (props: IMainDiscountsProps): JSX.Element => {
    const {onChange} = props
    const record = useRecordContext<Partner>()
    const onSubmit = useCallback(
        ({discount, additionalDiscountFramerPoints}: IDiscountEditForm) => onChange(discount, additionalDiscountFramerPoints),
        [onChange]
    )

    const show = useDiscountEditModal(
        record.AdditionalDiscounts.baseDiscounts,
        onSubmit,
        record.AdditionalDiscounts.additionalDiscountFramerPoints
    )
    return (
        <TableRow>
            <TableCell>Общая скидка</TableCell>
            <TableCell>{record.AdditionalDiscounts.baseDiscounts.st}%</TableCell>
            <TableCell>{record.AdditionalDiscounts.baseDiscounts.nst}%</TableCell>
            <TableCell>{record.AdditionalDiscounts.baseDiscounts.lam}%</TableCell>
            <TableCell>{record.AdditionalDiscounts.baseDiscounts.wAl}%</TableCell>
            <TableCell>{record.AdditionalDiscounts.baseDiscounts.cAl}%</TableCell>
            <TableCell>{record.AdditionalDiscounts.additionalDiscountFramerPoints}%</TableCell>
            <TableCell>
                <IconButton color="primary" onClick={show}>
                    <AppRegistrationIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
