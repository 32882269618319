import {Show, ShowControllerProps, Tab, TabbedShowLayout, useRecordContext} from 'react-admin'
import {OrderItemMain} from './order-item-main'
import {OrderItemDiscounts} from './order-item-discounts'
import {OrderItemConstructions} from './order-item-constructions'
import {OrderItemGoods} from './order-item-goods'
import {Box} from '@mui/material'

export const OrderItem = (props: ShowControllerProps): JSX.Element => {
    const record = useRecordContext()
    return (
        <Show component={Box} sx={{overflow: 'auto'}} {...props}>
            <TabbedShowLayout>
                <Tab label="Основные">
                    <OrderItemMain />
                </Tab>
                <Tab label="Скидки">
                    <OrderItemDiscounts />
                </Tab>
                <Tab label="Конструкции">
                    <OrderItemConstructions calculatedByFramerPoints={record?.calculatedWithFramerPoints || record?.isPaidByFramerPoints} />
                </Tab>
                <Tab label="Доп. материалы">
                    <OrderItemGoods />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
