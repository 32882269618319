import {Chip} from '@mui/material'
import {FunctionField, useRecordContext} from 'react-admin'
import {useMemo} from 'react'
import {IPromoCodeJSON, PromoCode} from '../../../../models/promo-code'
interface IPartnerStatusFieldProps {
    label: string
}
export const PartnerStatusField = (props: IPartnerStatusFieldProps): JSX.Element => {
    const {label} = props
    const record = useRecordContext<IPromoCodeJSON>()
    const value = useMemo(() => PromoCode.parse(record).statusString, [record])
    return (
        <FunctionField
            source={'status'}
            label={label}
            render={r => <Chip color={r.status === 'used' ? 'secondary' : 'default'} label={value} />}
        />
    )
}
