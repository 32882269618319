import {AutocompleteInput} from 'react-admin'
interface IRecordAutocompleteProps {
    label: string
}
export const RecordAutocomplete = (props: IRecordAutocompleteProps): JSX.Element => {
    const {label} = props
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const optionText = record => (!(record || record?.id) ? 'Все' : `${record?.id}. ${record?.name} ${record?.deletedAt ? ' <УДАЛЕН>' : ''}`)
    return <AutocompleteInput label={label} size={'small'} optionText={optionText} />
}
