export interface IServiceJSON {
    id: number
    installationId?: number
    constructionId: string | null
    name: string
    price: number
    enabled: boolean
    isCreatedByBoss: boolean | null
    sku: string
}

export class Service {
    static parse(serviceJSON: IServiceJSON): Service {
        const service = new Service(serviceJSON.id, serviceJSON.name, serviceJSON.price)
        service.installationId = serviceJSON.installationId
        service.constructionId = serviceJSON.constructionId
        service.enabled = serviceJSON.enabled ?? false
        service.isCreatedByBoss = serviceJSON.isCreatedByBoss ?? false
        service.sku = serviceJSON.sku ?? ''

        return service
    }

    id: number
    installationId?: number
    constructionId: string | null = null
    name: string
    private _price?: number
    price: number
    enabled = false
    isCreatedByBoss: boolean | null = null
    sku = ''

    constructor(id: number, name: string, price?: number) {
        this.id = id
        this.name = name
        this.price = price || 0
        this._price = price
    }

    toString(): string {
        return `${this.name} ${this.price}`
    }
}
