import {useNotRMAdmin} from '../../../../../hooks/common'
import {useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
import React from 'react'
import {useSetGostMark} from '../../../../../hooks/orders'
export const OrderSetGostMark = (): JSX.Element => {
    const showModal = useSetGostMark()
    const isNotRMAdmin = useNotRMAdmin()
    const record = useRecordContext()
    const enabledStates = ['draft', 'needs_recalc', 'calc_errors']
    if (!isNotRMAdmin) return <></>
    return (
        <Button disabled={!enabledStates.includes(record.state)} variant="contained" color="inherit" onClick={showModal}>
            <>{record?.hasGostMarkSign ? 'Удалить ПП ГОСТ-маркиратор' : 'Добавить ПП ГОСТ-маркиратор'}</>
        </Button>
    )
}
