import {Theme} from '@mui/material'

export const TooltipStyle = (theme: Theme): Object => {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.grey[800]
                },
                arrow: {
                    color: theme.palette.grey[800]
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow:
                        '0px 3px 1px -2px rgba(145, 158, 171, 0.2),0px 2px 2px 0px rgba(145, 158, 171, 0.14),0px 1px 5px 0px rgba(145, 158, 171, 0.12)'
                }
            }
        }
    }
}
