import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {useFormik} from 'formik'
import {Autocomplete, Stack} from '@mui/material'
import {cities} from '../consts/cities'
import {AmegaEmployee} from '../models/amega-employee'
import {FormInput} from '../components/common/form-fields/form-input'
import {FormSelector} from '../components/common/form-fields/form-selector'
import {useRequest} from './network'

export const useChangeAmegaEmployee = (isEdit?: boolean): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<AmegaEmployee>()
    const submitHandler = useCallback(
        (value: AmegaEmployee) => (isEdit ? dataProvider.amegaEmployeeChange(record?.id, value) : dataProvider.amegaEmployeeAdd(value)),
        [dataProvider, isEdit, record?.id]
    )
    const {values, submitForm, handleChange, setFieldValue} = useFormik<AmegaEmployee>({
        initialValues: isEdit ? record : new AmegaEmployee(),
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: isEdit ? 'Изменить сотрудника' : 'Добавить сотрудника',
                body: (
                    <Stack direction={'column'} gap={2}>
                        <Stack direction={'row'} gap={2}>
                            <FormInput
                                fullWidth
                                defaultValue={values?.name}
                                autoFocus
                                name={'name'}
                                margin="dense"
                                label="Имя"
                                type="text"
                                onChange={handleChange}
                                size={'small'}
                            />
                            <FormInput
                                fullWidth
                                defaultValue={values?.catalog1C}
                                autoFocus
                                name={'catalog1C'}
                                margin="dense"
                                label="Каталог 1С"
                                type="text"
                                onChange={handleChange}
                                size={'small'}
                            />
                        </Stack>
                        <Stack direction={'row'} gap={2}>
                            <FormInput
                                fullWidth
                                defaultValue={values?.referralCode}
                                autoFocus
                                name={'referralCode'}
                                margin="dense"
                                label="Реферальный код"
                                type="text"
                                onChange={handleChange}
                                size={'small'}
                            />
                            <Autocomplete
                                sx={{mt: 1}}
                                fullWidth
                                options={cities}
                                getOptionLabel={option => option.name}
                                onChange={(_, v) => setFieldValue('cityId', v.id)}
                                defaultValue={cities.find(f => f.id === values?.cityId)}
                                renderInput={params => <FormSelector {...params} label="Выберите город" variant="outlined" size={'small'} />}
                            />
                        </Stack>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [handleChange, isEdit, mutate, setFieldValue, show, values?.catalog1C, values?.cityId, values?.name, values?.referralCode]
    )
}
export const useDeleteAmegaEmployee = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext<AmegaEmployee>()
    const submitHandler = useCallback(() => dataProvider.amegaEmployeeDelete(record?.id), [dataProvider, record?.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: () => window.history.back()})

    return useCallback(
        () =>
            show({
                title: 'Удаление сотрудника',
                body: <>Действительно удалить сотрудника?</>,
                onSuccess: mutate,
                successText: 'Удалить'
            }),
        [mutate, show]
    )
}
