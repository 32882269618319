import {Datagrid, List, ReferenceField, ListProps} from 'react-admin'
import {TintFilmsListActions} from './actions'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {AvailableListButtons} from '../../../components/common/available-list-buttons/available-list-buttons'
import {TintFilmsListFilters} from './filters'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const TintFilmsList = (props: ListProps): JSX.Element => {
    return (
        <List
            actions={<TintFilmsListActions />}
            filters={<TintFilmsListFilters />}
            sx={{height: 'inherit', overflow: 'hidden'}}
            title="Тонировочные пленки"
            {...props}
            bulkActionButtons={false}>
            <Datagrid bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <ClipboardTextField source="numpos" label="Позиция" sx={{width: 1}} />
                <ClipboardTextField source="sku" label="Артикул" />
                <ClipboardTextField source="description" label="Описание" />
                <BooleanField inList source="isAvailable" label={'Активно'} />
                <ReferenceField source="factoryId" label="Завод" reference="factories" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <AvailableListButtons />
            </Datagrid>
        </List>
    )
}
