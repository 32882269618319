import {DateTimeInput, Filter, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {useCallback, useState} from 'react'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const OrderActionsListFilter = (props): JSX.Element => {
    // eslint-disable-next-line react/prop-types
    const {q} = props
    const [filter, setFilter] = useState({id: q})
    const handleChange = useCallback(event => {
        const val = event.target.value || ''
        setFilter({id: val})
    }, [])
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn onChange={handleChange} />
            <SelectInput
                label="Тип действия"
                source="type"
                emptyText="Все"
                alwaysOn
                choices={[
                    {id: 'action', name: 'Изменение заказа'},
                    {id: 'recalc', name: 'Пересчет'},
                    {id: 'addrChange', name: 'Изменение адреса/склада'}
                ]}
            />
            <DateTimeInput label="Дата действия от" source="dateFrom" alwaysOn />
            <DateTimeInput label="Дата действия до" source="dateTo" alwaysOn />
            <ReferenceInput source="construction_id" label="Конструкции" match="q" reference="constructions" filter={filter} alwaysOn>
                <SelectInput optionText="position" optionValue="id" emptyText="Все" />
            </ReferenceInput>
        </Filter>
    )
}
