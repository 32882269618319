import {memo} from 'react'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import {useRecordContext, useTranslate} from 'ra-core'
import {sanitizeFieldRestProps, TextFieldProps} from 'react-admin'

export const CustomEmailField = memo((props: TextFieldProps) => {
    const {className, source, emptyText, ...rest} = props
    const record = useRecordContext(props)
    const value = get(record, source)
    const translate = useTranslate()

    if (value == null) {
        return emptyText ? (
            <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
                {emptyText && translate(emptyText, {_: emptyText})}
            </Typography>
        ) : null
    }

    return (
        <Typography className={className} variant="body2" {...sanitizeFieldRestProps(rest)}>
            {value.replace(/[,| ]/gm, ', ')}
        </Typography>
    )
})

CustomEmailField.displayName = 'CustomEmailField'
