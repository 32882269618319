import {Order} from '../../../../../models/order'
import {useMemo} from 'react'
import {FramerBonusesIcon} from '../../../../../components/common/framer-bonuses-icon/framer-bonuses-icon'
import {SxProps, Theme, Typography} from '@mui/material'
import get from 'lodash/get'
import {RaRecord} from 'ra-core'
interface IFormattedOrderCostProps {
    record: Order
    source: string
    sx?: SxProps<Theme>
    withFramerPoints?: boolean
    withCost?: boolean
    render?: (record?: RaRecord, source?: string) => void
}

export const FormattedOrderCost = (props: IFormattedOrderCostProps): JSX.Element => {
    const {record, source, sx, withFramerPoints = true, withCost = true, render} = props
    const value = render ? render(record, source) : get(record, source)
    const cost = useMemo(() => (value ? value / 100 : value === 0 ? 0 : '-'), [value])
    return (record?.calculatedWithFramerPoints || record?.isPaidByFramerPoints) && withFramerPoints ? (
        <Typography sx={sx}>
            {cost.toLocaleString()}&nbsp;
            {(value || value === 0) && withCost ? <FramerBonusesIcon /> : ''}
        </Typography>
    ) : (
        <Typography sx={sx}>
            {cost.toLocaleString()}&nbsp;{(value || value === 0) && withCost ? '₽' : ''}
        </Typography>
    )
}
