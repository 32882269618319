import {FieldProps, useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
import {useDealerChangePaymentType} from '../../../../../hooks/dealers'
import {useChiefAdmin, useCommonAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {Dealer} from '../../../../../models/dealer'

export const DealerChangePaymentType = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useDealerChangePaymentType()
    const chiefAdmin = useChiefAdmin()
    const superAdmin = useSuperAdmin()
    const commonAdmin = useCommonAdmin()
    const record = useRecordContext<Dealer>()

    if (!chiefAdmin && !superAdmin && !commonAdmin) return <></>
    return (
        <Button disabled={commonAdmin && record?.factoryId !== 3} variant="contained" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
