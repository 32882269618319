import {ExportButton, ListActionsProps, TopToolbar} from 'react-admin'
import {RegisterRegionalManager} from '../components/register-regional-manager'
import {FilterResetButton} from '../../../components/common/filter-reset-button/filter-reset-button'

export const UserListActions = (props: ListActionsProps): JSX.Element => {
    const {className, ...rest} = props
    return (
        <TopToolbar {...rest}>
            <RegisterRegionalManager label="Добавить РМ" />
            <ExportButton variant="outlined" color="inherit" />
            <FilterResetButton />
        </TopToolbar>
    )
}
