import {TextField, TextFieldProps} from 'react-admin'
import {MouseEvent, useCallback, forwardRef, useState} from 'react'
import {ClickAwayListener, Tooltip} from '@mui/material'

export const ClipboardTextField = (props: TextFieldProps): JSX.Element => {
    const [visible, setVisible] = useState(false)
    const copyHandler = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const text = (e.target as HTMLElement).textContent
        navigator.clipboard.writeText(text)
        setVisible(true)
    }, [])
    const handleTooltipClose = useCallback(() => setVisible(false), [])
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onMouseLeave={handleTooltipClose}
                title="Текст скопирован"
                placement="top-start"
                open={visible}>
                <Wrapper {...props} onClick={copyHandler} sx={{cursor: 'pointer'}} />
            </Tooltip>
        </ClickAwayListener>
    )
}

const Wrapper = forwardRef<HTMLSpanElement, TextFieldProps>(function Wrapper(props, ref) {
    return (
        <span data-mui-internal-clone-element ref={ref}>
            <TextField {...props} />
        </span>
    )
})
Wrapper.displayName = 'Wrapper'
