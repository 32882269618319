import {Order} from './order'
import {Construction} from './construction'
import {Good} from './good'
import {Service} from './service'
import {UserAction} from './user-action'

class OrderInfo {
    orderId: number
}
export class RecalcOrderRequest {
    orderInfo: OrderInfo
    constructions: Construction[]
    goods: Good[]
    services: Service[]
    userActions: UserAction[]
    partnerId: number
    filialId: number
    dealerId: number
    constructor(order: Order) {
        this.orderInfo = {
            orderId: order.id
        }

        this.constructions = order.constructions ?? []
        this.goods = order.goods ?? []
        this.services = order.services ?? []
        this.userActions = order.userActions ?? []

        // Use fields from order to override partner/filial/dealer.
        this.partnerId = order.partnerId
        this.filialId = order.filialId
        this.dealerId = order.dealerId
    }
}
