import {Button} from '@mui/material'
import {useRmAdmin} from '../../../../../hooks/common'
import {useOrderSetShippingAddressModal} from '../../../../../hooks/orders'
export const OrderSetShippingAddress = (): JSX.Element => {
    const showModal = useOrderSetShippingAddressModal()
    const isRMAdmin = useRmAdmin()
    if (isRMAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Установить принудительный адрес доставки</>
        </Button>
    )
}
