import {Button} from '@mui/material'
import {useNotRMAdmin} from '../../../../../hooks/common'
import {useCopyToDevModal} from '../../../../../hooks/orders'
export const CopyToDev = (): JSX.Element => {
    const showModal = useCopyToDevModal()
    const isNotRmAdmin = useNotRMAdmin()
    if (!isNotRmAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Копировать заказ на DEV</>
        </Button>
    )
}
