import {AutocompleteInput, DateTimeInput, Filter, NumberInput, ReferenceInput, TextInput} from 'react-admin'
import {AuditEventType} from '../../../models/audit-event'
import {FramerSelect} from '../../../components/common/form-fields/select'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'

interface IAuditEventsFilterProps {
    types: AuditEventType[]
}
export const AuditEventsFilter = (props: IAuditEventsFilterProps): JSX.Element => {
    const {types, ...rest} = props
    return (
        <Filter {...rest}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <DateTimeInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateTimeInput label="Дата создания до" source="dateTo" alwaysOn />
            <FramerSelect
                emptyText={'Все'}
                alwaysOn
                source={'eventType'}
                label={'Тип'}
                choices={types?.map(({id, label}) => ({id, name: label}))}
            />
            <ReferenceInput
                label="Автор"
                source="authorId"
                reference="users"
                alwaysOn
                perPage={10}
                filter={{
                    userRoles: [
                        'CommonAdmin',
                        'ChiefAdmin',
                        'SuperAdmin',
                        'RMAdmin',
                        'NonqualifiedBossManager',
                        'BossManager',
                        'ChiefManager',
                        'CommonManager'
                    ]
                }}>
                <AutocompleteInput label="Автор" size={'small'} optionText="username" emptyText="Все" />
            </ReferenceInput>
            <ReferenceInput label="Заказ" source="orderId" reference="orders" alwaysOn perPage={10}>
                <AutocompleteInput label="Заказ" size={'small'} optionText="orderNumber" emptyText="Все" />
            </ReferenceInput>

            <ReferenceInput label="Пользователь" source="userId" reference="users" alwaysOn perPage={10}>
                <AutocompleteInput label="Пользователь" size={'small'} optionText="username" emptyText="Все" />
            </ReferenceInput>
            <ReferenceInput label="Контрагент" source="dealerId" reference="dealers" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Контрагент'} />
            </ReferenceInput>

            <ReferenceInput label="Филиал" source="filialId" reference="filials" alwaysOn perPage={10}>
                <AutocompleteInput label="Филиал" size={'small'} optionText="name" emptyText="Все" />
            </ReferenceInput>

            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Партнер'} />
            </ReferenceInput>

            <NumberInput label="Ид оповещения" source="alertId" alwaysOn />
        </Filter>
    )
}
