import {ArrayField, Datagrid, Show, ShowControllerProps, Tab, TabbedShowLayout, TextField} from 'react-admin'
import {PartnerStatusField} from '../components/partner-status-field/partner-status-field'
import {PartnerPromoConfirm} from '../components/partner-promo-confirm/partner-promo-confirm'
import {AdditionalDiscounts} from '../components/discounts/additional-discounts'
import {PartnerDealers} from '../components/dealers/dealers'
import {Box, Card, Divider, Grid, Stack, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {DeleteClient} from '../components/delete-client/delete-client'
import {UpdateInfo} from '../components/update-info/update-info'
import {ChangeCity} from '../components/change-city/change-city'
import {ChangeMainDealer} from '../components/change-main-dealer/change-main-dealer'
import {ChangeDiscountVisibility} from '../components/change-discount-visibility/change-discount-visibility'
import {ChangeSubDealerEnabled} from '../components/change-subdealer-enabled/change-subdealer-enabled'
import {ChangeVilatermEnabled} from '../components/change-vilaterm-enabled/change-vilaterm-enabled'
import {ChangeMetalImpostEnabled} from '../components/change-metal-impost-enabled/change-metal-impost-enabled'
import {ChangeGostEnabled} from '../components/change-gost-enabled/change-gost-enabled'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'

export const PartnerItem = (props: ShowControllerProps): JSX.Element => {
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное">
                    <Grid container spacing={2} columns={{xs: 3, sm: 6, md: 12}}>
                        <Grid item xs={3}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Партнер'} source={'name'} />
                                <ShowField color={theme.palette.grey['200']} title={'Ид завода'} source={'factoryId'} />
                                <ShowField title={'Код 1С'} source={'externalId'} />
                                <ShowField
                                    reference={'dealers'}
                                    link={'show'}
                                    referenceSource={'id'}
                                    title={'Контрагент'}
                                    source={'selectedDealerId'}
                                    sx={{mb: 2}}
                                />
                                <ShowField color={theme.palette.grey['200']} title={'Город'} source={'city'} />
                                <ShowField title={'Телефон'} source={'contactInfo.phone'} />
                                <ShowField type={'date'} title={'Дата создания'} source={'createdAt'} />
                                <ShowField type={'date'} title={'Дата последнего обновления'} source={'updatedAt'} />
                                <ShowField type={'date'} title={'Дата удаления'} source={'deletedAt'} />
                                <BooleanField label={'Исключен из аналитики'} source={'skipInAnalytics'} />
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Скидки'} />
                                <ShowField color={theme.palette.grey['200']} title={'Скидка на конструкции/стандарт'} source={'discount'} />
                                <ShowField title={'Скидка на нестандарт'} source={'discountNonstandard'} />
                                <ShowField sx={{mb: 1}} title={'Скидка на ламинацию'} source={'discountLamination'} />
                                <ShowField source="discountWarmAl" title={'Скидка на теплый алюминий'} />
                                <ShowField source="discountColdAl" title={'Скидка на холодный алюминий'} />
                                <ShowField sx={{mb: 1}} source="discountMetal" title={'Скидка на метал'} />
                                <ShowField source="discountGoods" title={'Скидка на товары'} />
                                <ShowField sx={{mb: 1}} source="discountGlass" title={'Скидка на стеклопакеты'} />
                                <BooleanField source="showDiscountInProfile" label={'Видимость скидки'} />
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Настройка профиля'} />
                                <ShowField color={theme.palette.grey['200']} source="margin.percent" title={'Наценка на конструкции'} />
                                <ShowField
                                    sx={{mb: 1}}
                                    color={theme.palette.grey['200']}
                                    source="goodsMargin.percent"
                                    title={'Наценка на допы'}
                                />
                                <BooleanField source="isDeliveryAvailable" label={'Доступна доставка'} />
                                <BooleanField source="isArmMarkingAvailable" label={'Доступна возможность просмотра армирования в заказе'} />
                                <BooleanField sx={{mb: 1}} source="isSubdealershipAvailable" label={'Доступно субдилерство'} />
                                <BooleanField source="isVilatermShouldBeSet" label={'Принудительная установка вилатерма на все заказы'} />
                                <BooleanField source="isTenderShouldBeSet" label={'Принудительная установка параметра Тендер на все заказы'} />
                                <BooleanField source="isMetalKreplenieImposta" label={'Принудительная установка металлической пятки импоста'} />
                                <BooleanField source="isGostShouldBeSet" label={'Установка параметра ГОСТ'} />
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Действия'} />
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <DeleteClient />
                                        <UpdateInfo />
                                        <ChangeCity />
                                        <ChangeMainDealer />
                                    </Stack>
                                    <Divider />
                                    <Stack spacing={1}>
                                        <ChangeDiscountVisibility />
                                        <ChangeSubDealerEnabled />
                                        <ChangeVilatermEnabled />
                                        <ChangeMetalImpostEnabled />
                                        <ChangeGostEnabled />
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Tab>
                <Tab label="Дополнительные скидки">
                    <AdditionalDiscounts />
                </Tab>
                <Tab label="Промокод">
                    <ArrayField source="promos" label={'Промокоды'}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source={'promo.code'} label={'Код'} />
                            <PartnerStatusField label={'Статус'} />
                            <TextField source={'promo.description'} label={'Описание'} />
                            <PartnerPromoConfirm />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Контрагенты">
                    <PartnerDealers />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
