export const FramerBonusesIcon = (): JSX.Element => {
    return (
        <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.617 0h2.18c1.251 0 2.165.276 2.74.829.584.542.877 1.304.877 2.285 0 .962-.297 1.724-.89 2.286-.585.552-1.494.829-2.728.829h-.37v.957h2.87v1.057h-2.87V10H3.988V8.243H1.296V7.186h2.692v-.957h-.37c-1.234 0-2.143-.277-2.728-.829C.297 4.838 0 4.076 0 3.114 0 2.134.292 1.371.877.83 1.452.276 2.365 0 3.617 0Zm.37 5.029v-3.8h-.37c-.816 0-1.396.161-1.739.485-.352.315-.528.786-.528 1.415 0 .638.171 1.114.514 1.428.344.314.928.472 1.753.472h.37Zm1.439 0v-3.8h.37c.816 0 1.396.161 1.74.485.352.315.528.786.528 1.415 0 .638-.172 1.114-.515 1.428-.343.314-.927.472-1.753.472h-.37Z"
                fill="#303030"></path>
        </svg>
    )
}
