import {Button} from '@mui/material'
import {FieldProps} from 'react-admin'
import {useAlertChangeModal} from '../../../../../hooks/alerts'
export const AlertChange = (props: FieldProps & {isCreate?: boolean}): JSX.Element => {
    const {label, isCreate} = props
    const openForm = useAlertChangeModal(isCreate)
    return (
        <Button
            sx={{mb: isCreate ? 0 : 1}}
            fullWidth={!isCreate}
            variant={isCreate ? 'outlined' : 'contained'}
            color="inherit"
            onClick={openForm}>
            {label}
        </Button>
    )
}
