import {Filter, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'
import {booleanItems} from '../../../consts/others'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FilialListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput label="Внешний" source="isExternal" emptyText="Все" alwaysOn choices={booleanItems} />
            <SelectInput label="Основной" source="isMain" emptyText="Все" alwaysOn choices={booleanItems} />
            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Партнер'} />
            </ReferenceInput>
        </Filter>
    )
}
