import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {showFieldTitle, showFieldValue} from '../../../../../consts/styles'
import {FunctionField} from 'react-admin'
import {useCallback} from 'react'
import {Order} from '../../../../../models/order'

interface IForceWeekdaysProps {
    sx?: SxProps<Theme>
    title: string
    color?: string
}

export const ForceWeekdays = (props: IForceWeekdaysProps): JSX.Element => {
    const {sx, color, title} = props
    const render = useCallback((record: Order) => Order.numbersToWeekdays(record?.forceWeekdays), [])
    const theme = useTheme()

    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={showFieldValue} render={render} />
        </Stack>
    )
}
