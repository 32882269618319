import {MutableRefObject, useEffect, useRef} from 'react'

export const useOuterClick = <T extends HTMLElement>(callback: () => void): MutableRefObject<T> => {
    const callbackRef = useRef(null)
    const innerRef = useRef(null)

    useEffect(() => {
        callbackRef.current = callback
    })

    useEffect(() => {
        const handleClick = (e: MouseEvent): void => {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e)
        }
        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [])

    return innerRef
}
