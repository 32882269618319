import {useRequestBookingCancel} from '../../../../../hooks/orders'
import {useNotRMAdmin} from '../../../../../hooks/common'
import {useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
export const RequestBookingCancel = (): JSX.Element => {
    const showModal = useRequestBookingCancel()
    const isNotRMAdmin = useNotRMAdmin()
    const record = useRecordContext()
    const disabledStatuses = ['draft', 'needs_recalc', 'calc_errors', 'cancelled']
    if (!isNotRMAdmin) return <></>
    return (
        <Button disabled={disabledStatuses.includes(record.state)} variant="contained" color="inherit" onClick={showModal}>
            <>Запрос на отмену бронирования</>
        </Button>
    )
}
