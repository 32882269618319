import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useStartDatabaseUpdateServiceModal} from '../../../../../hooks/windraw_services'

export const DatabaseUpdateService = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useStartDatabaseUpdateServiceModal()
    return (
        <Button variant={'outlined'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
