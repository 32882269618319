import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useAmpqRestart} from '../../../../../hooks/ampq_handlers'
import {useSuperAdmin} from '../../../../../hooks/common'
export const RestartAmpq = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useAmpqRestart()
    const isSuperAdmin = useSuperAdmin()
    if (!isSuperAdmin) return <></>
    return (
        <Button sx={{mt: 1}} fullWidth variant={'contained'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
