import {Theme} from '@mui/material'

export const TypographyStyle = (theme: Theme): Object => {
    return {
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2)
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1)
                }
            }
        }
    }
}
