import {useOrderUnbook, useOrderAutoUnbook} from '../../../../../hooks/orders'
import {useNotRMAdmin} from '../../../../../hooks/common'
import {useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
export const OrderUnbook = (): JSX.Element => {
    const showModal = useOrderUnbook()
    const isNotRMAdmin = useNotRMAdmin()
    const record = useRecordContext()
    if (!isNotRMAdmin) return <></>
    return (
        <Button disabled={record.state !== 'cancel_requested'} variant="contained" color="inherit" onClick={showModal}>
            <>Отменить бронирование</>
        </Button>
    )
}
export const OrderAutoUnbook = (): JSX.Element => {
    const showModal = useOrderAutoUnbook()
    const isNotRMAdmin = useNotRMAdmin()
    const record = useRecordContext()
    if (!isNotRMAdmin) return <></>
    return (
        <Button disabled={record.state !== 'cancel_requested'} variant="contained" color="inherit" onClick={showModal}>
            <>Авторазбронировать заказ</>
        </Button>
    )
}
