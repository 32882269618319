import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useDealerUpdateInfoModal} from '../../../../../hooks/dealers'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'

export const DealerUpdateInfo = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useDealerUpdateInfoModal()
    const chiefAdmin = useChiefAdmin()
    const superAdmin = useSuperAdmin()

    if (!chiefAdmin && !superAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
