import {useState} from 'react'
import {DialogContext, IDialogData} from './dialog-context'

interface IDialogContextProviderProps {
    children: JSX.Element | JSX.Element[]
}
const DialogContextProvider = (props: IDialogContextProviderProps): JSX.Element => {
    const {children} = props
    const [data, setData] = useState<IDialogData>()
    const displayHandler = (data: IDialogData): void => setData(data)
    const closeHandler = (): void => setData(undefined)

    return (
        <DialogContext.Provider
            value={{
                data: data,
                show: displayHandler,
                onClose: closeHandler
            }}>
            {children}
        </DialogContext.Provider>
    )
}

export {DialogContextProvider}
export type {IDialogContextProviderProps}
