import {Filter, SelectInput} from 'react-admin'
import {factories} from '../../../consts/factories'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WindrawServicesFilter = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <SelectInput label="Завод" source="factoryId" emptyText="Все" alwaysOn choices={factories} />
            <SelectInput
                label="Тип"
                source="type"
                emptyText="Все"
                alwaysOn
                choices={[
                    {id: 'calc', name: 'Расчеты'},
                    {id: 'create', name: 'Создание'},
                    {id: 'book', name: 'Бронирование'},
                    {id: 'balancer', name: 'Балансер'}
                ]}
            />
        </Filter>
    )
}
