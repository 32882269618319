import {DateInput, Filter, SelectInput, TextInput} from 'react-admin'
import {FactoryList} from '../../../models/factory-types'
import {booleanItems} from '../../../consts/others'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PartnerListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput {...props} emptyText="Все" alwaysOn choices={booleanItems} label="Доступна доставка" source="delivery" />
            <SelectInput label="Завод" source="factoryId" emptyText="Все" alwaysOn choices={FactoryList} />
            <SelectInput label="Подтвержден" source="isConfirmed" emptyText="Все" alwaysOn choices={[{id: true, name: 'Да'}]} />
            <SelectInput label="Удален" source="notDeleted" emptyText="Все" alwaysOn choices={[{id: true, name: 'Нет'}]} />
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
        </Filter>
    )
}
