import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {showFieldTitle, showFieldValue} from '../../../../../consts/styles'
import {FunctionField} from 'react-admin'
import {useCallback} from 'react'

interface IOrderDiscountFieldProps {
    sx?: SxProps<Theme>
    color?: string
    title: string
    direction?: 'row' | 'column'
    source?: string
}

export const OrderDiscountField = (props: IOrderDiscountFieldProps): JSX.Element => {
    const {sx, color = 'transparent', title, direction = 'row', source} = props
    const theme = useTheme()
    const formatDiscounts = useCallback(
        type => record => {
            const factory = record.factoryDiscounts[type]
            const manual = record.manualDiscounts[type]
            return manual === 0 ? `${factory.toFixed(2)} %` : `${manual.toFixed(2)} %`
        },
        []
    )
    return (
        <Stack
            alignItems={direction === 'column' ? 'flex-start' : 'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={direction}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={showFieldValue} emptyText={'-'} label={''} render={formatDiscounts(source)} />
        </Stack>
    )
}
