import {Button} from '@mui/material'
import {useRmAdmin} from '../../../../../hooks/common'
import {useOrderSetShippingCostModal} from '../../../../../hooks/orders'
export const OrderSetShippingCost = (): JSX.Element => {
    const showModal = useOrderSetShippingCostModal()
    const isRMAdmin = useRmAdmin()
    if (isRMAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Установить принудительную стоимость доставки</>
        </Button>
    )
}
