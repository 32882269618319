import {DealerInfo} from './dealer-info'
import {BankAccount, IBankAccountJSON} from './bank-account'
import {ContactInfo, IContactInfoJSON} from './contact-info'
import {IServiceJSON, Service} from './service'
import {FactoryTypes} from './factory-types'
import {IPartnerJSON, Partner} from './partner'
import {Account} from './account'

export enum DealerConfirmationStatus {
    DealerNotConfirmed = 'DealerNotConfirmed',
    DealerPendingConfirmation = 'DealerPendingConfirmation',
    DealerConfirmed = 'DealerConfirmed'
}

export enum DealerPaymentType {
    Card = 'card',
    Invoice = 'invoice'
}
export interface ICreditInfo {
    beginDate: string
    endDate: string
    limit: number
    active: boolean
}

export interface IDealerJSON {
    id: number
    externalId: string
    name: string
    type: number
    paymentType: DealerPaymentType
    bankAccount: IBankAccountJSON
    contactInfo: IContactInfoJSON
    services: IServiceJSON[]
    billsNum: number
    isDeliveryAvailable: boolean
    balance: number
    creditLimitInCents: number
    framerCreditLimitInCents: number
    creditLimit: ICreditInfo | null
    framerCreditLimit: ICreditInfo | null
    factoryId: FactoryTypes
    confirmationStatus: DealerConfirmationStatus
    isFramerPointsAvailable: boolean
    mainDealerId?: string
    partner?: IPartnerJSON
    discount?: number
    discountColdAl?: number
    discountFramerPoints?: number
    discountLamination?: number
    discountMetal?: number
    discountNonstandard?: number
    discountWarmAl?: number
}

export class Dealer {
    static parse(dealerJSON: IDealerJSON): Dealer {
        const dealer = new Dealer()
        Object.assign(dealer, dealerJSON)

        dealer.bankAccount = BankAccount.parse(dealerJSON.bankAccount)
        dealer.contactInfo = ContactInfo.parse(dealerJSON.contactInfo)
        dealer.services = (dealerJSON.services ?? []).map(s => Service.parse(s))
        dealer.isDeliveryAvailable = Boolean(dealerJSON.isDeliveryAvailable)
        dealer.confirmationStatus = DealerConfirmationStatus[dealerJSON.confirmationStatus]

        return dealer
    }

    id!: number
    account?: Account
    framerAccount?: Account
    externalId!: string
    name!: string
    type!: number
    paymentType!: DealerPaymentType
    bankAccount!: BankAccount
    contactInfo!: ContactInfo
    services: Service[] = []
    billsNum!: number
    isDeliveryAvailable!: boolean
    balance = 0
    creditLimitInCents = 0
    framerCreditLimitInCents = 0
    creditLimit: ICreditInfo | null = null
    framerCreditLimit: ICreditInfo | null = null
    factoryId!: FactoryTypes
    confirmationStatus!: DealerConfirmationStatus
    info?: DealerInfo
    isFramerPointsAvailable = false
    mainDealerName: string
    partner?: Partner
    discount: 0
    discountColdAl: 0
    discountFramerPoints: 0
    discountLamination: 0
    discountMetal: 0
    discountNonstandard: 0
    discountWarmAl: 0

    static confirmationStatusList = [
        {id: DealerConfirmationStatus.DealerNotConfirmed, name: 'Дилер не подтвержден'},
        {id: DealerConfirmationStatus.DealerPendingConfirmation, name: 'Дилер ожидает подтверждения'},
        {id: DealerConfirmationStatus.DealerConfirmed, name: 'Дилер подтвержден'}
    ]

    static getConfirmationStatus(dealer?: Dealer): string {
        if (!dealer) return ''
        return Dealer.confirmationStatusList.find(status => dealer.confirmationStatus === status.id)?.name
    }

    static typeList = [
        {id: 0, name: 'Юрлицо'},
        {id: 1, name: 'Физлицо'}
    ]

    static getPayTypeTitle(dealer?: Dealer): string {
        if (!dealer) return '-'
        if (dealer.paymentType === DealerPaymentType.Card) return 'По карте'
        return 'По счету'
    }

    static getDiscounts(dealer?: Dealer): string {
        if (!dealer) return '-'
        if (dealer.factoryId === 4) return '-'
        return `с:${dealer.discount},нс:${dealer.discountNonstandard},л:${dealer.discountLamination},\n
            та:${dealer.discountWarmAl},ха:${dealer.discountColdAl},м:${dealer.discountMetal}`
    }

    static getOperationType(operationType?: number): string {
        switch (operationType) {
            case 1:
                return 'Зачисление'
            case -1:
                return 'Списание'
            default:
                return 'Не указан'
        }
    }

    static getPaymentType(paymentType?: number): string {
        switch (paymentType) {
            case 1:
                return 'Онлайн'
            case 2:
                return 'Банкомат'
            case 3:
                return 'Офис'
            case 4:
                return 'Счет'
            case 5:
                return 'С баланса'
            case 6:
                return 'Возврат'
            case 7:
                return 'Возврат за заказ'
            default:
                return 'Не указан'
        }
    }

    static getOperationState(operationState?: number): string {
        switch (operationState) {
            case 1:
                return 'Обработка'
            case 2:
                return 'Подтверждена'
            case 3:
                return 'Отклонена'
            default:
                return 'Не указано'
        }
    }

    static accountTypes = [
        {id: 1, name: 'Официальный'},
        {id: 2, name: 'Фантики'}
    ]
    static operationTypes = [
        {id: 1, name: 'Зачисление'},
        {id: -1, name: 'Списание'}
    ]
    static balanceTypes = [
        {id: 2, name: 'Фантики'},
        {id: 1, name: 'Рубли'}
    ]
    static paymentTypes = [
        {id: 1, name: 'Онлайн'},
        {id: 2, name: 'Банкомат'},
        {id: 3, name: 'Офис'},
        {id: 4, name: 'Счет'},
        {id: 5, name: 'С баланса'},
        {id: 6, name: 'Возврат'},
        {id: 7, name: 'Возврат за заказ'}
    ]

    static operationStates = [
        {id: 1, name: 'Обработка'},
        {id: 2, name: 'Подтверждена'},
        {id: 3, name: 'Отменена'}
    ]

    static getType(type: number): string {
        switch (type) {
            case 0:
                return 'Юр. лицо'
            case 1:
                return 'Физ. лицо'
            default:
                return 'ИП'
        }
    }
}
