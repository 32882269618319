/* eslint-disable @typescript-eslint/naming-convention */
export const LayoutStyles = {
    RaLayout: {
        styleOverrides: {
            root: {
                '& .RaLayout-contentWithSidebar': {
                    height: 'calc(100vh - 3em)',
                    width: '100vw'
                },
                '& .RaLayout-content': {
                    flex: 1,
                    overflow: 'hidden',
                    backgroundColor: '#f5f6f8',
                    height: 'calc(100vh - 3em)',
                    paddingLeft: '24px',
                    paddingRight: '24px'
                },
                '& .RaLayout-appFrame': {
                    marginTop: 'unset'
                }
            }
        }
    },
    MuiToolbar: {
        styleOverrides: {
            root: {
                paddingBottom: '16px !important'
            }
        }
    }
}
