import {useRecordContext} from 'react-admin'
import {OrderGoodsTable} from './order-goods-table'

export const OrderItemGoods = (): JSX.Element => {
    const record = useRecordContext()
    const constructionGoods =
        record.constructions?.map((c, i) => ({
            name: c.name ? c.name : `Конструкция ${i + 1}`,
            goods: record.goods?.filter(g => g.constructionId === c.id) ?? []
        })) ?? []

    const orderGoods = record.goods?.filter(_ => !_.constructionId) ?? []
    return (
        <>
            {!!constructionGoods.some(i => !!i.goods.length) &&
                constructionGoods.map(item => <OrderGoodsTable goods={item.goods} key={item.name} name={item.name} />)}
            {!!orderGoods.length && <OrderGoodsTable goods={orderGoods} key={'order'} name={'Заказ'} />}
        </>
    )
}
