import {Dealer, IDealerJSON} from './dealer'
import {IRegionJSON, Region} from './region'
import {Affiliate, IAffiliateJSON} from './affiliate'
import {ContactInfo, IContactInfoJSON} from './contact-info'
import {IDadataAddress} from '../services/kladr-manager'
import {Address, IAddressJSON} from './address'
import {Discount, IDiscountJSON} from './discount'
import {IMarginJSON, Margin} from './margin'
import {IServiceJSON, Service} from './service'
import {OrderStates} from './order-states'
import {Client, IClientJSON} from './client'
import {Employee, IEmployeeJSON} from './employee'
import {IWarehouseJSON, Warehouse} from './warehouse'
import {numbersToWeekdaysMap} from '../consts/date'
import {Construction, IConstructionJSON} from './construction'
import {Good, IGoodJSON} from './good'
import {IUserActionJSON, UserAction} from './user-action'

export interface IOrderJSON {
    id: number
    uniqueId: string
    orderNumber: string
    windrawOrderNumber: string
    windrawGoodsOrderNumber: string
    createdAt: string
    lastCalculatedAt: string
    state: OrderStates
    client: IClientJSON | null
    totalCost: number
    totalAmegaCost: number
    totalAmegaCostToDisplay: number
    totalAmegaCostWithDealerDiscount: number
    totalAmegaCostWithDealerDiscountToDisplay: number
    totalAmegaCostWithShipping: number
    totalAmegaGoodsCost: number
    totalDiscount: number
    totalWeight: number
    totalSquare: number
    services: IServiceJSON[]
    margin: IMarginJSON
    goodsMargin: IMarginJSON
    discount: IDiscountJSON
    estShippingDate: string
    shippingDate: string
    shippingComment: string
    shippingCost: number
    shippingFloor: number
    oversizedProductsCount: number
    overweightProductsShippingCost: number
    overweightConstructionsCount: number
    shippingToFloorCost: number
    shippingAddress: IAddressJSON | null
    changed: boolean
    needRecalc: boolean
    readonly: boolean
    favorite: boolean
    dadataAddress: IDadataAddress | null
    contactPerson: IContactInfoJSON | null
    offerAddonLine1?: string
    offerAddonLine2?: string
    mountingDate?: string
    filial: IAffiliateJSON | null
    owner: IEmployeeJSON | null
    haveEnergyProducts?: boolean
    isCancelAvailable?: boolean
    city?: IRegionJSON | null
    calculatedWithFramerPoints: boolean
    orderType?: number
    kpPdfId?: string
    orderSigns?: string[]
    allowDiscountRequest?: boolean
    paidAt?: string
    localTime?: string
    goodsMarginPercent: number
    constrMarginPercent: number
    comment?: string
    showCommentInKp?: boolean
    isConfirmedAt1C?: boolean
    updatedAt: string
    externalId: string
    filialId: number
    dealer: IDealerJSON | null
    dealerId: number
    partnerId: number
    orders?: IOrderJSON[]
    selectedOrder: IOrderJSON
    isDestinationSet?: boolean
    isPromocodeAvailable?: boolean
    paymentState: string
    cancellationReason: string
    shippingType: string
    warehouse?: IWarehouseJSON
    forceShippingType?: string
    forceShippingAddress?: IAddressJSON
    forceDirectShipping?: boolean
    forceWarehouseId?: string
    forceWeekdays?: string
    constructions: IConstructionJSON[]
    goods: IGoodJSON[]
    userActions: IUserActionJSON[]
    oversizedProductsTransportationServiceCost?: number
    tags?: string[]
}

export class Order {
    id = -1
    kpId = -1
    uniqueId = ''
    orderNumber = ''
    windrawOrderNumber = ''
    windrawGoodsOrderNumber = ''
    createdAt = ''
    lastCalculatedAt = ''
    state = OrderStates.Draft
    client: Client | null = null
    totalCost = 0
    totalAmegaCost = 0
    totalAmegaCostToDisplay = 0
    totalAmegaCostWithDealerDiscount = 0
    totalAmegaCostWithDealerDiscountToDisplay = 0
    totalAmegaCostWithShipping = 0
    totalAmegaGoodsCost = 0
    totalDiscount = 0
    totalWeight = 0
    totalSquare = 0
    services: Service[] = []
    margin = new Margin()
    goodsMargin = new Margin()
    discount = new Discount()
    estShippingDate = ''
    shippingCost = 0
    shippingFloor = 0
    oversizedProductsCount = 0
    overweightProductsShippingCost = 0
    overweightConstructionsCount = 0
    shippingToFloorCost = 0
    shippingDate = ''
    shippingComment = ''
    shippingAddress: Address | null = null
    needRecalc = false
    readonly = false
    favorite = false
    dadataAddress: IDadataAddress | null = null
    contactPerson: ContactInfo | null = null
    offerAddonLine1?: string
    offerAddonLine2?: string
    mountingDate?: string
    filial: Affiliate | null = null
    owner: Employee | null = null
    haveEnergyProducts = false
    isCancelAvailable = false
    allowDiscountRequest = false
    city: Region | null = null
    calculatedWithFramerPoints = false
    isPaidByFramerPoints = false
    orderType = 0
    kpPdfId?: string
    orderSigns: string[] = []
    paidAt = ''
    localTime?: string
    goodsMarginPercent: number
    constrMarginPercent: number
    comment = ''
    showCommentInKp = false
    ///КП утверждено
    isConfirmedAt1C = false
    updatedAt = ''
    externalId = ''
    filialId = -1
    dealer: Dealer | null = null
    dealerId = -1
    partnerId = -1
    orders: Order[] = []
    selectedOrder: Order | null = null
    isPromocodeAvailable: false
    isDestinationSet?: boolean = false
    paymentState: string = ''
    cancellationReason: string = ''
    shippingType: string = ''
    warehouse?: Warehouse
    forceShippingType?: string = ''
    forceShippingAddress?: Address
    forceDirectShipping?: boolean
    forceWarehouseId?: string
    forceWeekdays?: string
    constructions: Construction[]
    goods: Good[]
    userActions: UserAction[] = []
    oversizedProductsTransportationServiceCost?: number
    tags: string[] = []

    static statusesList = [
        {id: 'draft', name: 'Расчет (draft)', color: '#757D85'},
        {id: 'calc_errors', name: 'Ошибки построения (calc_errors)', color: '#CE4040'},
        {id: 'needs_recalc', name: 'Необходим пересчет (needs_recalc)', color: '#BEC2C7'},
        {id: 'awaiting_payment', name: 'Ожидает оплаты (awaiting_payment)', color: '#FBB605'},
        {id: 'paid', name: 'Оплачен (paid)', color: '#FBB605'},
        {id: 'booking', name: 'Передаем в производство (booking)', color: '#FBB605'},
        {id: 'booked', name: 'Забронирован (booked)', color: '#0075FF'},
        {id: 'cancel_requested', name: 'Запрос отмены (cancel_requested)', color: '#FBB605'},
        {id: 'cancelled', name: 'Отменен (cancelled)', color: '#CE4040'},
        {id: 'producing', name: 'В производстве (producing)', color: '#0075FF'},
        {id: 'production_error', name: 'Ошибка производства (production_error)', color: '#CE4040'},
        {id: 'ready_for_pickup', name: 'Готов к отгрузке (ready_for_pickup)', color: '#0075FF'},
        {id: 'produced__shipping_to_client', name: 'Готов, ожидается доставка (produced_shipping_to_client)', color: '#0075FF'},
        {
            id: 'produced__shipping_to_warehouse',
            name: 'Готов, ожидается доставка на склад (produced_shipping_to_warehouse)',
            color: '#0075FF'
        },
        {id: 'shipping_to_client_address', name: 'Доставка по адресу (shipping_to_client_address)', color: '#0075FF'},
        {id: 'shipping_to_warehouse', name: 'Доставка на склад (shipping_to_warehouse)', color: '#0075FF'},
        {
            id: 'at_warehouse_awaiting_shipping',
            name: 'На складе, ожидается доставка (at_warehouse_awaiting_shipping)',
            color: '#0075FF'
        },
        {id: 'at_warehouse_awaiting_pickup', name: 'На складе, ожидается самовывоз (at_warehouse_awaiting_pickup)'},
        {id: 'completed', name: 'Завершен (completed)', color: '#3ABF7C'}
    ]

    static orderTypes = [
        {id: 'framer', name: 'Framer'},
        {id: 'offline', name: 'Оффлайн'}
    ]

    static cancellationReason = {
        ship_date_change: 'Изменение даты доставки',
        address_change: 'Изменение адреса доставки/склада',
        constr_change: 'Изменение параметров конструкции',
        client_declined: 'Отказ заказчика',
        dealer_error: 'Выгружен по ошибке',
        too_long_production: 'Не устраивают сроки готовности',
        other: 'Другое'
    }

    static readablePaymentState = {
        payment_equal: 'Суммы заказов совпадают',
        payment_not_equal: 'Суммы заказов не совпадают',
        payment_not_verified: 'Суммы заказов не сверялись',
        '': 'Суммы заказов не сверялись'
    }

    static getReadablePaymentState(code: string): string {
        return `${Order.readablePaymentState[code]} (${code})`
    }

    static readableTags = {
        blockConstructionChange: 'Блокировка конструкций',
        blockFactoryGoodsChange: 'Блокировка заводских допов',
        blockDeliveryAddressChange: 'Блокировка изменения адреса'
    }

    static getReadableTags(tags: string[]): string {
        let orderReadableTags = []
        if (tags) {
            for (let tag of tags) {
                orderReadableTags.push(Order.readableTags[tag])
            }
        }

        return orderReadableTags.length > 0 ? orderReadableTags.join(', ') : '-'
    }

    static getCancelReason(code: string): string {
        return Order.cancellationReason[code] ? `${Order.cancellationReason[code]} (${code})` : '-'
    }

    static cancellationReasonList = Object.entries(Order.cancellationReason).map(([code, humanReadable]) => ({
        id: code,
        name: `${humanReadable} (${code})`
    }))

    static cancellationReasonSelectOptions = Order.cancellationReasonList.map(i => ({label: i.name, value: i.id}))

    static deliveryTypes = [
        {id: 'delivery', name: 'Доставка'},
        {id: 'pickup', name: 'Самовывоз'}
    ]

    static deliveryTypesForSelect = [{id: undefined, name: 'Не указано'}, ...this.deliveryTypes]

    static getStatusTitle(status: string): string {
        return Order.statusesList.find(s => s.id === status)?.name ?? ''
    }

    static getShippingType(type: string): string {
        if (type === 'pickup') return 'Самовывоз'
        if (type === 'delivery') return 'Доставка'
        return 'Не выбран'
    }
    static getShippingAddress(address: Address): string {
        if (!address || !address.street.length) return '-'
        return `${address.region}, ${address.city}, ${address.street}, ${address.building}, ${address.zipcode}`
    }
    static getShippingWarehouse(warehouse: Warehouse): string {
        if (!warehouse) return '-'
        return `${warehouse.name}`
    }

    static getForceShippingType(forceShippingType: string): string {
        if (!forceShippingType) return '-'
        return forceShippingType === 'delivery' ? 'Доставка по адресу' : 'Доставка на склад'
    }
    static getForceShippingAddress(forceShippingAddress: IAddressJSON): string {
        if (!forceShippingAddress) return '-'
        return Address.parse(forceShippingAddress).toString()
    }

    static getForceDirectShipping(forceDirectShipping?: boolean): string {
        if (!forceDirectShipping) return '-'
        return forceDirectShipping ? 'Да' : 'Нет'
    }

    static numbersToWeekdays(weekdays?: string): string {
        if (!weekdays) return '-'
        return weekdays
            .split(',')
            .map(day => numbersToWeekdaysMap[day])
            .join(', ')
    }
}
