import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRefresh} from 'react-admin'
import {useFormik} from 'formik'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {useRequest} from './network'

export const useUpdateModelUserParams = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback(() => dataProvider.updateModelUserParams(), [dataProvider])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить модель',
                body: <>Действительно обновить модель пользовательских параметров?</>,
                onSuccess: mutate,
                successText: 'Обновить'
            }),
        [mutate, show]
    )
}
export const useUpdateTemplatesUserParams = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback(() => dataProvider.updateTemplatesUserParams(), [dataProvider])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить шаблоны',
                body: <>Действительно обновить шаблоны?</>,
                onSuccess: mutate,
                successText: 'Обновить'
            }),
        [mutate, show]
    )
}
export const useFullRecalcUserParamsModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback((values: {factoryId: number}) => dataProvider.fullRecalcUserParams(values?.factoryId), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<{factoryId: number}>({
        initialValues: {factoryId: undefined},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Выберите завод обновления заказов',
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.factoryId}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('factoryId', e.target.value)}>
                        <FormControlLabel value={3} control={<Radio />} label="Пермь" />
                        <FormControlLabel value={4} control={<Radio />} label="МСК" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Запустить'
            }),
        [mutate, setFieldValue, show, values?.factoryId]
    )
}
