import {TextField, Theme, styled} from '@mui/material'
export const FormSelector = styled(TextField)(({theme}: {theme: Theme}) => ({
    '& .MuiFilledInput-root': {
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[400]}`
    },
    '& label': {
        color: theme.palette.grey[600]
    },
    '& .Mui-focused': {
        color: theme.palette.grey[600],
        borderColor: theme.palette.primary.main
    },
    '& .Mui-error': {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.main
    },
    '& .Mui-error:after': {
        borderColor: theme.palette.error.main
    },
    '& :after': {
        top: 0,
        border: 'unset',
        borderRadius: 12
    },
    '& :before': {
        display: 'none'
    }
}))

FormSelector.defaultProps = {
    variant: 'filled',
    sx: {borderRadius: 20}
}
