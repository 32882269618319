export const cities = [
    {
        id: 1,
        name: 'Екатеринбург',
        kladrRegionId: '66',
        kladrRegionName: 'Свердловская область',
        factoryId: 3
    },
    {
        id: 2,
        name: 'Ижевск',
        kladrRegionId: '18',
        kladrRegionName: 'Удмуртская республика',
        factoryId: 3
    },
    {
        id: 3,
        name: 'Казань',
        kladrRegionId: '16',
        kladrRegionName: 'Республика Татарстан',
        factoryId: 3
    },
    {
        id: 4,
        name: 'Киров',
        kladrRegionId: '43',
        kladrRegionName: 'Кировская область',
        factoryId: 3
    },
    {
        id: 5,
        name: 'Нефтекамск',
        kladrRegionId: '02',
        kladrRegionName: 'Башкортостан / Нефтекамск',
        factoryId: 3
    },
    {
        id: 6,
        name: 'Пермь',
        kladrRegionId: '59',
        kladrRegionName: 'Пермский край',
        factoryId: 3
    },
    {
        id: 7,
        name: 'Тюмень',
        kladrRegionId: '72',
        kladrRegionName: 'Тюменская область',
        factoryId: 3
    },
    {
        id: 8,
        name: 'Уфа',
        kladrRegionId: '02',
        kladrRegionName: 'Башкортостан / Уфа',
        factoryId: 3
    },
    {
        id: 9,
        name: 'Челябинск',
        kladrRegionId: '',
        kladrRegionName: '',
        factoryId: 3
    },
    {
        id: 10,
        name: 'Москва и Московская область',
        kladrRegionId: '77',
        kladrRegionName: 'Москва и Московская область ',
        factoryId: 4
    },
    {
        id: 11,
        name: 'Ишимбай',
        kladrRegionId: '',
        kladrRegionName: '',
        factoryId: 3
    },
    {
        id: 12,
        name: 'Владимир',
        kladrRegionId: '33',
        kladrRegionName: 'Владимирская область',
        factoryId: 4
    },
    {
        id: 13,
        name: 'Рязань',
        kladrRegionId: '62',
        kladrRegionName: 'Рязанская область',
        factoryId: 4
    },
    {
        id: 14,
        name: 'Нижний Новгород',
        kladrRegionId: '52',
        kladrRegionName: 'Нижегородская область',
        factoryId: 4
    }
]
