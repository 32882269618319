export class Claim {
    state: ClaimStates
    static getClaimStateName = (claim: Claim): string => {
        switch (claim.state) {
            case ClaimStates.Draft:
                return 'Черновик'
            case ClaimStates.Sent:
                return 'Отправлена'
            case ClaimStates.InProcess:
                return 'В работе'
            case ClaimStates.Elimination:
                return 'На устранении'
            case ClaimStates.CorrectionsNeeded:
                return 'Приостановлена'
            case ClaimStates.ClientCanceled:
                return 'Отклонена'
            case ClaimStates.Completed:
                return 'Завершена'
            case ClaimStates.PendingSkk:
                return 'Передаем СКК'
            default:
                return 'Черновик'
        }
    }

    static statusesList = [
        {id: 'draft', name: 'Черновик', color: '#757D85'},
        {id: 'sent', name: 'Отправлена', color: '#5928E5'},
        {id: 'in_process', name: 'В работе', color: '#0075FF'},
        {id: 'elimination', name: 'На устранении', color: '#ffa620'},
        {id: 'corrections_needed', name: 'Приостановлена', color: '#FBB605'},
        {id: 'client_canceled', name: 'Отклонена', color: '#CE4040'},
        {id: 'completed', name: 'Завершена', color: '#3ABF7C'},
        {id: 'pending_skk', name: 'Передаем СКК', color: '#5928E5'}
    ]
}
export enum ClaimStates {
    Draft = 'draft',
    Sent = 'sent',
    InProcess = 'in_process',
    Elimination = 'elimination',
    CorrectionsNeeded = 'corrections_needed',
    Completed = 'completed',
    ClientCanceled = 'client_canceled',
    PendingSkk = 'pending_skk'
}
