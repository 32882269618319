import {useLoading} from 'react-admin'

export const ProgressBar = (): JSX.Element | null => {
    const loading = useLoading()
    if (!loading) return null

    return (
        <div className={'backdrop'}>
            <span className={'spinner'} />
        </div>
    )
}
