import {Button} from '@mui/material'
import {usePartnerChangeGostEnabledModal} from '../../../../hooks/partners'
export const ChangeGostEnabled = (): JSX.Element => {
    const showModal = usePartnerChangeGostEnabledModal()

    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Включить/отключить установку параметра ГОСТ</>
        </Button>
    )
}
