import {useCallback, useMemo, useRef, useState} from 'react'
import {useGetDadataSuggestions} from '../../../hooks/geo'
import {Address} from '../../../models/address'
import {Coordinates} from '../../../models/coordinates'
import {ListItem} from '../../../models/list-item'
import {KladrManager} from '../../../services/kladr-manager'
import styles from './map-modal.module.css'
import {AutocompleteInput} from '../autocomplete-input/autocomplete-input'
import {YMap} from '../ymaps/ymap'

interface IMapModalProps {
    placeholder: string
    onSelect: (address: Address) => void
    initialValue?: Address
}

export const MapModal = (props: IMapModalProps): JSX.Element => {
    const {placeholder, onSelect, initialValue} = props
    const defaultValue = useMemo(() => (initialValue ? Address.parse(initialValue) : undefined), [initialValue])
    const [address, setAddress] = useState<Address>(defaultValue)
    const {getSuggestions, clearSuggestions, items} = useGetDadataSuggestions()
    const inputRef = useRef<HTMLInputElement>(null)
    const onClickCoords = useCallback(
        (val: Coordinates) => {
            KladrManager.getSuggestionsByCoords(val).then(r => {
                if (!r.length) return
                if (inputRef?.current) inputRef.current.value = r[0].value
                setAddress(Address.parseFromDadataAddress(r[0].data))
                onSelect(address)
            })
        },
        [address, onSelect]
    )

    const onClickSuggestion = useCallback(
        (address: ListItem<Address>) => {
            if (inputRef?.current) inputRef.current.value = address.name
            clearSuggestions()
            setAddress(address.data)
            onSelect(address.data)
        },
        [clearSuggestions, onSelect]
    )
    const suggestions = useMemo(
        () => items.map((i, idx) => new ListItem(idx.toString(), i.value, Address.parseFromDadataAddress(i.data))),
        [items]
    )
    return (
        <div className={styles.body}>
            <YMap onClickCoords={onClickCoords} address={address} />
            <div className={styles.bottom}>
                <div className={styles.info}>
                    <AutocompleteInput
                        ref={inputRef}
                        title={placeholder}
                        onSelect={onClickSuggestion}
                        items={suggestions}
                        getFunction={getSuggestions}
                        defaultValue={defaultValue?.toString()}
                        itemsClassName={styles.items}
                        resetFunction={clearSuggestions}
                    />
                </div>
            </div>
        </div>
    )
}
