import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {FunctionField} from 'react-admin'
import {useCallback} from 'react'
import {Order} from '../../../../../models/order'
import {showFieldTitle, showFieldValue} from '../../../../../consts/styles'

interface IForceShippingTypeProps {
    sx?: SxProps<Theme>
    title: string
    color?: string
}

export const ForceShippingType = (props: IForceShippingTypeProps): JSX.Element => {
    const {sx, color, title} = props
    const theme = useTheme()
    const render = useCallback((record: Order) => Order.getForceShippingType(record.forceShippingType), [])

    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={showFieldValue} render={render} />
        </Stack>
    )
}
