import {Button} from '@mui/material'
import {useNotRMAdmin} from '../../../../../hooks/common'
import {useProductSeparationStepModal} from '../../../../../hooks/orders'
export const ProductSeparationStep = (): JSX.Element => {
    const showModal = useProductSeparationStepModal()
    const isNotRmAdmin = useNotRMAdmin()
    if (!isNotRmAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Изменить количество одновременно пересчитываемых изделий для заказа</>
        </Button>
    )
}
