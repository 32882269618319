import {Show, ShowControllerProps} from 'react-admin'
import {Box, Card, Grid} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {RestartAmpq} from './components/restart_ampq/restart_ampq'
export const AmpqHandlersItem = (props: ShowControllerProps): JSX.Element => {
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Событие'} />
                        <ShowField source="name" title="Имя" />
                        <ShowField reference={'factories'} referenceSource={'factoryID'} title={'Завод'} source={'name'} link={'show'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <RestartAmpq label={'Перезапуск слушателя'} />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
