import {Datagrid, DateField, List, ReferenceField, ShowButton, ListProps, FunctionField} from 'react-admin'
import {UserListActions} from './actions'
import {UserListFilters} from './filters'
import {useChiefAdmin, useNotRMAdmin, useSuperAdmin} from '../../../hooks/common'
import {useCallback} from 'react'
import {User} from '../../../models/user'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const UserList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isNotRmAdmin = useNotRMAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const toShow = useCallback(() => isNotRmAdmin && 'show', [isNotRmAdmin])

    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<UserListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Пользователи"
            actions={<UserListActions />}>
            <Datagrid rowClick={toShow} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата регистрации" />
                <DateField source="deletedAt" showTime label="Дата Удаления" />
                <ClipboardTextField source="username" label="Логин" />
                <ClipboardTextField source="email" label="Email" />
                <ClipboardTextField source="filial.city.name" label="Город" />
                <FunctionField source="userRoles" label="Роль" render={u => User.rolesToString(u.userRoles)} />
                <ReferenceField source="selectedDealerId" label="Код 1С" reference="dealers" link="show">
                    <ClipboardTextField source="externalId" />
                </ReferenceField>
                {isNotRmAdmin && (
                    <ReferenceField source="partnerId" label="Телефоны" reference="partners" link="show">
                        <ClipboardTextField source="contactInfo.phone" />
                    </ReferenceField>
                )}
                {isNotRmAdmin && (
                    <ReferenceField source="filialId" label="Филиал" reference="filials" link="show">
                        <ClipboardTextField source="id" />
                    </ReferenceField>
                )}
                {isNotRmAdmin && <ShowButton />}
            </Datagrid>
        </List>
    )
}
