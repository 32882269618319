import {Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import {Card, Stack} from '@mui/material'
import {GlassCustomToolbar} from '../../../components/common/glass-custom-toolbar/glass-custom-toolbar'
import React from 'react'

export const ConstrGlassesEdit = (): JSX.Element => (
    <Edit title={'Редактирование стеклопакетов'}>
        <Card>
            <SimpleForm toolbar={<GlassCustomToolbar />}>
                <TextInput sx={{width: 510}} source="name" label="Название" required />
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <TextInput source="sku" label="Артикул" required />
                    <ReferenceInput source="factoryId" reference="factories">
                        <SelectInput label="Завод" optionText={'name'} required />
                    </ReferenceInput>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <NumberInput source="thickness" label="Толщина" required />
                    <SelectInput
                        source="glassType"
                        label="Тип стекла"
                        emptyValue={'empty'}
                        emptyText={'Не выбрано'}
                        choices={[
                            {id: 'lowEmission', name: 'Низкоэмиссионное'},
                            {id: 'multifunctional', name: 'Мультифункциональное'}
                        ]}
                        required
                    />
                </Stack>
                <NumberInput source="numpos" label="Позиция" required />
            </SimpleForm>
        </Card>
    </Edit>
)
