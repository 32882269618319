import {usePartnerDiscountVisiblityModal} from '../../../../hooks/partners'
import {useChiefAdmin, useCommonAdmin, useSuperAdmin} from '../../../../hooks/common'
import {Button} from '@mui/material'
export const ChangeDiscountVisibility = (): JSX.Element => {
    const showModal = usePartnerDiscountVisiblityModal()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isCommonAdmin = useCommonAdmin()
    if (!(isSuperAdmin || isChiefAdmin || isCommonAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Настройка видимости скидки</>
        </Button>
    )
}
