import {Datagrid, List, ReferenceField, ListProps, NumberField} from 'react-admin'
import {FrameSpacersListActions} from './actions'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {AvailableListButtons} from '../../../components/common/available-list-buttons/available-list-buttons'
import {FrameSpacersListFilters} from './filters'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const FramespacersList = (props: ListProps): JSX.Element => {
    return (
        <List
            filters={<FrameSpacersListFilters />}
            actions={<FrameSpacersListActions />}
            sx={{height: 'inherit', overflow: 'hidden'}}
            title="Дистанционные рамки"
            {...props}
            bulkActionButtons={false}>
            <Datagrid bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <ClipboardTextField source="sku" label="Артикул" />
                <ClipboardTextField source="name" label="Название" sx={{width: 1}} />
                <ClipboardTextField source="numpos" label="Позиция" sx={{width: 1}} />
                <NumberField source="thickness" label="Толщина" />
                <BooleanField inList source="isAvailable" label={'Активно'} />
                <ReferenceField source="factoryId" label="Завод" reference="factories" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <AvailableListButtons />
            </Datagrid>
        </List>
    )
}
