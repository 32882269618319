import {Datagrid, DateField, List, ReferenceField, ShowButton, ListProps, BooleanField} from 'react-admin'
import {useChiefAdmin, useElohinaAdmin, useNotRMAdmin, useSuperAdmin} from '../../../hooks/common'
import {Partner} from '../../../models/partner'
import {PartnerListActions} from './actions'
import {PartnerListFilters} from './filters'
import {useCallback} from 'react'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const PartnerList = (props: ListProps<Partner>): JSX.Element => {
    const {exporter} = props
    const isChiefAdmin = useChiefAdmin()
    const isElohinaAdmin = useElohinaAdmin()
    const isSuperAdmin = useSuperAdmin()
    const isNotRmAdmin = useNotRMAdmin()
    const toShow = useCallback(() => isNotRmAdmin && 'show', [isNotRmAdmin])
    return (
        <List<Partner>
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<PartnerListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin || isElohinaAdmin) && exporter}
            title="Партнеры"
            {...props}
            bulkActionButtons={false}
            actions={<PartnerListActions />}>
            <Datagrid rowClick={toShow} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" emptyText={'-'} />
                <DateField source="deletedAt" showTime label="Дата Удаления" emptyText={'-'} />
                <ClipboardTextField source="externalId" label="Код 1С" emptyText={'-'} />
                <ClipboardTextField source="name" label="Наименование" />
                <ClipboardTextField source="city" label="Город" />
                <BooleanField source="isDeliveryAvailable" label="Доступна доставка" />
                <BooleanField source="isSubdealershipAvailable" label="Доступно субдилерство" />
                <ClipboardTextField source="factoryId" label="Ид завода" />
                <ReferenceField source="selectedDealerId" label="Контрагент" reference="dealers" link="show">
                    <ClipboardTextField source="id" />
                </ReferenceField>
                <ClipboardTextField source={'discountString'} label={'Скидки'} />
                <ClipboardTextField source="contactInfo.phone" label="Телефоны" />
                {isNotRmAdmin && <ShowButton />}
            </Datagrid>
        </List>
    )
}
