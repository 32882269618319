import {usePartnerChangeCityModal} from '../../../../hooks/partners'
import {useChiefAdmin, useSuperAdmin} from '../../../../hooks/common'
import {Button} from '@mui/material'
export const ChangeCity = (): JSX.Element => {
    const showModal = usePartnerChangeCityModal()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Сменить город</>
        </Button>
    )
}
