import {AutocompleteInput, DateInput, Filter, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {useNotRMAdmin} from '../../../hooks/common'
import {User} from '../../../models/user'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserListFilters = (props): JSX.Element => {
    const isNotRmAdmin = useNotRMAdmin()
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Партнер'} />
            </ReferenceInput>
            {isNotRmAdmin && (
                <ReferenceInput label="Филиал" source="filialId" reference="filials" alwaysOn perPage={10}>
                    <AutocompleteInput label="Филиал" size={'small'} optionText="name" emptyText="Все" />
                </ReferenceInput>
            )}
            <SelectInput label="Роль" source="userRole" alwaysOn emptyText="Все" choices={User.roles} />
            <DateInput label="Дата регистрации от" source="dateFrom" alwaysOn />
            <DateInput label="Дата регистрации до" source="dateTo" alwaysOn />
        </Filter>
    )
}
