import {DateInput, Filter, SelectInput, TextInput} from 'react-admin'
import {booleanItems} from '../../../consts/others'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AmegaEmployeesFilter = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput label="Удален" source="notDeleted" emptyText="Все" alwaysOn choices={booleanItems} />
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
        </Filter>
    )
}
