import {Theme} from '@mui/material'

export const CardStyle = (theme: Theme): Object => {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: theme.shadows[2],
                    borderRadius: Number(theme.shape.borderRadius) * 2,
                    position: 'relative',
                    zIndex: 0, // Fix Safari overflow: hidden with border radius
                    backgroundImage: 'none'
                }
            }
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {variant: 'h6'},
                subheaderTypographyProps: {variant: 'body2'}
            }
        }
    }
}
