import styles from './autocomplete-input.module.css'
import React from 'react'
import {ListItem} from '../../../models/list-item'

interface IAutocompleteInputItemsProps<T> {
    items?: ListItem<T>[]
    onClick: (item: ListItem<T>) => void
    className?: string
}

export const AutocompleteInputItems = <T extends unknown>(props: IAutocompleteInputItemsProps<T>): JSX.Element => {
    const {items, onClick, className} = props
    if (!items?.length) return <></>
    return (
        <div className={`${styles.items} ${className}`}>
            {items.map((item, idx) => (
                <p key={idx} onClick={() => onClick(item)} className={styles.item} data-index={idx}>
                    {item.name}
                </p>
            ))}
        </div>
    )
}
