import {ExportButton, ListActionsProps, TopToolbar} from 'react-admin'
import {FilterResetButton} from '../../../components/common/filter-reset-button/filter-reset-button'

export const DealerListActions = (props: ListActionsProps): JSX.Element => {
    const {className, ...rest} = props
    return (
        <TopToolbar {...rest}>
            <ExportButton variant="outlined" color="inherit" />
            <FilterResetButton />
        </TopToolbar>
    )
}
