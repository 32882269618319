import {Filter, ReferenceInput, TextInput} from 'react-admin'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FrameSpacersListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <ReferenceInput label="Завод" source="factoryId" reference="factories" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Завод'} />
            </ReferenceInput>
        </Filter>
    )
}
