import {IPartnerJSON, Partner} from './partner'
import {IAffiliateJSON} from './affiliate'
import {IDealerJSON} from './dealer'
import decodeJwt from 'jwt-decode'
import {IUserPermissionsJSON} from './login'
import {FactoryTypes} from './factory-types'

export interface IUserJSON {
    id: number
    createdAt: string
    partner?: IPartnerJSON
    partnerId: number
    filial: IAffiliateJSON
    filialId: number
    dealer: IDealerJSON
    selectedDealerId: number
    isFramerPointsSelected: boolean
    userRoles: ['BossManager']
    email: string
    name: string
    username: string
    isLocked: boolean
    lastReadNewsId: number
    /// Токен для копирования заказа - Пермь
    offerAddonLine1: string
    /// Токен для копирования заказа - МСК
    offerAddonLine2: string
}
export class User {
    id: number
    createdAt: string
    partner?: Partner
    partnerId: number
    filial: IAffiliateJSON
    filialId: number
    dealer: IDealerJSON
    selectedDealerId: number
    isFramerPointsSelected: boolean
    userRoles: ['BossManager']
    email: string
    name: string
    username: string
    isLocked: boolean
    lastReadNewsId: number
    /// Токен для копирования заказа - Пермь
    offerAddonLine1: string
    /// Токен для копирования заказа - МСК
    offerAddonLine2: string

    static parse(json: IUserJSON): User {
        const user = new User()
        Object.assign(user, json)
        return user
    }

    static roles = [
        {id: 'BossManager', name: 'Босс менеджер'},
        {id: 'ChiefManager', name: 'Главный менеджер'},
        {id: 'CommonManager', name: 'Менеджер'},
        {id: 'RMAdmin', name: 'Региональный менеджер'},
        {
            id: 'NonqualifiedBossManager',
            name: 'Неподтвержденный босс менеджер'
        }
    ]
    static rolesToString(roles?: string[]): string {
        if (!roles) return ''
        return roles.map(role => User.roles.find(r => role === r.id)?.name).join(',')
    }
    static roleToString(role?: string): string {
        if (!role) return ''
        return User.roles.find(r => role === r.id)?.name
    }
    get isPrm(): boolean {
        return this.partner.factoryId === FactoryTypes.Perm
    }
    get mskUserForOrderCopy(): IUserPermissionsJSON | undefined {
        if (!this.offerAddonLine1.length) return undefined
        return decodeJwt<IUserPermissionsJSON>(this.offerAddonLine1)
    }
    get prmUserForOrderCopy(): IUserPermissionsJSON | undefined {
        if (!this.offerAddonLine2.length) return undefined
        return decodeJwt<IUserPermissionsJSON>(this.offerAddonLine2)
    }

    get mskActualToken(): IUserPermissionsJSON | undefined {
        if (!this.mskUserForOrderCopy) return undefined
        const exp = new Date(this.mskUserForOrderCopy.exp * 1000)
        const now = new Date()
        if (now > exp) return undefined
        return this.mskUserForOrderCopy
    }
    get prmActualToken(): IUserPermissionsJSON | undefined {
        if (!this.prmUserForOrderCopy) return undefined
        const exp = new Date(this.prmUserForOrderCopy.exp * 1000)
        const now = new Date()
        if (now > exp) return undefined
        return this.prmUserForOrderCopy
    }
}
