import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useUpdateInfoModal} from '../../../../hooks/users'
import {useChiefAdmin, useSuperAdmin} from '../../../../hooks/common'

export const UserUpdateInfo = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useUpdateInfoModal()
    const chiefAdmin = useChiefAdmin()
    const superAdmin = useSuperAdmin()

    if (!chiefAdmin && !superAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
