import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {FunctionField} from 'react-admin'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {showFieldTitle} from '../../../consts/styles'

interface IBooleanFieldProps {
    source?: string
    color?: string
    sx?: SxProps<Theme>
    label?: string
    inList?: boolean
}

export const BooleanField = (props: IBooleanFieldProps): JSX.Element => {
    const {label, source, sx, color, inList = false} = props
    const theme = useTheme()
    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            {!inList && <Typography sx={showFieldTitle(theme)}>{label}</Typography>}
            <FunctionField
                source={source}
                render={record =>
                    record[source] ? (
                        <CheckCircleIcon sx={{width: '18px'}} color={'success'} />
                    ) : (
                        <CancelIcon sx={{width: '18px'}} color={'error'} />
                    )
                }
            />
        </Stack>
    )
}
