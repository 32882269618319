export interface IDiscountJSON {
    cAl: number
    lam: number
    nst: number
    st: number
    wAl: number
}

export class Discount {
    cAl = 0
    lam = 0
    nst = 0
    st = 0
    wAl = 0
    gls = 0
    gds = 0
    met = 0
    ttl = 0

    static titles(key: keyof Discount): string {
        switch (key) {
            case 'st':
                return 'Конструкции/Стандарт'
            case 'gls':
                return 'Стеклопакеты'
            case 'gds':
                return 'Допы'
            case 'met':
                return 'Металл'
            case 'nst':
                return 'Нестандарт'
            case 'lam':
                return 'Ламинация'
            case 'wAl':
                return 'Теплый алюминий'
            case 'cAl':
                return 'Холодный алюминий'
            case 'ttl':
                return null // Hide property field.
        }
    }
}

export interface IDiscountEditForm {
    discount: Discount
    additionalDiscountFramerPoints?: number
    comment: string
}
