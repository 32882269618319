import {IDadataSuggestion, KladrManager} from '../services/kladr-manager'
import {useCallback, useEffect, useState} from 'react'
import {useYMaps} from '@pbe/react-yandex-maps'
import {YMapsApi} from '@pbe/react-yandex-maps/typings/util/typing'

export const useGetDadataSuggestions = (): {
    items: IDadataSuggestion[]
    getSuggestions: (value: string) => void
    clearSuggestions: () => void
    loading: boolean
} => {
    const [items, setItems] = useState<IDadataSuggestion[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const getSuggestions = useCallback(async (value: string): Promise<void> => {
        setLoading(true)
        const items = await KladrManager.getSuggestions(value, null)
        setItems(items)
        setLoading(false)
    }, [])
    const clearSuggestions = useCallback(() => setItems([]), [])

    return {items, getSuggestions, clearSuggestions, loading}
}
export const useGeolocation = (): number[] => {
    const [geo, setGeo] = useState<number[] | null>(null)
    const api = useYMaps(['geolocation'])
    const getGeo = async (): Promise<void> =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (api as YMapsApi)?.geolocation?.get().then(result => setGeo(result.geoObjects.get(0).geometry.getCoordinates()))
    useEffect(() => {
        getGeo().catch(console.log)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api?.geolocation])
    return geo
}
