import {Theme} from '@mui/material'

export const SidebarStyles = (theme: Theme): Object => ({
    RaSidebar: {
        styleOverrides: {
            root: {
                boxShadow:
                    '0 .125rem 9.375rem rgba(90,97,105,.1),0 .25rem .5rem rgba(90,97,105,.12),0 .9375rem 1.375rem rgba(90,97,105,.1),0 .4375rem 2.1875rem rgba(165,182,201,.1)',
                zIndex: 3
            }
        }
    },
    RaMenuItemLink: {
        styleOverrides: {
            root: {
                '&.RaMenuItemLink-active': {
                    color: theme.palette.primary.main
                }
            }
        }
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: '.85rem',
                backgroundColor: '#fbfbfb',
                padding: '21px'
            }
        }
    }
})
