import {useCallback, useContext, useEffect, useState} from 'react'
import {GetOneResult, useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {Stack} from '@mui/material'
import {useFormik} from 'formik'
import {IUserJSON, User} from '../models/user'
import {AccountUser, ISupportAccount} from '../models/account_user'
import {FormInput} from '../components/common/form-fields/form-input'
import {useRequest} from './network'
import {authProvider, dataProvider} from '../providers'
import * as Yup from 'yup'
import Typography from '@mui/material/Typography'
import {useNavigate} from 'react-router'

export const useProfile = (): User => {
    const [user, setUser] = useState<User>()
    const {mutate} = useRequest(authProvider.getProfile, {onSuccess: data => setUser(User.parse(data))})
    useEffect(() => {
        mutate()
    }, [mutate])
    return user
}
export const useSupportAccountByTokens = (profile: User): {prm: User; msk: User} => {
    const [prm, setPrm] = useState<User>()
    const [msk, setMsk] = useState<User>()

    const setAccount = useCallback((data: GetOneResult<IUserJSON>): void => {
        const acc = User.parse(data.data)
        if (acc.isPrm) setPrm(acc)
        else setMsk(acc)
    }, [])

    const {mutate} = useRequest<GetOneResult<IUserJSON>, unknown, number>(id => dataProvider.getOne<IUserJSON>('users', {id}), {
        onSuccess: setAccount
    })

    useEffect(() => {
        if (!profile) return
        if (profile.prmActualToken) mutate(+profile.prmActualToken?.sub)
        if (profile.mskActualToken) mutate(+profile.mskActualToken?.sub)
    }, [mutate, profile])

    return {prm, msk}
}
export const useUpdateInfoModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const record = useRecordContext<User>()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const submitHandler = useCallback((values: User) => dataProvider.updateUserInfo(values), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<User>({initialValues: record, onSubmit: submitHandler, enableReinitialize: true})
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить партнера',
                body: (
                    <>
                        <FormInput
                            sx={{mr: 2}}
                            size={'small'}
                            defaultValue={values?.username}
                            autoFocus
                            margin="dense"
                            label="Логин"
                            type="text"
                            onChange={e => setFieldValue('username', e.target.value)}
                        />
                        <FormInput
                            size={'small'}
                            defaultValue={values?.email}
                            autoFocus
                            margin="dense"
                            label="Email"
                            type="text"
                            onChange={e => setFieldValue('email', e.target.value)}
                        />
                    </>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [mutate, setFieldValue, show, values?.email, values?.username]
    )
}
export const useUpdatePasswordModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(
        (values: {password: string}) => dataProvider.updateUserPassword(values.password, record?.id),
        [dataProvider, record?.id]
    )
    const {values, submitForm, setFieldValue} = useFormik({initialValues: {password: ''}, onSubmit: submitHandler})
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить пароль партнера',
                body: (
                    <FormInput
                        defaultValue={values.password}
                        autoFocus
                        margin="dense"
                        label="Пароль"
                        type="text"
                        onChange={v => setFieldValue('password', v.target.value)}
                    />
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [mutate, setFieldValue, show, values.password]
    )
}
export const useAddRegionalManagerModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const submitHandler = useCallback((values: AccountUser) => dataProvider.addRegionalManager(values), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<AccountUser>({
        initialValues: AccountUser.empty,
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Создать регионального менеджера',
                body: (
                    <Stack gap={1.5}>
                        <Stack gap={1.5} direction={'row'}>
                            <FormInput
                                defaultValue={values.login}
                                size={'small'}
                                margin="none"
                                label="Логин"
                                type="text"
                                onChange={v => setFieldValue('login', v.target.value)}
                            />
                            <FormInput
                                defaultValue={values.email}
                                size={'small'}
                                label="Email"
                                type="email"
                                onChange={v => setFieldValue('email', v.target.value)}
                            />
                        </Stack>
                        <Stack gap={1.5} direction={'row'}>
                            <FormInput
                                defaultValue={values.password}
                                size={'small'}
                                label="Пароль"
                                type="password"
                                onChange={v => setFieldValue('password', v.target.value)}
                            />
                            <FormInput
                                defaultValue={values.confirmPassword}
                                size={'small'}
                                label="Повтор пароля"
                                type="password"
                                onChange={v => setFieldValue('confirmPassword', v.target.value)}
                            />
                        </Stack>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [mutate, setFieldValue, show, values.confirmPassword, values.email, values.login, values.password]
    )
}
export const useSupportAccountUpdateTokens = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const submitHandler = useCallback((values: ISupportAccount) => dataProvider.updateTokensForSupport(values), [dataProvider])
    const navigate = useNavigate()
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            onClose()
            navigate(0)
        }
    })

    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: {username: '', password: ''},
                validationSchema: Yup.object().shape({
                    username: Yup.string().required('Поле должно быть заполнено'),
                    password: Yup.string().required('Поле должно быть заполнено')
                }),
                title: 'Обновить аккаунт для копирования заказов',
                body: ({values, setFieldValue, errors}) => {
                    return (
                        <Stack gap={1.5}>
                            <Typography>Завод аккаунта будет определен автоматически</Typography>
                            <Stack gap={1.5} direction={'row'}>
                                <FormInput
                                    defaultValue={values.username}
                                    error={!!errors.username}
                                    helperText={errors.username}
                                    size={'small'}
                                    margin="none"
                                    label="Логин"
                                    autoComplete="off"
                                    type="text"
                                    onChange={v => setFieldValue('username', v.target.value)}
                                />
                                <FormInput
                                    defaultValue={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    size={'small'}
                                    label="Пароль"
                                    autoComplete="new-password"
                                    type="password"
                                    onChange={v => setFieldValue('password', v.target.value)}
                                />
                            </Stack>
                        </Stack>
                    )
                },
                onSuccess: mutate,
                successText: 'Обновить',
                cancelText: 'Отмена'
            }),
        [mutate, show]
    )
}
