import {useChiefAdmin, useSuperAdmin} from '../../../../hooks/common'
import {Button} from '@mui/material'
import {useSupportAccountUpdateTokens} from '../../../../hooks/users'
export const UpdateToken = (): JSX.Element => {
    const showModal = useSupportAccountUpdateTokens()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Обновить</>
        </Button>
    )
}
