import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {showFieldTitle, showFieldValue} from '../../../consts/styles'
import {FunctionField} from 'react-admin'

interface IClaimStateFieldProps<T> {
    title: string
    render: (record?: T, source?: string) => JSX.Element | string
    sx?: SxProps<Theme>
    direction?: 'row' | 'column'
    color?: string
    source?: string
}

export const FunctionalShowField = <T extends unknown>(props: IClaimStateFieldProps<T>): JSX.Element => {
    const {title, render, sx, direction = 'row', color, source} = props
    const theme = useTheme()

    return (
        <Stack
            alignItems={direction === 'column' ? 'flex-start' : 'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={direction}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={showFieldValue} source={source} render={render} />
        </Stack>
    )
}
