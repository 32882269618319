export const numbersToWeekdaysMap = {
    '1': 'пн',
    '2': 'вт',
    '3': 'ср',
    '4': 'чт',
    '5': 'пт',
    '6': 'сб',
    '7': 'вс'
}
export const toggleWeekdaysInArray = (arr: string[], value: string): string[] => {
    return arr?.includes(value) ? arr.slice(0).filter(v => v !== value) : [...arr, value]
}
