export class Refund {
    id = 0
    dealerId = 0
    accountType = 1
    comment = ''
    amount = 0
    createdAt?: string

    constructor(id: number) {
        this.dealerId = id
    }

    static getRefundOptions(refund: Refund): {label: string; value: number} {
        return {
            value: refund?.id,
            label:
                refund?.createdAt?.split('T')[0] +
                '; ' +
                refund.amount / 100 +
                ` ${refund?.accountType === 2 ? 'фант.' : 'руб.'}; ` +
                refund.comment
        }
    }
}
