import {Placemark, Map, ZoomControl, FullscreenControl} from '@pbe/react-yandex-maps'
import {useCallback, useMemo} from 'react'
import {Address} from '../../../models/address'
import {Coordinates} from '../../../models/coordinates'
export class MapPlacemark {
    id: number
    name: string
    coordinates: Coordinates
    preset: string

    constructor(id: number, name: string, coordinates: Coordinates, preset?: string) {
        this.id = id
        this.name = name
        this.coordinates = coordinates
        this.preset = preset
    }
    static fromAddress(addr: Address): MapPlacemark {
        return new MapPlacemark(-1, addr.toString(), addr.coordinates)
    }
}

interface IYMapsProps {
    placemarks?: MapPlacemark[]
    selectedPlacemark?: MapPlacemark
    onSelect?: (placemark: MapPlacemark) => void
    zoom?: number
    address?: Address
    onClickCoords?: (coordinates: Coordinates) => void
    withoutControl?: boolean
}

export const YMap = (props: IYMapsProps): JSX.Element => {
    const {placemarks, selectedPlacemark, zoom = 16, address, onClickCoords} = props
    const center = useMemo(
        () => address?.coordinates?.toArray ?? selectedPlacemark?.coordinates?.toArray ?? [58.0105, 56.2502],
        [address?.coordinates?.toArray, selectedPlacemark?.coordinates?.toArray]
    )
    const initialState = useMemo(
        () => ({
            zoom,
            center
        }),
        [center, zoom]
    )
    const onMapClick = useCallback(e => onClickCoords(Coordinates.fromArray(e.get('coords'))), [onClickCoords])
    console.log(address)
    return (
        <Map onClick={onMapClick} width={'100%'} height={'100%'} state={initialState}>
            {placemarks &&
                placemarks.map(p => (
                    <Placemark
                        key={p.id}
                        options={{preset: selectedPlacemark?.id === p.id ? `islands#red${p.preset}` : `islands#blue${p.preset}`}}
                        geometry={p.coordinates.toArray}
                    />
                ))}
            {address && (
                <Placemark key={'address_mark'} options={{preset: 'islands#redHomeCircleIcon'}} geometry={address?.coordinates?.toArray} />
            )}
            <FullscreenControl />
            <ZoomControl />
        </Map>
    )
}
