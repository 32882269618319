import {useRecordContext} from 'react-admin'
import {Partner} from '../../../../models/partner'
import {ProfileDiscountRow} from './profile-discount-row'
import {useCallback} from 'react'
import {Discount} from '../../../../models/discount'
interface IProfileDiscountsProps {
    onChange: (val: {[p: string]: Discount}) => void
}
export const ProfileDiscounts = (props: IProfileDiscountsProps): JSX.Element => {
    const {onChange} = props
    const record = useRecordContext<Partner>()
    const profileDiscounts = record?.AdditionalDiscounts?.profileDiscounts
    const changeHandler = useCallback(
        (profile: string, discounts: Discount) => onChange({...profileDiscounts, [profile]: discounts}),
        [onChange, profileDiscounts]
    )
    const removeHandler = useCallback(
        (profile: string) => {
            const temp = {...profileDiscounts}
            delete temp[profile]
            onChange(temp)
        },
        [onChange, profileDiscounts]
    )
    if (!profileDiscounts) return <></>
    return (
        <>
            {Object.entries(profileDiscounts).map(object => (
                <ProfileDiscountRow onRemove={removeHandler} onChange={changeHandler} key={object[0]} discountObject={object} />
            ))}
        </>
    )
}
