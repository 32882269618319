import {forwardRef, useCallback, useRef, ChangeEvent} from 'react'
import styles from './autocomplete-input.module.css'
import {AutocompleteInputItems} from './autocomplete-input-items'
import {FormInput} from '../form-fields/form-input'
import {ListItem} from '../../../models/list-item'
import {useOuterClick} from '../../../hooks/ui'
interface IAutocompleteInputProps<T> {
    error?: string
    disabled?: boolean
    itemsClassName?: string
    defaultValue?: string
    title: string
    items?: ListItem<T>[]
    getFunction?: (value: string) => void
    resetFunction?: () => void
    onSelect: (item: ListItem<T>) => void
    debounceTime?: number
    onFocus?: () => void
    onBlur?: () => void
}

export const AutocompleteInput = forwardRef(<T extends unknown>(props: IAutocompleteInputProps<T>, ref): JSX.Element => {
    const {
        error,
        disabled,
        itemsClassName,
        defaultValue,
        title,
        items,
        getFunction,
        resetFunction,
        onSelect,
        debounceTime = 1000,
        onFocus,
        onBlur
    } = props
    const containerRef = useOuterClick<HTMLDivElement>(() => resetFunction && resetFunction())
    const inputRef = useRef<HTMLInputElement>(null)
    const timerRef = useRef(null)
    const resetTimer = useCallback((): void => {
        timerRef.current && clearTimeout(timerRef.current)
    }, [])
    const selectHandler = useCallback(
        (item: ListItem<T>) => {
            if (inputRef?.current?.value) inputRef.current.value = item.name
            onSelect(item)
        },
        [onSelect, inputRef]
    )
    const changeHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            resetTimer()
            timerRef.current = setTimeout(() => getFunction && getFunction(e.target.value), debounceTime)
        },
        [debounceTime, getFunction, resetTimer]
    )
    const focusHandler = useCallback(async () => {
        if (!inputRef.current || disabled) return
        const query = inputRef.current.value
        if (!query?.length) return
        resetTimer()
        getFunction && getFunction(query)
        onFocus && onFocus()
    }, [disabled, getFunction, onFocus, resetTimer])
    const refHandler = useCallback(
        (node: HTMLInputElement): void => {
            inputRef.current = node
            if (typeof ref === 'function') ref(node)
            else if (ref) ref.current = node
        },
        [ref]
    )
    return (
        <div ref={containerRef} className={styles.container}>
            <FormInput
                ref={refHandler}
                error={!!error}
                sx={{width: '100%', mb: 2}}
                margin={'dense'}
                size={'small'}
                defaultValue={defaultValue}
                label={title}
                onFocus={focusHandler}
                onChange={changeHandler}
                onBlur={onBlur}
                fullWidth
                helperText={error}
            />

            <AutocompleteInputItems className={itemsClassName} items={items} onClick={selectHandler} />
        </div>
    )
})
AutocompleteInput.displayName = 'AutocompleteInput'
