import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {showFieldTitle, showFieldValue} from '../../../../../consts/styles'
import {FunctionField} from 'react-admin'
import {Dealer} from '../../../../../models/dealer'

interface IPaymentTypeProps {
    title: string
    color?: string
    sx?: SxProps<Theme>
}

export const PaymentType = (props: IPaymentTypeProps): JSX.Element => {
    const {color, sx, title} = props
    const theme = useTheme()

    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={showFieldValue} render={rec => Dealer.getPaymentType(rec.paymentType)} />
        </Stack>
    )
}
