import {Datagrid, List, ReferenceField, FunctionField, DateField, downloadCSV} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {AccountPaymentsListActions} from './actions'
import {AccountPaymentsListFilters} from './filters'
import {Dealer} from '../../../models/dealer'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'
import * as jsonExport from 'jsonexport/dist'
export const AccountPaymentsList = (): JSX.Element => {
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const exporter = (records, fetchRelatedRecords): void => {
        const headers = [
            'id',
            'createdAt',
            'updatedAt',
            'operationType',
            'paymentType',
            'operationState',
            'amount',
            'reservedScore',
            'comment',
            'dealerId',
            'partnerId',
            'orderId',
            'windrawOrderNumber'
        ]
        fetchRelatedRecords(records, 'orderId', 'orders').then(orders => {
            const data = records.map(r => ({...r, windrawOrderNumber: orders[r.orderId]?.windrawOrderNumber ?? '-'}))
            return jsonExport(data, {headers}, (err, csv) => downloadCSV(csv, 'comments'))
        })
    }
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<AccountPaymentsListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<AccountPaymentsListActions />}>
            <Datagrid bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="updatedAt" showTime label="Дата Обновления" />
                <FunctionField label="Тип операции" render={record => Dealer.getOperationType(record?.operationType)} />
                <FunctionField label="Тип платежа" render={record => Dealer.getPaymentType(record?.paymentType)} />
                <FunctionField label="Состояние операции" render={record => Dealer.getOperationState(record?.operationState)} />
                <FunctionField label="Сумма" render={record => record.amount / 100} />
                <ClipboardTextField source="reservedScore" label="Использовано бонусов" />
                <ClipboardTextField source="comment" label="Комментарий" emptyText={'-'} />
                <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField source="orderId" label="Заказ" reference="orders" link="show">
                    <ClipboardTextField source="windrawOrderNumber" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
