import {IconButton, Stack, TableCell, TableRow} from '@mui/material'
import {Discount} from '../../../../models/discount'
import {useDiscountEditModal} from '../../../../hooks/partners'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import {useCallback} from 'react'

interface IProfileDiscountRowProps {
    discountObject: [string, Discount]
    onChange: (profile: string, discounts: Discount) => void
    onRemove: (profile: string) => void
}

export const ProfileDiscountRow = (props: IProfileDiscountRowProps): JSX.Element => {
    const {discountObject, onChange, onRemove} = props
    const [profile, discounts] = discountObject
    const onSubmit = useCallback(({discount}) => onChange(profile, discount), [onChange, profile])
    const removeHandler = useCallback(() => onRemove(profile), [onRemove, profile])
    const show = useDiscountEditModal(discounts, onSubmit)
    return (
        <TableRow key={profile}>
            <TableCell>{profile}</TableCell>
            <TableCell>{discounts.st}%</TableCell>
            <TableCell>{discounts.nst}%</TableCell>
            <TableCell>{discounts.lam}%</TableCell>
            <TableCell>{discounts.wAl}%</TableCell>
            <TableCell>{discounts.cAl}%</TableCell>
            <TableCell></TableCell>
            <TableCell>
                <Stack direction={'row'}>
                    <IconButton color="primary" onClick={show}>
                        <AppRegistrationIcon />
                    </IconButton>
                    <IconButton color="error" onClick={removeHandler}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
}
