import {useMemo} from 'react'
import doc_mini from '../../../images/doc_img.png'
import {FileTypeBadge} from '../file-type-badge/file-type-badge'
import {ClaimFile} from '../../../models/claim-file'

interface IImageMiniatureProps {
    image: ClaimFile
}

export const ImageMiniature = (props: IImageMiniatureProps): JSX.Element => {
    const {image} = props

    const docTypes = useMemo(() => ['jpg', 'jpeg', 'png', 'gif'], [])
    const type = useMemo(() => image.originalFilename.split('.').pop(), [image.originalFilename])
    const src = useMemo(() => (!docTypes.includes(type) ? doc_mini : image.downloadUrl), [docTypes, type, image.downloadUrl])
    return (
        <div
            style={{
                width: '66px',
                height: '66px',
                borderRadius: '4px',
                position: 'relative',
                marginRight: '12px',
                marginBottom: '12px',
                border: '1px solid #D8D8D8'
            }}>
            <a href={image.downloadUrl} target={'_blank'} download rel="noreferrer">
                <img style={{width: '66px', height: '66px', borderRadius: '4px'}} src={src} alt={'miniature'} />
            </a>
            <FileTypeBadge name={type.toUpperCase()} />
        </div>
    )
}
