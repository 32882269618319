import {useTheme} from '@mui/material'

interface IFileTypeBadgeProps {
    name: string
}
export const FileTypeBadge = (props: IFileTypeBadgeProps): JSX.Element => {
    const {name} = props
    const theme = useTheme()
    return (
        <div
            style={{
                background: theme.palette.primary.main,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
                borderRadius: '3px',
                position: 'absolute',
                top: '8px',
                padding: '0 5px',
                left: '6px'
            }}>
            <p style={{fontWeight: 700, fontSize: '8px', color: theme.palette.common.white, lineHeight: 0}}>{name}</p>
        </div>
    )
}
