import {ArrayField, Datagrid, FunctionField, TextField} from 'react-admin'
import {FormattedConstructionCost} from './components/formatted-construction-cost/formatted-construction-cost'
import {Card} from '@mui/material'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

interface IOrderItemConstructionsProps {
    calculatedByFramerPoints?: boolean
}
export const OrderItemConstructions = (props: IOrderItemConstructionsProps): JSX.Element => {
    const {calculatedByFramerPoints} = props
    return (
        <Card>
            <ArrayField source="constructions" label={'Промокоды'}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source={'position'} label={'№ п/п'} />
                    <FunctionField
                        label={'Миниатюра конструкции'}
                        render={rec => <img src={`data:image/svg+xml;base64, ${rec.image}`} alt="Миниатюра конструкции" />}
                    />
                    <ClipboardTextField label={'Количество'} source={'quantity'} />
                    <FormattedConstructionCost
                        label={'Цена с наценкой'}
                        source={'totalCost'}
                        calculatedByFramerPoints={calculatedByFramerPoints}
                    />
                    <FunctionField
                        label={'Цена без наценки'}
                        render={c =>
                            c.amegaCost !== c.amegaCostToDisplay
                                ? `${c.amegaCost / 100} ₽ / ${c.amegaCostToDisplay / 100} ₽`
                                : `${c.amegaCost / 100} ₽`
                        }
                    />
                </Datagrid>
            </ArrayField>
        </Card>
    )
}
