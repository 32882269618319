import {Button} from '@mui/material'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useCreateAtWindrawAnd1c} from '../../../../../hooks/orders'
export const CreateAtWindrawAnd1c = (): JSX.Element => {
    const showModal = useCreateAtWindrawAnd1c()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Создать заказ в Windraw и 1С?</>
        </Button>
    )
}
