/* eslint-disable @typescript-eslint/naming-convention */
import {Theme} from '@mui/material'

export const AppbarStyles = (theme: Theme): Object => ({
    MuiAppBar: {
        styleOverrides: {
            root: {
                background: theme.palette.grey[0],
                boxShadow: '2px 6px 20px rgba(0, 0, 0, 0.06)',
                color: theme.palette.grey[900]
            }
        }
    }
})
