import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {useFormik} from 'formik'
import {useRequest} from './network'

export const useRestartWindrawServicesModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback((values: {factoryId: number}) => dataProvider.restartWindrawServices(values?.factoryId), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<{factoryId: number}>({
        initialValues: {factoryId: undefined},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Выберите завод для перезапуска сервисов расчета:',
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.factoryId}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('factoryId', e.target.value)}>
                        <FormControlLabel value={3} control={<Radio />} label="Пермь" />
                        <FormControlLabel value={4} control={<Radio />} label="МСК" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Перезапустить'
            }),
        [mutate, setFieldValue, show, values?.factoryId]
    )
}

export const useStartWindrawOrderCreateServiceModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback(
        (values: {factoryId: number}) => dataProvider.startWindrawOrderCreateService(values?.factoryId),
        [dataProvider]
    )
    const {values, submitForm, setFieldValue} = useFormik<{factoryId: number}>({
        initialValues: {factoryId: undefined},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Выберите завод для запуска сервиса создания заказов:',
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.factoryId}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('factoryId', e.target.value)}>
                        <FormControlLabel value={3} control={<Radio />} label="Пермь" />
                        <FormControlLabel value={4} control={<Radio />} label="МСК" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Запустить'
            }),
        [mutate, setFieldValue, show, values?.factoryId]
    )
}
export const useStartDatabaseUpdateServiceModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback((values: {factoryId: number}) => dataProvider.startWindrawDbUpdate(values?.factoryId), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<{factoryId: number}>({
        initialValues: {factoryId: undefined},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Выберите завод для обновления БД:',
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.factoryId}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('factoryId', e.target.value)}>
                        <FormControlLabel value={3} control={<Radio />} label="Пермь" />
                        <FormControlLabel value={4} control={<Radio />} label="МСК" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Запустить'
            }),
        [mutate, setFieldValue, show, values?.factoryId]
    )
}
export const useStopWindrawServiceModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const refresh = useRefresh()
    const record = useRecordContext()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback(() => dataProvider.stopWindrawService(record?.id), [dataProvider, record?.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Остановка сервиса',
                body: <>Остановить сервис?</>,
                onSuccess: mutate,
                successText: 'Остановить'
            }),
        [mutate, show]
    )
}
