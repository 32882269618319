import {Button} from '@mui/material'
import {FieldProps} from 'react-admin'
import {useUpdateModelUserParams} from '../../../../hooks/user_params'

export const UpdateModel = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useUpdateModelUserParams()
    return (
        <Button sx={{mt: 1}} fullWidth variant={'contained'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
