import {Filter, SelectInput, TextInput} from 'react-admin'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AmpqHandlersListFilter = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput
                label="Завод"
                source="factoryId"
                emptyText="Все"
                alwaysOn
                choices={[
                    {id: '3', name: 'Пермь'},
                    {id: '4', name: 'Электросталь'}
                ]}
            />
        </Filter>
    )
}
