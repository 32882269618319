import {DataGridStyle} from './datagrid'
import {BackDropStyle} from './backdrop'
import {ButtonStyle} from './button'
import {CardStyle} from './card'
import {CssBaseline} from './cssBaseline'
import {IconButtonStyle} from './iconButton'
import {InputStyle} from './input'
import {ListsStyle} from './lists'
import {PaperStyle} from './paper'
import {TooltipStyle} from './tooltip'
import {TypographyStyle} from './typography'
import {Theme} from '@mui/material'
import {LayoutStyles} from './layout'
import {SidebarStyles} from './sidebar'
import {AppbarStyles} from './appbar'
import {ShowStyles} from './show'

export const componentsOverrides = (theme: Object & Theme): Object => {
    return Object.assign(
        DataGridStyle,
        CssBaseline,
        LayoutStyles,
        ShowStyles,
        SidebarStyles(theme),
        BackDropStyle(theme),
        ButtonStyle(theme),
        CardStyle(theme),
        IconButtonStyle(theme),
        InputStyle(theme),
        ListsStyle(theme),
        PaperStyle(theme),
        TooltipStyle(theme),
        TypographyStyle(theme),
        AppbarStyles(theme)
    )
}
