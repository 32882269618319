import {Card, Stack, TextFieldProps} from '@mui/material'
import {FormInput} from './form-input'
import {useCallback, useState} from 'react'

export const ColorInput = (props: TextFieldProps): JSX.Element => {
    const [color, setColor] = useState(props.defaultValue)
    const onChangeHandler = useCallback(
        e => {
            setColor(e.target.value)
            props.onChange(e)
        },
        [props]
    )
    return (
        <Stack direction={'row'} gap={'12px'}>
            <FormInput {...props} value={color} onChange={onChangeHandler} />
            <div style={{position: 'relative', width: '65px', height: 'inherit'}}>
                <Card sx={{height: '100%', backgroundColor: color, width: '65px'}} />
            </div>
        </Stack>
    )
}
