import {ExportButton, ListActionsProps, TopToolbar} from 'react-admin'
import {ChangeAmegaEmployee} from '../components/change_employee/change_employee'
import {FilterResetButton} from '../../../components/common/filter-reset-button/filter-reset-button'

export const AmegaEmployeesActions = (props: ListActionsProps): JSX.Element => {
    const {className, ...rest} = props
    return (
        <TopToolbar {...rest}>
            <ChangeAmegaEmployee label={'Создать'} />
            <ExportButton variant="outlined" color="inherit" />
            <FilterResetButton />
        </TopToolbar>
    )
}
