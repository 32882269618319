import {useInput} from 'react-admin'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from '@mui/material'
import {ChangeEvent, useCallback, useMemo} from 'react'
import {RaRecord} from 'ra-core'
interface ISelectInputProps<T> {
    multiple?: boolean
    source: string
    choices?: T[]
    label: string
    fieldName?: string
    alwaysOn?: boolean
    onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void
    emptyText?: string
}
export const FramerSelect = <T extends {name: string; id: string}>(props: ISelectInputProps<T>): JSX.Element => {
    const {label, choices, source, multiple = false, fieldName, emptyText} = props
    const {field} = useInput(props)
    const onChange = useCallback(({target: {value}}) => field.onChange(value), [field])
    const multipleValue = useMemo(() => (!field.value || field.value === '' ? [] : field.value), [field.value])
    const value = useMemo(() => (multiple ? multipleValue : field.value ?? ''), [field.value, multiple, multipleValue])
    const renderValue = useCallback(
        (p: string | string[]): string =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `(${p.length}) ${multiple ? (p as string[]).map(v => choices.find(c => c.id === v)?.name) : choices.find(c => c.id === p)?.name}`,
        [choices, multiple]
    )
    const inputValue = useMemo(() => {
        if (value.length) return value
        if (emptyText) return multiple ? [emptyText] : emptyText
        return multiple ? [] : value
    }, [emptyText, multiple, value])
    return (
        <FormControl>
            <InputLabel size={'small'} id="demo-multiple-name-label">
                {label}
            </InputLabel>
            <Select
                size={'small'}
                renderValue={renderValue}
                onChange={onChange}
                multiple={multiple}
                input={<OutlinedInput label={label} />}
                value={inputValue}>
                {emptyText && (
                    <MenuItem key={null} value={null}>
                        {emptyText}
                    </MenuItem>
                )}
                {choices.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item[fieldName ?? 'name']}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
