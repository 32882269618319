import {FunctionField} from 'react-admin'
import {useCallback} from 'react'
import {Order} from '../../../../../models/order'
import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {showFieldTitle} from '../../../../../consts/styles'

interface IOrderStateProps {
    title: string
    color?: string
    sx?: SxProps<Theme>
}

export const OrderState = (props: IOrderStateProps): JSX.Element => {
    const {title, color, sx} = props
    const theme = useTheme()
    const render = useCallback((record: Order) => Order.getStatusTitle(record.state), [])

    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={{textAlign: 'end'}} render={render} />
        </Stack>
    )
}
