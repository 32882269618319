import {Button} from '@mui/material'
import React from 'react'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useBookInERP} from '../../../../../hooks/orders'
export const BookInERP = (): JSX.Element => {
    const showModal = useBookInERP()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Бронирование в ERP</>
        </Button>
    )
}
