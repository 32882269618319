import {Show, ShowControllerProps} from 'react-admin'
import {Box, Card, Grid, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {ChangeAmegaEmployee} from '../components/change_employee/change_employee'
import {DeleteAmegaEmployee} from './components/delete_employee/delete_employee'
export const AmegaEmployeeItem = (props: ShowControllerProps): JSX.Element => {
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Сотрудник'} source={'id'} />
                        <ShowField color={theme.palette.grey['200']} title={'Имя'} source={'name'} />
                        <ShowField source="city" title="Город" />
                        <ShowField source="catalog1C" title="Каталог 1С" />
                        <ShowField sx={{mb: 1}} source="referralCode" title="Реферальный код" />
                        <ShowField source="createdAt" type={'date'} title="Дата создания" />
                        <ShowField source="updatedAt" type={'date'} title="Дата последнего обновления" />
                        <ShowField source="deletedAt" type={'date'} title="Дата удаления" />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <ChangeAmegaEmployee isEdit label={'Изменить сотрудника'} />
                        <DeleteAmegaEmployee label={'Удалить сотрудника'} />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
