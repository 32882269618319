import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import {Good} from '../../../models/good'
import {OrderGoodsTableItem} from './order-goods-table-item'

interface IOrderGoodsTableProps {
    name: string
    goods: Good[]
}

export const OrderGoodsTable = (props: IOrderGoodsTableProps): JSX.Element => {
    const {name, goods} = props
    const headers = ['Доп. материал', 'Количество', 'Параметры', 'Цена с наценкой', 'Цена без наценки']

    return (
        <Card>
            <Typography variant={'h6'} sx={{ml: 2, mt: 2}}>
                {name}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(h => (
                                <TableCell key={h} style={{fontWeight: 'bold'}} align="center">
                                    {h}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {goods.map(good => (
                            <OrderGoodsTableItem key={good.id} good={good} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}
