import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {useFormik} from 'formik'
import {Autocomplete, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {Affiliate} from '../models/affiliate'
import {Partner} from '../models/partner'
import {City} from '../models/city'
import {cities} from '../consts/cities'
import {FormSelector} from '../components/common/form-fields/form-selector'
import {useRequest} from './network'

export const useFilialChangeVilaterm = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Affiliate>()
    const submitHandler = useCallback(
        (values: {value: boolean}) => dataProvider.filialChangeVilaterm(record?.id, values.value),
        [dataProvider, record?.id]
    )
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: !!record?.isVilatermShouldBeSet},
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: `Принудительная установка вилатерма в заказах для ${record?.name}:`,
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.value}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('value', e.target.value === 'true')}>
                        <FormControlLabel value={true} control={<Radio />} label="Включить" />
                        <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [mutate, record?.name, setFieldValue, show, values?.value]
    )
}
export const useFilialChangeImpost = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Affiliate>()
    const submitHandler = useCallback(
        (values: {value: boolean}) => dataProvider.filialChangeImpost(record?.id, values.value),
        [dataProvider, record?.id]
    )
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: !!record?.isMetalKreplenieImposta},
        onSubmit: submitHandler,
        enableReinitialize: true
    })

    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: `Принудительная установка импоста в заказах для ${record?.name}:`,
                body: (
                    <RadioGroup
                        id={'value'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.value}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('value', e.target.value === 'true')}>
                        <FormControlLabel value={true} control={<Radio />} label="Включить" />
                        <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [mutate, record?.name, setFieldValue, show, values?.value]
    )
}
export const useFilialChangeCityModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.filialChangeCity(record?.id, values), [dataProvider, record?.id])
    const {submitForm, setFieldValue} = useFormik<City>({
        initialValues: new City(),
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const changeHandler = useCallback(
        (_, value) => {
            setFieldValue('id', value.id)
            setFieldValue('name', value.name)
        },
        [setFieldValue]
    )

    return useCallback(
        () =>
            show({
                title: 'Сменить город филиала',
                body: (
                    <Autocomplete
                        style={{width: 300}}
                        options={cities}
                        autoHighlight
                        getOptionLabel={option => option.name}
                        onChange={changeHandler}
                        renderInput={params => <FormSelector {...params} label="Выберите город" size={'small'} />}
                    />
                ),
                onSuccess: mutate
            }),
        [changeHandler, mutate, show]
    )
}
