import {Button} from '@mui/material'
import {useAddRegionalManagerModal} from '../../../hooks/users'

interface IRegisterRegionalManagerProps {
    label?: string
}
export const RegisterRegionalManager = (props: IRegisterRegionalManagerProps): JSX.Element => {
    const {label} = props
    const openForm = useAddRegionalManagerModal()
    return (
        <Button variant="outlined" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
