import {Admin, CustomRoutes, Resource} from 'react-admin'
import {UserList, UserItem} from './entities/users'
import {FramerLayout} from './layout'
import PeopleIcon from '@mui/icons-material/People'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import PaymentIcon from '@mui/icons-material/Payment'
import StoreIcon from '@mui/icons-material/Store'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import VerifiedUserIcon from '@mui/icons-material/Verified'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import OutletIcon from '@mui/icons-material/Outlet'
import ChromeReaderMode from '@mui/icons-material/ChromeReaderMode'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import LineWeightIcon from '@mui/icons-material/LineWeight'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import {authProvider, dataProvider} from './providers'
import {defaultTheme} from 'react-admin'
import {componentsOverrides} from './theme/overrides'
import palette from './theme/palette'
import shadows from './theme/shadows'
import typography from './theme/typography'
import {useMemo} from 'react'
import {createTheme} from '@mui/material'
import {DialogContextProvider} from './components/common/dialog/dialog-context-provider'
import DialogView from './components/common/dialog/dialog'
import sidebar from './theme/sidebar'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from 'ra-language-russian'
import {PartnerList} from './entities/partners'
import {PartnerItem} from './entities/partners/item/item'
import {OrderItem, OrderList} from './entities/orders'
import {DealerList} from './entities/dealers/list/list'
import {AccountPaymentsList} from './entities/account_payments'
import {useChiefAdmin, useNotRMAdmin, useSuperAdmin} from './hooks/common'
import {DealerItem} from './entities/dealers/item/item'
import {FilialList, FilialItem} from './entities/filials'
import {AlertsList, AlertsItem} from './entities/alerts'
import {AuditEventsList} from './entities/audit_events'
import {AmegaEmployeesList} from './entities/amega_employees'
import {AmegaEmployeeItem} from './entities/amega_employees/item/item'
import {OrderActionsList} from './entities/order_actions'
import {AmpqHandlersList, AmpqHandlersItem} from './entities/ampq_handlers'
import {WindrawServicesList, WindrawServicesItem} from './entities/windraw_services'
import {UserParams} from './entities/user_params'
import {ClaimItem, ClaimsList} from './entities/claims'
import {QueryClient} from 'react-query'
import {TintFilmsList} from './entities/tintfilms/list/list'
import {ConstrGlassesList} from './entities/constrglasses/list/list'
import {FramespacersList} from './entities/framespacers/list/list'
import {TintFilmEdit} from './entities/tintfilms/edit/edit'
import {FrameSpacerEdit} from './entities/framespacers/edit/edit'
import {ConstrGlassesEdit} from './entities/constrglasses/edit/edit'
import {ConstrGlassesCreate} from './entities/constrglasses/create/create'
import {FrameSpacerCreate} from './entities/framespacers/create/create'
import {TintFilmCreate} from './entities/tintfilms/create/create'
import {YMaps} from '@pbe/react-yandex-maps'
import {YMAPS_API_KEY} from './consts/const'
import {Route} from 'react-router'
import {Profile} from './entities/profile/profile'
const App = (): JSX.Element => {
    const notRmAdmin = useNotRMAdmin()
    const superAdmin = useSuperAdmin()
    const chiefAdmin = useChiefAdmin()
    const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

    const theme = useMemo(() => {
        const temp = createTheme({...defaultTheme, palette: palette, shadows: shadows, typography: typography, shape: {borderRadius: 8}})
        return {
            ...temp,
            sidebar: sidebar,
            components: componentsOverrides(temp)
        }
    }, [])

    const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}})
    return (
        <YMaps query={{apikey: YMAPS_API_KEY}}>
            <DialogContextProvider>
                <DialogView theme={theme} />
                <Admin
                    queryClient={queryClient}
                    i18nProvider={i18nProvider}
                    theme={theme}
                    layout={FramerLayout}
                    dataProvider={dataProvider}
                    authProvider={authProvider}>
                    <Resource name="users" options={{label: 'Пользователи'}} icon={PeopleIcon} list={UserList} show={UserItem} />
                    <Resource name="partners" options={{label: 'Партнеры'}} icon={AccountBalanceIcon} list={PartnerList} show={PartnerItem} />
                    <Resource name="dealers" options={{label: 'Контрагенты'}} icon={BusinessCenterIcon} list={DealerList} show={DealerItem} />
                    <Resource
                        name="amegaemployees"
                        options={{label: 'Сотрудники Амега'}}
                        icon={ApartmentIcon}
                        list={notRmAdmin && AmegaEmployeesList}
                        show={AmegaEmployeeItem}
                    />
                    <Resource name="filials" options={{label: 'Филиалы'}} icon={StoreIcon} list={notRmAdmin && FilialList} show={FilialItem} />
                    <Resource name="orders" options={{label: 'Заказы'}} icon={BackupTableIcon} list={OrderList} show={OrderItem} />
                    <Resource
                        name="accountoperations"
                        options={{label: 'Платежи'}}
                        icon={PaymentIcon}
                        list={notRmAdmin && AccountPaymentsList}
                    />
                    <Resource
                        name="useractions"
                        options={{label: 'Действия в заказе'}}
                        icon={PendingActionsIcon}
                        list={notRmAdmin && OrderActionsList}
                    />
                    <Resource
                        name="alerts"
                        options={{label: 'Оповещения'}}
                        icon={AddAlertIcon}
                        list={notRmAdmin && AlertsList}
                        show={AlertsItem}
                    />
                    <Resource
                        name="adminauditevents"
                        options={{label: 'Аудит действий'}}
                        icon={VerifiedUserIcon}
                        list={(chiefAdmin || superAdmin) && AuditEventsList}
                    />
                    <Resource
                        name={'userparams'}
                        icon={ChromeReaderMode}
                        options={{label: 'Пользовательские параметры'}}
                        list={<UserParams />}
                    />
                    <Resource
                        name="amqphandlers"
                        options={{label: 'Amqp Handlers'}}
                        icon={DoneAllIcon}
                        list={superAdmin && AmpqHandlersList}
                        show={AmpqHandlersItem}
                    />
                    <Resource
                        name="windrawservices"
                        options={{label: 'Windraw services'}}
                        icon={PrecisionManufacturingIcon}
                        list={superAdmin && WindrawServicesList}
                        show={WindrawServicesItem}
                    />
                    <Resource
                        name="tintfilms"
                        options={{label: 'Тонировочные пленки'}}
                        icon={LineWeightIcon}
                        list={notRmAdmin && TintFilmsList}
                        edit={notRmAdmin && TintFilmEdit}
                        create={notRmAdmin && TintFilmCreate}
                    />
                    <Resource
                        name="constrglasses"
                        options={{label: 'Стекла'}}
                        icon={BurstModeIcon}
                        list={notRmAdmin && ConstrGlassesList}
                        edit={notRmAdmin && ConstrGlassesEdit}
                        create={notRmAdmin && ConstrGlassesCreate}
                    />
                    <Resource
                        name="framespacers"
                        options={{label: 'Дистанционные рамки'}}
                        icon={OpenInFullIcon}
                        list={notRmAdmin && FramespacersList}
                        edit={notRmAdmin && FrameSpacerEdit}
                        create={notRmAdmin && FrameSpacerCreate}
                    />
                    <Resource name="reclamations" options={{label: 'Рекламации'}} icon={OutletIcon} list={ClaimsList} show={ClaimItem} />
                    <Resource name="factories" />
                    <Resource name="constructions" />
                    <Resource name="warehouses" />
                    <Resource name="cities" />
                    <Resource name="payhistory" />
                    <CustomRoutes>
                        <Route path="/profile" element={<Profile />} />
                    </CustomRoutes>
                </Admin>
            </DialogContextProvider>
        </YMaps>
    )
}
export default App
