import {TableCell, TableRow} from '@mui/material'
import {Good} from '../../../models/good'

interface IOrderGoodsTableItemProps {
    good: Good
}

export const OrderGoodsTableItem = (props: IOrderGoodsTableItemProps): JSX.Element => {
    const {good} = props

    return (
        <TableRow key={good.id}>
            <TableCell>{good.name}</TableCell>
            <TableCell align="center">{good.quantity}</TableCell>
            <TableCell align="center">
                {good.attributes
                    .filter(i => i.name !== 'picture')
                    .map(a => (
                        <p key={a.name}>
                            {a.caption}: {a.enumValues?.find(v => v.value === a.value)?.name ?? a.value}
                        </p>
                    ))}
            </TableCell>
            <TableCell align="center">{good.cost / 100} ₽</TableCell>
            <TableCell align="center">
                {good.amegaCost !== good.amegaCostToDisplay
                    ? `${good.amegaCost / 100} ₽ / ${good.amegaCostToDisplay / 100} ₽`
                    : `${good.amegaCost / 100} ₽`}
            </TableCell>
        </TableRow>
    )
}
