import {useMemo} from 'react'
import {FramerBonusesIcon} from '../../../../../components/common/framer-bonuses-icon/framer-bonuses-icon'
import {Construction} from '../../../../../models/construction'
import {FunctionField} from 'react-admin'
import {useRecordContext} from 'ra-core'

interface IFormattedConstructionCostProps {
    record?: Construction
    source: string
    calculatedByFramerPoints?: boolean
    label: string
}

export const FormattedConstructionCost = (props: IFormattedConstructionCostProps): JSX.Element => {
    const {record, source, calculatedByFramerPoints, label} = props
    const localRecord = useRecordContext()
    const rec = record ?? localRecord
    const cost = useMemo(() => (rec[source] ? rec[source] / 100 : '-'), [source, rec])
    return (
        <FunctionField
            label={label}
            render={_ =>
                calculatedByFramerPoints ? (
                    <>
                        {cost}&nbsp;
                        {rec[source] ? <FramerBonusesIcon /> : ''}
                    </>
                ) : (
                    <>
                        {cost}&nbsp;{rec[source] ? '₽' : ''}
                    </>
                )
            }
        />
    )
}
