import {IDadataAddress} from '../services/kladr-manager'
import {Coordinates, ICoordinatesJSON} from './coordinates'
import {getClone} from '../helpers/json'

export interface IAddressJSON {
    region: string
    city: string
    street: string
    building: string
    zipcode: string
    kladrId: string
    coordinates?: ICoordinatesJSON
    dadataAddress?: IDadataAddress
}

export class Address {
    static parseFromDadataAddress(dadataAddress: IDadataAddress): Address {
        let city = ''
        if (dadataAddress.city_with_type) {
            city += dadataAddress.city_with_type
        }
        if (dadataAddress.settlement_with_type) {
            if (city.length > 0) {
                city += ', '
            }
            city += dadataAddress.settlement_with_type
        }

        const address = new Address(
            dadataAddress.region_with_type,
            city,
            dadataAddress.street_with_type ? dadataAddress.street_with_type : '',
            dadataAddress.house ? `${dadataAddress.house_type} ${dadataAddress.house}` : '',
            dadataAddress.postal_code,
            dadataAddress.kladr_id
        )

        if (dadataAddress.geo_lat && dadataAddress.geo_lon) {
            address.coordinates = new Coordinates(+dadataAddress.geo_lat, +dadataAddress.geo_lon)
        }

        address.dadataAddress = getClone(dadataAddress)

        return address
    }

    static parse(obj: IAddressJSON): Address {
        return new Address(
            obj.region,
            obj.city,
            obj.street,
            obj.building,
            obj.zipcode,
            obj.kladrId,
            obj.coordinates ? Coordinates.parse(obj.coordinates) : undefined
        )
    }

    static get empty(): Address {
        return new Address('')
    }

    region: string
    city: string
    street: string
    building: string
    zipcode: string
    kladrId: string
    coordinates?: Coordinates
    dadataAddress?: IDadataAddress

    constructor(
        region: string,
        city?: string,
        street?: string,
        building?: string,
        zipcode?: string,
        kladrId?: string,
        coordinates?: Coordinates
    ) {
        this.region = region ?? ''
        this.city = city ?? ''
        this.street = street ?? ''
        this.building = building ?? ''
        this.zipcode = zipcode ?? ''
        this.kladrId = kladrId ?? ''
        this.coordinates = coordinates
    }

    get clone(): Address {
        return Address.parse(getClone(this))
    }

    get isEmpty(): boolean {
        return (
            this.region.length === 0 &&
            this.city.length === 0 &&
            this.street.length === 0 &&
            this.building.length === 0 &&
            this.zipcode.length === 0 &&
            this.kladrId.length === 0
        )
    }

    toString(): string {
        let address = this.region

        if (this.city.length > 0) {
            address += `, ${this.city}`
        }
        if (this.street.length > 0) {
            address += `, ${this.street}`
        }
        if (this.building.length > 0) {
            address += `, ${this.building}`
        }

        return address
    }

    equals(address: Address): boolean {
        return (
            this.region === address.region &&
            this.city === address.city &&
            this.street === address.street &&
            this.building === address.building &&
            this.zipcode === address.zipcode &&
            this.kladrId === address.kladrId
        )
    }
}
