import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext} from 'react-admin'
import {AmegaEmployee} from '../models/amega-employee'
import {useRequest} from './network'

export const useAmpqRestart = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext<AmegaEmployee>()
    const submitHandler = useCallback(() => dataProvider.ampqRestart(record?.id), [dataProvider, record?.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: () => window.history.back()})

    return useCallback(
        () =>
            show({
                title: 'Перезапуск слушателя',
                body: <>Перезапустить слушатель?</>,
                onSuccess: mutate,
                successText: 'Перезапустить'
            }),
        [mutate, show]
    )
}
