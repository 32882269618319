import * as React from 'react'
import {Link} from 'react-router-dom'
import {Button, useResourceContext} from 'react-admin'

export const FilterResetButton = (): JSX.Element => {
    const resource = useResourceContext()
    return (
        <Button
            sx={{width: '100%', mt: 2}}
            color="inherit"
            variant={'outlined'}
            label={'Сбросить фильтр'}
            component={Link}
            to={{
                pathname: `/${resource}`,
                search: 'filter={}&displayedFilters={}'
            }}
        />
    )
}
