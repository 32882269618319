export class AccountUser {
    login: string = ''
    email: string = ''
    password: string = ''
    confirmPassword: string = ''

    static empty = {
        login: '',
        email: '',
        password: '',
        confirmPassword: ''
    }
}

export interface ISupportAccount {
    username: string
    password: string
}
