import {Box, Card, Grid, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {Show} from 'react-admin'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {FilialChangeVilaterm} from './components/filial-change-vilaterm/filial-change-vilaterm'
import {FilialChangeImpost} from './components/filial-change-impost/filial-change-impost'
import {FilialChangeCity} from './components/filial-change-city/filial-change-city'

export const FilialItem = (): JSX.Element => {
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Филиал'} source={'id'} />

                        <ShowField color={theme.palette.grey['200']} source="name" title="Наименование" />
                        <BooleanField source="isMain" label="Основной" />
                        <BooleanField source="isExternal" label="Внешний" />
                        <ShowField
                            sx={{mb: 1}}
                            title={'Партнер'}
                            referenceSource={'partnerId'}
                            source={'name'}
                            reference={'partners'}
                            link={'show'}
                        />
                        <ShowField sx={{mb: 1}} color={theme.palette.grey['200']} source="city.name" title="Город" />
                        <BooleanField source="isVilatermShouldBeSet" label="Принудительная установка вилатерма на все заказы" />
                        <BooleanField source="isMetalKreplenieImposta" label="Принудительная установка металлической пятки импоста" />
                        <ShowField type={'date'} source="createdAt" title="Дата создания" />
                        <ShowField type={'date'} source="updatedAt" title="Дата последнего обновления" />
                        <ShowField type={'date'} source="deletedAt" title="Дата Удаления" />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <FilialChangeVilaterm label={'Включить/отключить принудительную установку вилатерма'} />
                        <FilialChangeImpost label={'Включить/отключить принудительную установку импоста'} />
                        <FilialChangeCity label={'Сменить город'} />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
