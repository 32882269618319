import {createElement, FunctionComponent, ReactNode, useState} from 'react'
import {List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import {useSidebarState} from 'react-admin'

interface ISubMenuProps {
    dense: boolean
    icon: FunctionComponent
    title: string
    children: ReactNode
}

export const SubMenu = (props: ISubMenuProps): JSX.Element => {
    const {title, icon, children, dense} = props
    const [isOpen, setIsOpen] = useState(false)

    const [sidebarIsOpen] = useSidebarState()

    const header = (
        <MenuItem dense={dense} onClick={() => setIsOpen(prev => !prev)}>
            <ListItemIcon sx={{minWidth: 5, color: '#757d85'}}>{createElement(icon)}</ListItemIcon>
            <Typography variant="inherit" color="textSecondary" sx={{flex: 1}}>
                {title}
            </Typography>
            <ListItemIcon sx={{minWidth: 5}}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        </MenuItem>
    )

    return (
        <div>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={title} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        borderTop: '1px solid #e3e3e3',
                        borderBottom: '1px solid #e3e3e3',
                        '& .MuiMenuItem-root': {backgroundColor: '#f2f2f2'},
                        '& .MuiMenuItem-root:hover': {backgroundColor: 'rgb(0 0 0 / 8%)'}
                    }}>
                    {children}
                </List>
            </Collapse>
        </div>
    )
}
