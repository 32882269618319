import {Datagrid, DateField, List, ListProps, ShowButton} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {AmegaEmployeesFilter} from './filter'
import {AmegaEmployeesActions} from './actions'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'
export const AmegaEmployeesList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<AmegaEmployeesFilter />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<AmegaEmployeesActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="deletedAt" showTime label="Дата удаления" />
                <ClipboardTextField source="name" label="Имя" />
                <ClipboardTextField source="catalog1C" label="Каталог 1С" />
                <ClipboardTextField source="referralCode" label="Реферальный код" />
                <ClipboardTextField source="city" label="Город" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
