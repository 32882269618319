import {Datagrid, DateField, List, ReferenceField, ShowButton, ListProps, FunctionField} from 'react-admin'
import {useChiefAdmin, useNotRMAdmin, useSuperAdmin} from '../../../hooks/common'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {FormattedOrderCost} from '../item/components/formatted-order-cost/formatted-order-cost'
import {OrderListActions} from './actions'
import {OrderListFilters} from './filters'
import {useOrderTotalCost} from '../../../hooks/orders'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const OrderList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isNotRmAdmin = useNotRMAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const totalCost = useOrderTotalCost()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<OrderListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<OrderListActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="state" label="Статус" />
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="updatedAt" showTime label="Дата обновления" />
                <DateField source="deletedAt" showTime label="Дата удаления" />
                <DateField source="paidAt" showTime label="Дата оплаты" />
                <ClipboardTextField source="orderNumber" label="Номер Framer" />
                <ClipboardTextField source="windrawOrderNumber" label="Номер Windraw" />
                <ClipboardTextField source="windrawGoodsOrderNumber" label="Номер Windraw на допы" />
                <BooleanField inList source="hasGoods" label="Есть доп. материалы" />
                {isNotRmAdmin && (
                    <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                        <ClipboardTextField source="name" />
                    </ReferenceField>
                )}
                {!isNotRmAdmin && <ClipboardTextField source="partner.name" label="Партнер" />}
                <ClipboardTextField source="city.name" label="Город" />

                <FunctionField label="Цена клиента" render={record => <FormattedOrderCost record={record} source={'totalCost'} />} />
                <FunctionField label="Наценка" render={record => `к:${record.constrMarginPercent}% д:${record.goodsMarginPercent}%`} />
                <FunctionField
                    label="Цена субдилера"
                    render={record =>
                        record.partner?.isSubdealershipAvailable ? (
                            <FormattedOrderCost record={record} source={'totalAmegaCostToDisplay'} />
                        ) : (
                            '—'
                        )
                    }
                />
                <FunctionField label="Цена завода" render={record => <FormattedOrderCost record={record} source={'totalAmegaCost'} />} />
                <FunctionField label="Цена допов" render={record => <FormattedOrderCost record={record} source={'totalAmegaGoodsCost'} />} />
                <FunctionField
                    label="Полная стоимость"
                    render={record => <FormattedOrderCost sx={{fontWeight: 700}} source={''} record={record} render={totalCost} />}
                />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
