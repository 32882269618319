export class Tags {
    blockConstructionChange: boolean = false
    blockFactoryGoodsChange: boolean = false
    blockDeliveryAddressChange: boolean = false

    static titles(key: string): string {
        switch (key) {
            case 'blockConstructionChange':
                return 'Блокировка конструкций'
            case 'blockFactoryGoodsChange':
                return 'Блокировка заводских допов'
            case 'blockDeliveryAddressChange':
                return 'Блокировка изменения адреса'
        }
    }

    static fromArray(array?: string[]): Tags {
        const tags = new Tags()
        if (!array) return tags
        array.forEach(tag => (tags[tag] = true))
        return tags
    }
}
