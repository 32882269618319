/* eslint-disable @typescript-eslint/naming-convention */
import {Theme} from '@mui/material'

export const IconButtonStyle = (theme: Theme): Object => {
    return {
        MuiIconButton: {
            variants: [
                {
                    props: {color: 'default'},
                    style: {
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        }
                    }
                },
                {
                    props: {color: 'inherit'},
                    style: {
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        }
                    }
                }
            ],

            styleOverrides: {
                root: {}
            }
        }
    }
}
