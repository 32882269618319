import {Datagrid, List, ShowButton, ListProps, DateField, FunctionField, ReferenceField} from 'react-admin'
import {useChiefAdmin, useNotRMAdmin, useSuperAdmin} from '../../../hooks/common'
import {DealerListActions} from './actions'
import {DealerListFilters} from './filters'
import {Dealer} from '../../../models/dealer'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const DealerList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isNotRmAdmin = useNotRMAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()

    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<DealerListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<DealerListActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="deletedAt" showTime label="Дата Удаления" emptyText={'-'} />
                <ClipboardTextField source="externalId" label="Код 1С" />
                <ClipboardTextField source="name" label="Наименование" />
                <FunctionField label="Тип оплаты" render={Dealer.getPayTypeTitle} />
                <ClipboardTextField source="inn" label="Инн" emptyText={'-'} />
                {isNotRmAdmin && (
                    <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                        <ClipboardTextField source="name" />
                    </ReferenceField>
                )}
                {!isNotRmAdmin && <ClipboardTextField source="partner.name" label="Партнер" />}
                <ReferenceField emptyText={'-'} source="partner.mainDealerId" label="Головной контрагент" reference="dealers" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ClipboardTextField source="city" label="Город" emptyText={'-'} />
                <ClipboardTextField source="factoryId" label="Ид завода" />
                <FunctionField label="Статус подтверждения" render={Dealer.getConfirmationStatus} />
                <FunctionField label="Скидки" render={Dealer.getDiscounts} />
                {isNotRmAdmin && <ShowButton />}
            </Datagrid>
        </List>
    )
}
