import {Datagrid, List, ShowButton, ListProps, DateField, NumberField, ReferenceField} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {FilialListActions} from './actions'
import {FilialListFilters} from './filters'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const FilialList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<FilialListFilters />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<FilialListActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="deletedAt" showTime label="Дата Удаления" />
                <ClipboardTextField source="name" label="Наименование" />
                <BooleanField inList source="isExternal" label={'Внешний'} />
                <BooleanField inList source="isMain" label={'Основной'} />
                <NumberField source="margin.percent" label="Наценка (%)" />
                <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                    <ClipboardTextField source="id" />
                </ReferenceField>
                <ClipboardTextField source="city.name" label="Город" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
