export interface IMarginJSON {
    percent: number
}

export class Margin {
    static parse(marginJSON: IMarginJSON): Margin {
        const margin = new Margin()

        Object.assign(margin, marginJSON)
        return margin
    }

    percent = 0

    toString(): string {
        return `${this.percent}%`
    }
}
