import {ExportButton, ListActionsProps, TopToolbar} from 'react-admin'
import {RestartServices} from './components/restart-services/restart-services'
import {StartOrderCreateService} from './components/start-order-create-service/start-order-create-service'
import {DatabaseUpdateService} from './components/database-update-service/database-update-service'
import {FilterResetButton} from '../../../components/common/filter-reset-button/filter-reset-button'
export const WindrawServicesActions = (props: ListActionsProps): JSX.Element => {
    const {className, ...rest} = props
    return (
        <TopToolbar {...rest}>
            <ExportButton variant="outlined" color="inherit" />
            <RestartServices label={'Перезапуск сервисов расчета'} />
            <StartOrderCreateService label={'Запуск сервиса создания заказа'} />
            <DatabaseUpdateService label={'Запустить процесс обновления БД'} />
            <FilterResetButton />
        </TopToolbar>
    )
}
