import {BooleanInput, DateInput, Filter, ReferenceInput, SelectInput} from 'react-admin'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'
import {Dealer} from '../../../models/dealer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AccountPaymentsListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Партнер'} />
            </ReferenceInput>
            <ReferenceInput label="Контрагент" source="dealerId" reference="dealers" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Контрагент'} />
            </ReferenceInput>
            <SelectInput label="Тип счета" source="accountType" alwaysOn choices={Dealer.accountTypes} />
            <SelectInput label="Тип операции" source="operationType" emptyText="Все" alwaysOn choices={Dealer.operationTypes} />
            <SelectInput label="Тип платежа" source="paymentType" emptyText="Все" alwaysOn choices={Dealer.paymentTypes} />
            <SelectInput label="Состояние операции" source="operationState" emptyText="Все" alwaysOn choices={Dealer.operationStates} />
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
            <BooleanInput sx={{mt: 2}} label="Только бонусы Energy" source="isReserve" alwaysOn />
        </Filter>
    )
}
