import {CreateBase, ReferenceInput, SelectInput, SimpleForm, TextInput, Title} from 'react-admin'
import {Card, Stack} from '@mui/material'

export const TintFilmCreate = (): JSX.Element => (
    <CreateBase>
        <Card>
            <Title title="Создание тонировочной пленки" />
            <SimpleForm>
                <TextInput sx={{width: 510}} source="description" label="Описание" required />
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <TextInput source="sku" label="Артикул" required />
                    <ReferenceInput source="factoryId" reference="factories">
                        <SelectInput label="Завод" optionText={'name'} required />
                    </ReferenceInput>
                </Stack>
            </SimpleForm>
        </Card>
    </CreateBase>
)
