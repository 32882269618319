import {ImageMiniature} from './image-miniature'
import {ClaimFile} from '../../../models/claim-file'

export interface IImagesMiniaturesProps {
    images?: ClaimFile[]
}

export const ImagesMiniatures = (props: IImagesMiniaturesProps): JSX.Element => {
    const {images} = props
    return (
        <div style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '17px', marginBottom: '36px'}}>
            {!!images?.length && images.map((e, i) => <ImageMiniature key={i} image={e} />)}
        </div>
    )
}
