import {useCallback, useMemo} from 'react'
import {useDataProvider, useRecordContext, useRefresh, useResourceContext} from 'react-admin'

const useNotRMAdmin = (): boolean => {
    const rmAdmin = useRmAdmin()
    return useMemo(() => !rmAdmin, [rmAdmin])
}
const useSuperAdmin = (): boolean => {
    return useMemo(() => localStorage.getItem('permissions')?.includes('super_admin'), [])
}
const useRmAdmin = (): boolean => {
    return useMemo(() => localStorage.getItem('permissions')?.includes('rm_admin'), [])
}
const useChiefAdmin = (): boolean => {
    return useMemo(() => localStorage.getItem('permissions')?.includes('chief_admin'), [])
}
const useCommonAdmin = (): boolean => {
    return useMemo(() => localStorage.getItem('permissions')?.includes('common_admin'), [])
}
const useElohinaAdmin = (): boolean => {
    return useMemo(() => localStorage.getItem('permissions')?.includes('elohina_admin'), [])
}

const useToken = (): string => {
    return useMemo(() => localStorage.getItem('access_token'), [])
}
const useAvailableRestoreRecord = (): (() => void) => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const resource = useResourceContext()

    return useCallback(() => {
        dataProvider.itemAvailableRestore(resource, record?.id).then(() => refresh())
    }, [dataProvider, record?.id, refresh, resource])
}
const useAvailableDeleteRecord = (): (() => void) => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const resource = useResourceContext()

    return useCallback(() => {
        dataProvider.itemAvailableDelete(resource, record?.id).then(() => refresh())
    }, [dataProvider, record?.id, refresh, resource])
}
export {
    useNotRMAdmin,
    useToken,
    useSuperAdmin,
    useChiefAdmin,
    useElohinaAdmin,
    useCommonAdmin,
    useRmAdmin,
    useAvailableRestoreRecord,
    useAvailableDeleteRecord
}
