import {Button} from '@mui/material'
import {useRmAdmin} from '../../../../../hooks/common'
import {useOrderSetShippingWarehouse} from '../../../../../hooks/orders'
export const OrderSetShippingWarehouse = (): JSX.Element => {
    const showModal = useOrderSetShippingWarehouse()
    const isRMAdmin = useRmAdmin()
    if (isRMAdmin) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Установить принудительный склад доставки</>
        </Button>
    )
}
