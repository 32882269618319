import * as React from 'react'
import {Link} from 'react-router-dom'
import {Button, useResourceContext} from 'react-admin'
import {Add} from '@mui/icons-material'

export const CreateButton = (): JSX.Element => {
    const resource = useResourceContext()
    return (
        <Button
            sx={{width: '100%', mt: 2}}
            label={'Создать'}
            color="primary"
            variant={'outlined'}
            component={Link}
            to={{
                pathname: `/${resource}/create`
            }}>
            <Add />
        </Button>
    )
}
