import {Button} from '@mui/material'
import {useOfferDownloadModal} from '../../../../../hooks/orders'
export const DownloadOffer = (): JSX.Element => {
    const showModal = useOfferDownloadModal()

    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Скачать КП</>
        </Button>
    )
}
