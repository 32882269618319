import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {FormattedOrderCost} from '../formatted-order-cost/formatted-order-cost'
import {useRecordContext} from 'react-admin'
import {Order} from '../../../../../models/order'
import {showFieldTitle, showFieldValue} from '../../../../../consts/styles'

interface IShowCostFieldProps {
    title: string
    color?: string
    sx?: SxProps<Theme>
    source?: string
    withFramerPoints?: boolean
    withCost?: boolean
}

export const ShowCostField = (props: IShowCostFieldProps): JSX.Element => {
    const {title, color, sx, source, withFramerPoints, withCost} = props
    const theme = useTheme()
    const record = useRecordContext<Order>()
    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FormattedOrderCost sx={showFieldValue} withCost={withCost} source={source} record={record} withFramerPoints={withFramerPoints} />
        </Stack>
    )
}
