import {FactoryTypes} from './factory-types'

export enum PromoCodeActions {
    REGISTRATION = 'register'
}

export interface IPromoCodeJSON {
    factoryId?: FactoryTypes
    city: number
    code: string
    from?: string
    to?: string
    action: PromoCodeActions
    description?: string
    bonusesValue?: number
    valid?: boolean
    status?: string
}
export class PromoCode {
    factoryId?: FactoryTypes
    city: number = -1
    code: string = ''
    from?: string
    to?: string
    action?: PromoCodeActions
    description?: string
    bonusesValue?: number
    valid?: boolean
    status?: string

    get descriptionFormat(): string {
        return this.description.replace(/\\n/g, '\n')
    }

    get statusString(): string {
        switch (this.status) {
            case 'used':
                return 'Применен'
            case 'unused':
                return 'Неприменен'
        }
    }

    static parse(json: IPromoCodeJSON): PromoCode {
        const promoCode = new PromoCode()
        Object.assign(promoCode, json)
        return promoCode
    }
    static registration(city: number): PromoCode {
        const promo = new PromoCode()
        promo.city = city
        promo.action = PromoCodeActions.REGISTRATION
        return promo
    }
}
