/* eslint-disable @typescript-eslint/naming-convention */

export const DataGridStyle = {
    RaDatagrid: {
        styleOverrides: {
            root: {
                display: 'flex',
                width: '100%',
                '& .RaDatagrid-tableWrapper': {
                    width: 'inherit'
                },
                '& .RaDatagrid-tbody': {
                    overflowY: 'scroll'
                }
            }
        }
    },
    RaList: {
        styleOverrides: {
            root: {
                '& .RaList-main': {
                    overflow: 'hidden'
                },
                '& .RaList-content': {
                    overflow: 'hidden',
                    display: 'flex'
                }
            }
        }
    },
    RaTopToolbar: {
        styleOverrides: {
            root: {
                boxSizing: 'border-box'
            }
        }
    },
    RaListToolbar: {
        styleOverrides: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'unset !important'
            }
        }
    },
    RaToolbar: {
        styleOverrides: {
            root: {
                '& .RaToolbar-defaultToolbar': {
                    justifyContent: 'flex-start'
                }
            }
        }
    },
    MuiTable: {
        styleOverrides: {
            root: {
                display: 'block',
                height: '100%',
                overflowX: 'scroll'
            }
        }
    },
    MuiTablePagination: {
        styleOverrides: {
            root: {
                overflow: 'unset'
            }
        }
    },
    RaFilterForm: {
        styleOverrides: {
            root: {
                width: '100%',
                paddingTop: '10px'
            }
        }
    }
}
