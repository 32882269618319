import {Chip, styled} from '@mui/material'

export const StyledChip = styled(Chip)<{customColor?: string; titleColor?: string}>(({customColor, titleColor}) => ({
    height: 'unset !important',
    margin: 'unset !important',
    marginRight: '8px !important',
    backgroundColor: customColor ?? '#BEC2C7',
    '& > .MuiChip-label': {
        fontSize: 12,
        fontWeight: 500,
        color: titleColor
    },
    '& > .MuiSvgIcon-root': {
        fontSize: 16,
        margin: '3px 8px 3px 6px',
        fill: 'white'
    }
}))
