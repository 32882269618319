import {Button} from '@mui/material'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {FieldProps} from 'react-admin'
import {useFilialChangeCityModal} from '../../../../../hooks/filials'

export const FilialChangeCity = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useFilialChangeCityModal()
    const chiefAdmin = useChiefAdmin()
    const superAdmin = useSuperAdmin()

    if (!chiefAdmin && !superAdmin) return <></>
    return (
        <Button sx={{mb: 1}} fullWidth variant="contained" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
