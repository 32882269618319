import {useCallback, useContext} from 'react'
import {DialogContext} from './dialog-context'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, ThemeOptions, ThemeProvider} from '@mui/material'
import {Formik} from 'formik'

interface IDialogProps {
    theme?: ThemeOptions
}
const DialogView = (props: IDialogProps): JSX.Element => {
    const {theme} = props
    const {data, onClose} = useContext(DialogContext)
    const closeHandler = useCallback(() => {
        data?.onSuccess()
        onClose()
    }, [data, onClose])
    const expanded = data?.expanded ? {height: 'calc(100vh - 128px)', width: 'calc(100vw - 128px)'} : {}
    const expandedPaper = data?.expanded ? {maxWidth: 'calc(100% - 64px)', maxHeight: 'calc(100% - 64px)'} : {}
    return (
        <ThemeProvider theme={theme}>
            <Formik enableReinitialize initialValues={data?.initialValues} onSubmit={data?.onSuccess} validationSchema={data?.validationSchema}>
                {formik => {
                    if (data?.withFormik && (!formik || !formik.values)) return <></>
                    return (
                        <Dialog
                            PaperProps={{
                                style: {borderRadius: 24, padding: 24, ...expandedPaper}
                            }}
                            aria-labelledby="simple-dialog-title"
                            open={!!data}>
                            <DialogTitle sx={{p: 0, mb: 3}} id="simple-dialog-title">
                                {data?.title}
                            </DialogTitle>
                            <DialogContent sx={{p: 0, mb: 3, boxSizing: 'border-box', ...expanded}}>
                                {typeof data?.body === 'function' ? data?.body(formik) : data?.body}
                            </DialogContent>
                            <DialogActions sx={{p: 0}}>
                                <Stack sx={{flex: 1}}>
                                    <Button
                                        style={{borderRadius: 8, height: 40}}
                                        sx={{mb: '12px'}}
                                        variant={'contained'}
                                        onClick={data?.withFormik ? formik?.submitForm : closeHandler}
                                        fullWidth
                                        color="primary">
                                        {data?.successText ?? 'Сохранить'}
                                    </Button>
                                    <Button
                                        style={{borderRadius: 8, height: 40}}
                                        variant={'outlined'}
                                        onClick={onClose}
                                        fullWidth
                                        color={'inherit'}>
                                        {data?.cancelText ?? 'Отмена'}
                                    </Button>
                                </Stack>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Formik>
        </ThemeProvider>
    )
}

export default DialogView
