import {AuditEventType} from '../models/audit-event'
import {useEffect, useState} from 'react'
import {useDataProvider} from 'react-admin'
import {useRequest} from './network'

export const useEventTypes = (): AuditEventType[] => {
    const dataProvider = useDataProvider()
    const [types, setTypes] = useState([])
    const {mutate} = useRequest<{json: AuditEventType[]}>(dataProvider.auditEventGetTypes, {onSuccess: data => setTypes(data.json)})
    useEffect(() => {
        mutate()
    }, [mutate])
    return types
}
