import {AutocompleteInput, DateInput, Filter, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {booleanItems} from '../../../consts/others'
import {Alert} from '../../../models/alert'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AlertsListFilter = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput
                label="Активно"
                source="active"
                emptyText="Все"
                alwaysOn
                choices={[
                    {id: true, name: 'Активные'},
                    {id: false, name: 'Неактивные'}
                ]}
            />
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
            <ReferenceInput label="Завод" source="factoryId" reference="factories" perPage={1000} alwaysOn>
                <AutocompleteInput label={'Завод'} size={'small'} optionText="name" emptyText="Все" />
            </ReferenceInput>
            <ReferenceInput label="Город" source="cityId" reference="cities" perPage={1000} alwaysOn>
                <AutocompleteInput label="Город" size={'small'} optionText="name" emptyText="Все" />
            </ReferenceInput>
            <SelectInput label="Тип ссылки" source="linkType" emptyText="Все" choices={Alert.alertLinkTypes} alwaysOn />
            <SelectInput label="Показывается в веб" source="showOnWeb" emptyText="Все" choices={booleanItems} alwaysOn />
            <SelectInput label="Показывается в моб. приложении" source="showOnMobile" emptyText="Все" choices={booleanItems} alwaysOn />
        </Filter>
    )
}
