import {FieldProps, useListContext} from 'react-admin'
import styles from './selector-chips.module.css'
import {StyledChip} from '../chip/styled-chip'
import {useCallback} from 'react'
export const SelectorChips = <T extends {id?: string; name?: string; color?: string}>(props: FieldProps & {items: T[]}): JSX.Element => {
    const {filterValues, setFilters} = useListContext(props)
    const {source, label, items} = props
    const values = filterValues[source]
    const onDelete = useCallback(
        e => {
            const filteredValues = values?.filter(item => item !== e)
            const changedFilter = {...values, [source]: filteredValues}
            setFilters(changedFilter, {})
        },
        [source, setFilters, values]
    )
    if (!values || !values.length) return <></>
    return (
        <div style={{display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap'}}>
            <p className={styles.title}>{label}: </p>
            {values?.map(e => {
                const item = items.find(item => item.id === e)
                const name = item?.name
                const color = item?.color
                return <StyledChip onDelete={() => onDelete(e)} titleColor={'white'} customColor={color} sx={{m: 0.5}} key={e} label={name} />
            })}
        </div>
    )
}
