import {DateInput, Filter, TextInput} from 'react-admin'
import {Claim} from '../../../models/claim'
import {FramerSelect} from '../../../components/common/form-fields/select'
import {SelectorChips} from '../../../components/common/selector-chips/selector-chips'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ClaimsFilter = (props): JSX.Element => {
    return (
        <>
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn />
                <FramerSelect alwaysOn multiple label="Статус" source="state" choices={Claim.statusesList} />
                <DateInput label="Дата оформления от" source="dateFrom" alwaysOn />
                <DateInput label="Дата оформления до" source="dateTo" alwaysOn />
            </Filter>
            <SelectorChips items={Claim.statusesList} label={'Статус'} source={'state'} />
        </>
    )
}
