import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {MainDiscounts} from './main-discounts'
import {ProfileDiscounts} from './profile-discounts'
import {useAdditionalDiscounts} from '../../../../hooks/partners'
import {ProfileAdd} from './profile-add'
export const AdditionalDiscounts = (): JSX.Element => {
    const {changeProfileDiscounts, changeBaseDiscounts, addProfileDiscounts} = useAdditionalDiscounts()

    return (
        <>
            <TableContainer component={Card}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Профиль</TableCell>
                            <TableCell>Стандарт</TableCell>
                            <TableCell>Нестандарт</TableCell>
                            <TableCell>Ламинация</TableCell>
                            <TableCell>Теплый алюминий</TableCell>
                            <TableCell>Холодный алюминий</TableCell>
                            <TableCell>Фантики</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <MainDiscounts onChange={changeBaseDiscounts} />
                        <ProfileDiscounts onChange={changeProfileDiscounts} />
                    </TableBody>
                </Table>
            </TableContainer>
            <ProfileAdd onSubmit={addProfileDiscounts} />
        </>
    )
}
