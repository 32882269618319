import {Button, FunctionField} from 'react-admin'
import {useCallback} from 'react'
import {IPromoCodeJSON} from '../../../../models/promo-code'
import {usePartnerConfirmModal} from '../../../../hooks/partners'

export const PartnerPromoConfirm = (): JSX.Element => {
    const show = usePartnerConfirmModal()
    const render = useCallback(
        (record: IPromoCodeJSON) =>
            record.status === 'unused' ? (
                <Button variant="outlined" color="primary" onClick={show}>
                    <>Применить</>
                </Button>
            ) : (
                <></>
            ),
        [show]
    )

    return <FunctionField source={'status'} label={''} render={render} />
}
