import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {FunctionField} from 'react-admin'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {showFieldTitle} from '../../../../../consts/styles'
import {DealerConfirmationStatus} from '../../../../../models/dealer'

interface IDealerConfirmedProps {
    title: string
    color?: string
    sx?: SxProps<Theme>
}

export const DealerConfirmed = (props: IDealerConfirmedProps): JSX.Element => {
    const {title, sx, color} = props
    const theme = useTheme()
    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField
                render={record =>
                    record.confirmationStatus === DealerConfirmationStatus.DealerConfirmed ? (
                        <CheckCircleIcon sx={{width: '16px'}} color={'success'} />
                    ) : (
                        <CancelIcon sx={{width: '16px'}} color={'error'} />
                    )
                }
            />
        </Stack>
    )
}
