import {Layout} from 'react-admin'
import {LayoutProps} from 'react-admin'
import {FramerAppBar} from './app-bar'
import {ProgressBar} from './components/common/progress-bar/progress-bar'
import {MyMenu} from './menu'

export const FramerLayout = (props: LayoutProps): JSX.Element => {
    return (
        <>
            <Layout
                sx={{
                    height: '100vh',
                    '.RaMenu-closed': {
                        width: '65px'
                    }
                }}
                {...props}
                appBar={FramerAppBar}
                sidebar={MyMenu}
            />
            <ProgressBar />
        </>
    )
}
