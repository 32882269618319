import {useNotRMAdmin} from '../../../../../hooks/common'
import {useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
import {useSetGost} from '../../../../../hooks/orders'
export const OrderSetGost = (): JSX.Element => {
    const showModal = useSetGost()
    const isNotRMAdmin = useNotRMAdmin()
    const record = useRecordContext()
    const enabledStates = ['draft', 'needs_recalc', 'calc_errors']
    if (!isNotRMAdmin) return <></>
    return (
        <Button disabled={!enabledStates.includes(record.state)} variant="contained" color="inherit" onClick={showModal}>
            <>{record?.hasGostSign ? 'Удалить ПП ГОСТ' : 'Добавить ПП ГОСТ'}</>
        </Button>
    )
}
