import {IHttpClient, request, RequestMethods} from '../api/request'
import {ILogin, ITokenJSON, IUserPermissionsJSON} from '../models/login'
import decodeJwt from 'jwt-decode'
import {IUserJSON} from '../models/user'
import {API_LOGIN, API_PROFILE} from '../consts/api'

export const authProvider = {
    login: async (params: ILogin): Promise<void> => {
        const response = await request.apiFetch(API_LOGIN, {method: RequestMethods.POST, body: JSON.stringify(params)})
        const json = (await response.json) as ITokenJSON
        const decodedToken = decodeJwt<IUserPermissionsJSON>(json.access_token)
        localStorage.setItem('access_token', json.access_token)
        const roles = []
        if (decodedToken.per.SuperAdmin) {
            roles.push('super_admin')
        }
        if (decodedToken.per.ChiefAdmin) {
            roles.push('chief_admin')
        }
        if (decodedToken.per.CommonAdmin) {
            roles.push('common_admin')
        }
        if (decodedToken.per.RMAdmin) {
            roles.push('rm_admin')
        }
        if (decodedToken.sub === '3744' || decodedToken.sub === '891') {
            roles.push('elohina_admin')
        }
        localStorage.setItem('permissions', JSON.stringify(roles))
    },
    logout: (): Promise<void> => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('permissions')
        return Promise.resolve()
    },
    checkError: ({status}: IHttpClient): Promise<void> => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token')
            return Promise.reject()
        }
        return Promise.resolve()
    },
    checkAuth: async (): Promise<void> => {
        await request.apiFetch(API_PROFILE)
    },
    getPermissions: (): Promise<string> => {
        const roles = localStorage.getItem('permissions')
        return roles ? Promise.resolve(roles) : Promise.reject()
    },
    getProfile: async (): Promise<IUserJSON> => {
        const response = await request.apiFetch(API_PROFILE)
        return response.json as IUserJSON
    }
}
