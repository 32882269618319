import {Button, Confirm} from 'react-admin'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {useAvailableDeleteRecord} from '../../../hooks/common'
import {useState} from 'react'

export const AvailableDeleteButton = (): JSX.Element => {
    const availableDelete = useAvailableDeleteRecord()
    const [open, setOpen] = useState(false)
    const handleDialogClose = (): void => setOpen(false)
    const handleDialogOpen = (): void => setOpen(true)

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <Button label={'Удалить'} color={'error'} onClick={handleDialogOpen}>
                <DeleteOutlineIcon />
            </Button>
            <Confirm
                isOpen={open}
                title={'Удаление объекта'}
                content={'Вы действительно хотите удалить объект?'}
                onConfirm={availableDelete}
                onClose={handleDialogClose}
            />
        </>
    )
}
