import {Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, NumberInput} from 'react-admin'
import {Card, Stack} from '@mui/material'
import {GlassCustomToolbar} from '../../../components/common/glass-custom-toolbar/glass-custom-toolbar'
import React from 'react'

export const FrameSpacerEdit = (): JSX.Element => (
    <Edit title={'Редактирование дистанционной рамки'}>
        <Card>
            <SimpleForm toolbar={<GlassCustomToolbar />}>
                <TextInput sx={{width: 510}} source="name" label="Название" required />
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <TextInput source="sku" label="Артикул" required />
                    <ReferenceInput source="factoryId" reference="factories">
                        <SelectInput label="Завод" optionText={'name'} required />
                    </ReferenceInput>
                </Stack>
                <TextInput sx={{width: 510}} source="thickness" label="Толщина" required />
                <NumberInput source="numpos" label="Позиция" required />
            </SimpleForm>
        </Card>
    </Edit>
)
