import {Button, Confirm} from 'react-admin'
import ReplayIcon from '@mui/icons-material/Replay'
import {useAvailableRestoreRecord} from '../../../hooks/common'
import {useState} from 'react'

export const AvailableRestoreButton = (): JSX.Element => {
    const restore = useAvailableRestoreRecord()
    const [open, setOpen] = useState(false)
    const handleDialogClose = (): void => setOpen(false)
    const handleDialogOpen = (): void => setOpen(true)
    return (
        <>
            <Button label={'Восстановить'} color="secondary" onClick={handleDialogOpen}>
                <ReplayIcon />
            </Button>
            <Confirm
                isOpen={open}
                title={'Восстановление объекта'}
                content={'Вы действительно хотите восстановить объект?'}
                onConfirm={restore}
                onClose={handleDialogClose}
            />
        </>
    )
}
