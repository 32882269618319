import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useStartWindrawOrderCreateServiceModal} from '../../../../../hooks/windraw_services'

export const StartOrderCreateService = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useStartWindrawOrderCreateServiceModal()
    return (
        <Button variant={'outlined'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
