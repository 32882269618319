import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useUpdatePasswordModal} from '../../../hooks/users'

interface IUserUpdatePasswordProps extends FieldProps {
    label: string
}
export const UserUpdatePassword = (props: IUserUpdatePasswordProps): JSX.Element => {
    const {label} = props
    const openForm = useUpdatePasswordModal()

    return (
        <Button variant="contained" color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
