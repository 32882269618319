/* eslint-disable @typescript-eslint/naming-convention */
import {Theme} from '@mui/material'

export const InputStyle = (theme: Theme): Object => {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        '& svg': {color: theme.palette.text.disabled}
                    }
                },
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: theme.palette.text.disabled
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: theme.palette.grey[500_56]
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.grey[500_12],
                    '&:hover': {
                        backgroundColor: theme.palette.grey[500_16]
                    },
                    '&.Mui-focused': {
                        backgroundColor: theme.palette.action.focus
                    },
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.action.disabledBackground
                    }
                },
                underline: {
                    '&:before': {
                        borderBottomColor: theme.palette.grey[500_56]
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.grey[500_32],
                        borderWidth: '1px !important',
                        borderRadius: 8
                    },
                    '&.Mui-disabled': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.action.disabledBackground
                        }
                    }
                }
            }
        },
        RaSelectInput: {
            styleOverrides: {
                root: {
                    marginBottom: 'unset !important',
                    width: '250px'
                }
            }
        },
        RaFilterFormInput: {
            styleOverrides: {
                root: {
                    '& > .MuiFormControl-root': {
                        width: '250px'
                    }
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    width: '250px',
                    padding: '8.5px 14px'
                }
            }
        }
    }
}
