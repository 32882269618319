import {Button} from '@mui/material'
import {useChiefAdmin, useRmAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useCompleteOrder} from '../../../../../hooks/orders'
import {useMemo} from 'react'
import {useRecordContext} from 'react-admin'
import {OrderStates} from '../../../../../models/order-states'
export const CompleteOrder = (): JSX.Element => {
    const record = useRecordContext()
    const showModal = useCompleteOrder()
    const isRmAdmin = useRmAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const isHide = useMemo(() => record.state === OrderStates.Draft || record.state === OrderStates.NeedsRecalc, [record.state])
    if (!isRmAdmin && !isChiefAdmin && !isSuperAdmin) return <></>
    return (
        <Button disabled={isHide} variant="contained" color="inherit" onClick={showModal}>
            <>Завершить заказ</>
        </Button>
    )
}
