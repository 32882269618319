import {Button} from '@mui/material'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useRecalcOrder} from '../../../../../hooks/orders'
export const RecalcOrder = (): JSX.Element => {
    const showModal = useRecalcOrder()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Пересчитать заказ</>
        </Button>
    )
}
