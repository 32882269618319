export interface IContactInfoJSON {
    name: string
    phone: string
    legalAddress: string
    physicalAddress: string
}

export class ContactInfo {
    static parse(contactInfoJSON: IContactInfoJSON): ContactInfo {
        const contactInfo = new ContactInfo()
        Object.assign(contactInfo, contactInfoJSON)
        return contactInfo
    }

    name!: string
    phone!: string
    email!: string
    legalAddress!: string
    physicalAddress!: string

    static get Builder(): Builder {
        return new Builder()
    }
}

class Builder {
    private _contact = new ContactInfo()

    withName(name: string): Builder {
        this._contact.name = name
        return this
    }

    withPhone(phone: string): Builder {
        this._contact.phone = phone
        return this
    }

    build(): ContactInfo {
        return this._contact
    }
}
