import {Button} from '@mui/material'
import {usePartnerDeleteClientModal} from '../../../../hooks/partners'
import {useChiefAdmin, useSuperAdmin} from '../../../../hooks/common'
export const DeleteClient = (): JSX.Element => {
    const showModal = usePartnerDeleteClientModal()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Удалить клиента</>
        </Button>
    )
}
