import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useDeleteAmegaEmployee} from '../../../../../hooks/amega_employees'
export const DeleteAmegaEmployee = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useDeleteAmegaEmployee()

    return (
        <Button sx={{mt: 1}} fullWidth variant={'contained'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
