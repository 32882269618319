import {Datagrid, List, ListProps, ShowButton} from 'react-admin'
import {useSuperAdmin} from '../../../hooks/common'
import {AmpqHandlersListActions} from './actions'
import {AmpqHandlersListFilter} from './filter'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'
export const AmpqHandlersList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isSuperAdmin = useSuperAdmin()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<AmpqHandlersListFilter />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={isSuperAdmin && exporter}
            bulkActionButtons={false}
            title="Amqp Handlers"
            actions={<AmpqHandlersListActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="name" label="Имя" />
                <ClipboardTextField source="factoryID" label="Завод" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
