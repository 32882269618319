import {Datagrid, DateField, FunctionField, List, ListProps, ReferenceField, ShowButton} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {Claim} from '../../../models/claim'
import {ClaimsFilter} from './filter'
import {ClaimsActions} from './actions'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const ClaimsList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<ClaimsFilter />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<ClaimsActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source={'reclamationNumber'} label={'Номер рекламации'} emptyText={'-'} />
                <ReferenceField source="orderID" label="Номер заказа" reference="orders" link="show">
                    <ClipboardTextField source="orderNumber" emptyText={'-'} />
                </ReferenceField>
                <FunctionField source="state" label="Статус" render={Claim.getClaimStateName} emptyText={'-'} />
                <ReferenceField source="dealerId" label="Наименование контрагента" reference="dealers" link="show">
                    <ClipboardTextField source="name" emptyText={'-'} />
                </ReferenceField>
                <ClipboardTextField source={'contactEmail'} label={'Эл. адрес'} emptyText={'-'} />
                <ClipboardTextField fullWidth source={'contactPhone'} label={'Телефон'} emptyText={'-'} />
                <ReferenceField source="owner.id" label="Пользователь" reference="users" link="show">
                    <ClipboardTextField source="username" emptyText={'-'} />
                </ReferenceField>
                <DateField showTime source={'createdAt'} label={'Дата оформления'} />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
