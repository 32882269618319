import {FieldProps} from 'react-admin'

export const OrderActionsPlace = (props: FieldProps): JSX.Element => {
    const {record} = props
    if (!record?.place) return <>-</>
    const list = record.place.map(node => {
        return (
            <>
                <span style={{whiteSpace: 'nowrap'}}>{node.info}</span>
                <br />
            </>
        )
    })
    return <>{list}</>
}
