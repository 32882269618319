import {TextField, TextFieldProps, Theme, styled} from '@mui/material'
import {forwardRef} from 'react'
const InputComponent = forwardRef<HTMLDivElement, TextFieldProps>((props, ref?) => (
    <TextField inputRef={ref} variant={'filled'} sx={{borderRadius: 20}} {...props} />
))
InputComponent.displayName = 'FormInput'
export const FormInput = styled(InputComponent)(({theme}: {theme: Theme}) => ({
    '& .MuiFilledInput-root': {
        borderRadius: 12,
        backgroundColor: theme.palette.grey[200]
    },
    '& label': {
        color: theme.palette.grey[600]
    },
    '& .Mui-focused': {
        color: theme.palette.grey[600]
    },
    '& .Mui-error': {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.main
    },
    '& p.Mui-error': {
        backgroundColor: 'unset'
    },
    '& .Mui-error:after': {
        borderColor: theme.palette.error.main
    },
    '& :after': {
        top: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 12
    },
    '& :before': {
        display: 'none'
    }
}))
