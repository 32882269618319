export enum OrderStates {
    Draft = 'draft',
    CalcErrors = 'calc_errors',
    NeedsRecalc = 'needs_recalc',
    AwaitingPayment = 'awaiting_payment',
    Paid = 'paid',
    Booking = 'booking',
    Booked = 'booked',
    Producing = 'producing',
    ProductionError = 'production_error',
    ReadyForPickup = 'ready_for_pickup',
    ProducedShippingToClient = 'produced__shipping_to_client',
    ShippingToClientAddress = 'shipping_to_client_address',
    ProducedShippingToWarehouse = 'produced__shipping_to_warehouse',
    ShippingToWarehouse = 'shipping_to_warehouse',
    AtWarehouseAwaitingShipping = 'at_warehouse_awaiting_shipping',
    AtWarehouseAwaitingPickup = 'at_warehouse_awaiting_pickup',
    Completed = 'completed',
    Cancelled = 'cancelled',
    CancelRequested = 'cancel_requested',
    CalcRequest = 'calc_request',
    DateCorrection = 'date_correction'
}
