import {Datagrid, DateField, List, ListProps, ShowButton} from 'react-admin'
import {useSuperAdmin} from '../../../hooks/common'
import {WindrawServicesActions} from './actions'
import {WindrawServicesFilter} from './filter'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'
export const WindrawServicesList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isSuperAdmin = useSuperAdmin()
    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<WindrawServicesFilter />}
            exporter={isSuperAdmin && exporter}
            bulkActionButtons={false}
            title="Windraw Service"
            actions={<WindrawServicesActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="CorrelationID" />
                <ClipboardTextField source="serviceType" label="Тип сервиса" />
                <DateField source="createdAt" showTime label="Дата старта" />
                <DateField source="lastHeartbeatAt" showTime label="Дата последнего пинга" />
                <ClipboardTextField source="groupId" label="Имя группы" />
                <ClipboardTextField source="factoryId" label="Завод" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
