import {AppBar, Menu, UserMenu} from 'react-admin'
import {ListItemIcon, ListItemText, Typography} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitIcon from '@mui/icons-material/ExitToApp'

import {makeStyles} from '@mui/styles'
import {useLogout} from 'ra-core'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    }
})

export const FramerAppBar = (): JSX.Element => {
    const classes = useStyles()
    return (
        <AppBar position="sticky" userMenu={<MyUserMenu />}>
            <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
            <span className={classes.spacer} />
        </AppBar>
    )
}

const MyUserMenu = (): JSX.Element => {
    const logout = useLogout()

    return (
        <UserMenu>
            <Menu.Item to={'/profile'}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>Настройки профиля</ListItemText>
            </Menu.Item>
            <Menu.Item to={'/login'} onClick={logout}>
                <ListItemIcon>
                    <ExitIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Выход из профиля</ListItemText>
            </Menu.Item>
        </UserMenu>
    )
}
