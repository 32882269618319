export class Alert {
    id: string | undefined = undefined
    title = ''
    description = ''
    link = ''
    linkType = ''
    linkText = ''
    color = ''
    showOnWeb = false
    showOnMobile = false
    factoryId = 0
    cityId = 0
    createdAt = ''
    updatedAt = ''
    deletedAt = ''
    active = false

    static webAndMobile = {id: 'webAndMobile', name: 'Веб и моб. приложение', showOnWeb: true, showOnMobile: true}
    static webOnly = {id: 'webOnly', name: 'Веб', showOnWeb: true, showOnMobile: false}
    static mobileOnly = {id: 'mobileOnly', name: 'Моб. приложение', showOnWeb: false, showOnMobile: true}
    static alertDevices = [Alert.webAndMobile, Alert.webOnly, Alert.mobileOnly]

    static findAlertDevice = (alert: Alert): {id: string; name: string; showOnWeb: boolean; showOnMobile: boolean} => {
        if (alert.showOnWeb && alert.showOnMobile) {
            return Alert.webAndMobile
        }
        if (alert.showOnWeb) {
            return Alert.webOnly
        }
        if (alert.showOnMobile) {
            return Alert.mobileOnly
        }
        return Alert.webAndMobile
    }

    static alertLinkTypes = [
        {id: 'Раздел "Что нового?"', name: 'Раздел "Что нового?"'},
        {id: 'Внешняя ссылка', name: 'Внешняя ссылка'},
        {id: 'Ежик в тумане', name: 'Ежик в тумане'}
    ]
}
