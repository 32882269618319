import {Button} from '@mui/material'
import {usePartnerChangeVilatermEnabledModal} from '../../../../hooks/partners'
export const ChangeVilatermEnabled = (): JSX.Element => {
    const showModal = usePartnerChangeVilatermEnabledModal()

    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Включить/отключить принудительную установку вилатерма</>
        </Button>
    )
}
