import {Card, Grid, Stack, Tooltip, Typography, useTheme} from '@mui/material'
import {Title} from 'react-admin'
import {showFieldTitle} from '../../consts/styles'
import CircleIcon from '@mui/icons-material/Circle'
import {useProfile, useSupportAccountByTokens} from '../../hooks/users'
import {UpdateToken} from './components/update_token/update_token'

export const Profile = (): JSX.Element => {
    const profile = useProfile()
    const {prm, msk} = useSupportAccountByTokens(profile)
    const theme = useTheme()

    const items = [
        {title: 'Пермь', data: prm},
        {title: 'Москва', data: msk}
    ]
    return (
        <Stack sx={{p: 2}} direction={'column'}>
            <Title title={'Профиль'} />
            <Typography sx={{mb: 2}}>{'Настройки профиля'}</Typography>
            <Grid container spacing={2} columns={{xs: 1, sm: 1, md: 5}}>
                <Grid item xs={3}>
                    <Card sx={{p: 3}}>
                        <Typography sx={{fontSize: 16, color: theme.palette.grey['600'], mb: 2}}>{'Основные'}</Typography>
                        <Typography sx={{fontSize: 14, color: theme.palette.grey['900'], mb: 2}}>
                            {'Аккаунты для копирования заказов'}
                        </Typography>
                        {items.map(({data, title}) => (
                            <Stack
                                key={title}
                                sx={{borderRadius: '6px', p: '6.5px 0', columnGap: 10}}
                                direction={'row'}
                                justifyContent={'space-between'}>
                                <Typography sx={{...showFieldTitle(theme), fontWeight: 700, width: 70}}>{title}</Typography>
                                <Typography sx={{...showFieldTitle(theme), flex: 1}}>{data?.email ?? 'Отсутствует'}</Typography>
                                <Tooltip placement={'top'} title={data ? 'Токен активен' : 'Токен устарел, необходимо обновить'}>
                                    <CircleIcon color={data ? 'success' : 'error'} />
                                </Tooltip>
                            </Stack>
                        ))}
                        <UpdateToken />
                    </Card>
                </Grid>
            </Grid>
        </Stack>
    )
}
