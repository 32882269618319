import {Button} from '@mui/material'
import {usePartnerChangeMetalImpostEnabledModal} from '../../../../hooks/partners'
export const ChangeMetalImpostEnabled = (): JSX.Element => {
    const showModal = usePartnerChangeMetalImpostEnabledModal()

    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Включить/отключить принудительную установку металлической пятки импоста</>
        </Button>
    )
}
