import {BooleanInput, DateInput, Filter, NumberInput, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {useNotRMAdmin} from '../../../hooks/common'
import {booleanItems} from '../../../consts/others'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'
import {Order} from '../../../models/order'
import {FramerSelect} from '../../../components/common/form-fields/select'
import {SelectorChips} from '../../../components/common/selector-chips/selector-chips'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const OrderListFilters = (props): JSX.Element => {
    const isNotRmAdmin = useNotRMAdmin()
    return (
        <>
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn />
                <BooleanInput label="Строгий поиск" source="strictSearch" alwaysOn />
                <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                    <RecordAutocomplete label={'Партнер'} />
                </ReferenceInput>
                <ReferenceInput label="Контрагент" source="dealerId" reference="dealers" alwaysOn perPage={10}>
                    <RecordAutocomplete label={'Контрагент'} />
                </ReferenceInput>
                <ReferenceInput label="Город" source="cityId" reference="cities" alwaysOn>
                    <RecordAutocomplete label={'Город'} />
                </ReferenceInput>
                <SelectInput label="Тип заказа" source="orderType" emptyText="Все" alwaysOn choices={Order.orderTypes} />
                <FramerSelect alwaysOn multiple label="Статус" source="state" choices={Order.statusesList} />
                {isNotRmAdmin && (
                    <FramerSelect
                        label="Причина отмены бронирования"
                        source="cancellationReason"
                        alwaysOn
                        multiple
                        choices={Order.cancellationReasonList}
                    />
                )}
                {isNotRmAdmin && <SelectInput label="Тип доставки" source="shipping" emptyText="Все" alwaysOn choices={Order.deliveryTypes} />}
                {isNotRmAdmin && <SelectInput label="Прямая доставка" source="direct" emptyText="Все" alwaysOn choices={booleanItems} />}

                <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
                <DateInput label="Дата создания до" source="dateTo" alwaysOn />
                <NumberInput label="Стоимость конструкций без наценки от, ₽" source="minConstrAmegaCost" alwaysOn />
                <NumberInput label="Стоимость конструкций без наценки до, ₽" source="maxConstrAmegaCost" alwaysOn />
                <SelectInput label="Заказы за фантики" source="isPaidByFramerPoints" emptyText="Все" alwaysOn choices={booleanItems} />
            </Filter>
            <SelectorChips items={Order.statusesList} label={'Статус'} source={'state'} />
            <SelectorChips items={Order.cancellationReasonList} label={'Причина отмены бронирования'} source={'cancellationReason'} />
        </>
    )
}
