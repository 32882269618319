import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useStopWindrawServiceModal} from '../../../../../hooks/windraw_services'
export const StopWindrawService = (props: FieldProps): JSX.Element => {
    const {label} = props
    const openForm = useStopWindrawServiceModal()
    return (
        <Button sx={{mt: 1}} fullWidth variant={'contained'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
