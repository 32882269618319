import {User} from './user'

export class AuditEvent {
    id = -1
    createdAt = ''
    updatedAt = ''
    eventType = new AuditEventType()
    authorId = -1
    user = new User()
    alertId = 3
    new = false
    old = false

    static getChangedEvent(audit?: AuditEvent): string {
        if ('amount' in audit) return JSON.stringify(audit?.amount)
        const hasOld = audit?.old !== undefined && audit?.old !== null
        const hasNew = audit?.new !== undefined && audit?.new !== null

        if (hasOld || hasNew) {
            return `${hasOld ? JSON.stringify(audit.old) : ''} -> ${hasNew ? JSON.stringify(audit.new) : ''}`
        }
        return '-'
    }
}

export class AuditEventType {
    id = ''
    label = ''
}
