export class ListItem<T> {
    id: string
    name: string
    additionalString?: string
    data: T
    alwaysExpanded = false
    selectable = true
    dataTest?: string
    configId?: number
    private _parentsIds: string[] = []
    private _listItems: ListItem<T>[] = []
    private _childrenNames: string[] = []

    constructor(id: string, name: string, data: T, configId?: number) {
        this.id = id
        this.name = name
        this.data = data
        this.configId = configId
    }

    get listItems(): ListItem<T>[] {
        return this._listItems
    }

    set listItems(items: ListItem<T>[]) {
        this._listItems = items
    }

    get parentsIds(): string[] {
        return this._parentsIds
    }

    set parentsIds(ids: string[]) {
        this._parentsIds = ids
        this.listItems.forEach(item => (item.parentsIds = ids.concat(this.id)))
    }

    get childrenNames(): string[] {
        if (this.listItems.length === 0 || this._childrenNames.length !== 0) {
            return this._childrenNames
        }

        this._childrenNames = this.children.map(i => i.name)

        return this._childrenNames
    }

    get children(): ListItem<T>[] {
        const children: ListItem<T>[] = []

        this.listItems.forEach(item => {
            children.push(item)
            children.push(...item.children)
        })

        return children
    }

    get nameWithAdditionalString(): string {
        return `${this.name}${this.additionalString ?? ''}`
    }
}
