import {DateInput, Filter, SelectArrayInput, SelectInput, TextInput} from 'react-admin'
import {Dealer} from '../../../../../models/dealer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PayHistoryFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput label="Статус подтверждения" source="opType" emptyText="Все" alwaysOn choices={Dealer.operationTypes} />
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
            <SelectArrayInput label="Тип баланса" source="payType" alwaysOn choices={Dealer.paymentTypes} fullWidth />
        </Filter>
    )
}
