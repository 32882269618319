/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/ban-ts-comment */
import {Theme} from '@mui/material'

export const ButtonStyle = (theme: Theme): Object => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'unset !important',
                    padding: '4px 10px',
                    lineHeight: 1.5,
                    fontSize: '12px',
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                containedInherit: {
                    color: theme.palette.grey[800],
                    backgroundColor: theme.palette.grey[300],
                    // @ts-ignore
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400]
                    }
                },
                containedPrimary: {
                    // @ts-ignore
                    boxShadow: theme.shadows.primary
                },
                containedSecondary: {
                    // @ts-ignore
                    boxShadow: theme.shadows.secondary
                },
                outlinedInherit: {
                    // @ts-ignore
                    border: `1px solid ${theme.palette.grey[500_32]}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                },
                textInherit: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                }
            }
        }
    }
}
