import {Button} from '@mui/material'
import {FieldProps} from 'react-admin'
import {useAlertRestoreModal} from '../../../../../hooks/alerts'
export const AlertRestore = (props: FieldProps): JSX.Element => {
    const {record} = props
    const openForm = useAlertRestoreModal()
    return (
        <Button fullWidth variant="contained" color="inherit" onClick={openForm}>
            {record?.active ? 'Удалить (делает оповещение неактивным)' : 'Восстановить (делает оповещение активным)'}
        </Button>
    )
}
