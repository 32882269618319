import {Toolbar, ToolbarProps} from '@mui/material'
import {SaveButton} from 'react-admin'
import React from 'react'

export const GlassCustomToolbar = (props: ToolbarProps): JSX.Element => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    )
}
