import {FunctionField} from 'react-admin'
import {useCallback} from 'react'
import {Order} from '../../../../../models/order'
import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {useNotRMAdmin} from '../../../../../hooks/common'
import {showFieldTitle} from '../../../../../consts/styles'

interface IOrderCancelReasonProps {
    title: string
    color?: string
    sx?: SxProps<Theme>
}

export const OrderCancelReason = (props: IOrderCancelReasonProps): JSX.Element => {
    const {title, color, sx} = props
    const theme = useTheme()
    const render = useCallback((record: Order) => Order.getCancelReason(record?.cancellationReason), [])
    const isNotRmAdmin = useNotRMAdmin()
    if (!isNotRmAdmin) return <></>
    return (
        <Stack
            alignItems={'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <FunctionField sx={{textAlign: 'end'}} render={render} />
        </Stack>
    )
}
