import {useSetDiscount} from '../../../../../hooks/orders'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {Button} from '@mui/material'
export const OrderSetDiscount = (): JSX.Element => {
    const showModal = useSetDiscount()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button fullWidth variant="contained" color="inherit" onClick={showModal}>
            <>Установить скидки</>
        </Button>
    )
}
