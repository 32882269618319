import {useCallback, useContext, useMemo} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useNotify, useRecordContext, useRefresh} from 'react-admin'
import {Partner} from '../models/partner'
import {useFormik} from 'formik'
import {Discount, IDiscountEditForm} from '../models/discount'
import {Autocomplete, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {AdditionalDiscounts} from '../models/additional-discounts'
import {cities} from '../consts/cities'
import {City} from '../models/city'
import {Dealer} from '../models/dealer'
import {useChiefAdmin, useSuperAdmin} from './common'
import {FormInput} from '../components/common/form-fields/form-input'
import {FormSelector} from '../components/common/form-fields/form-selector'
import * as Yup from 'yup'
import {useRequest} from './network'
export const usePartnerConfirmModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(
        () => dataProvider.partnerConfirmPromo(record.id, record.promo.code),
        [dataProvider, record.id, record.promo.code]
    )

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Применить промокод',
                body: record.promo.code,
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [mutate, record.promo.code, show]
    )
}

export const useDiscountEditModal = (
    discount: Discount,
    onSubmit: (values: IDiscountEditForm) => void,
    additionalDiscountFramerPoints?: number
): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const initialValues = useMemo(() => ({discount, additionalDiscountFramerPoints, comment: ''}), [additionalDiscountFramerPoints, discount])
    const submitHandler = useCallback(
        (values: IDiscountEditForm) => {
            onSubmit(values)
            onClose()
        },
        [onClose, onSubmit]
    )
    return useCallback(
        () =>
            show({
                withFormik: true,
                validationSchema: Yup.object().shape({
                    comment: Yup.string().required('Поле должно быть заполнено')
                }),
                initialValues: initialValues,
                title: 'Изменить скидки',
                body: ({values, handleChange, errors}) => (
                    <>
                        {(Object.keys(discount) as (keyof Discount)[])
                            .filter(key => Discount.titles(key) !== null)
                            .map(type => (
                                <FormInput
                                    name={`discount.${type}`}
                                    sx={{m: '0.5em'}}
                                    key={type}
                                    defaultValue={values?.discount[type]}
                                    autoFocus
                                    margin={'dense'}
                                    label={Discount.titles(type)}
                                    type={'number'}
                                    size={'small'}
                                    onChange={handleChange}
                                />
                            ))}
                        {additionalDiscountFramerPoints != null && (
                            <FormInput
                                sx={{m: '0.5em'}}
                                name={'additionalDiscountFramerPoints'}
                                defaultValue={values?.additionalDiscountFramerPoints}
                                margin={'dense'}
                                label={'Фантики'}
                                type={'number'}
                                size={'small'}
                                onChange={handleChange}
                            />
                        )}
                        <FormInput
                            error={!!errors.comment}
                            sx={{m: '0.5em'}}
                            margin={'dense'}
                            size={'small'}
                            name={'comment'}
                            label="Комментарий"
                            onChange={handleChange}
                            fullWidth
                            helperText={errors.comment as string}
                        />
                    </>
                ),
                onSuccess: submitHandler,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [show, initialValues, discount, additionalDiscountFramerPoints, submitHandler]
    )
}

interface IAdditionalDiscountsHook {
    changeBaseDiscounts: (discounts: Discount, additionalDiscountFramerPoints?: number) => void
    changeProfileDiscounts: (discounts: {[key: string]: Discount}) => void
    addProfileDiscounts: (profile: string) => void
}
export const useAdditionalDiscounts = (): IAdditionalDiscountsHook => {
    const record = useRecordContext<Partner>()
    const refresh = useRefresh()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const submitHandler = useCallback(
        (discounts: AdditionalDiscounts) => dataProvider.partnerDiscountEdit(record?.id, discounts),
        [dataProvider, record?.id]
    )
    const submit = useRequest(submitHandler, {onSuccess: refresh})

    const changeBaseDiscounts = useCallback(
        (baseDiscounts, additionalDiscountFramerPoints) =>
            submit.mutate({...record.AdditionalDiscounts, baseDiscounts, additionalDiscountFramerPoints}),
        [record.AdditionalDiscounts, submit]
    )
    const changeProfileDiscounts = useCallback(
        profileDiscounts => submit.mutate({...record.AdditionalDiscounts, profileDiscounts}),
        [record.AdditionalDiscounts, submit]
    )
    const addProfileDiscounts = useCallback(
        profile => {
            if (record.AdditionalDiscounts.profileDiscounts?.hasOwnProperty(profile))
                return notify('Такой профиль уже есть в списке', {type: 'error'})
            submit.mutate({
                ...record.AdditionalDiscounts,
                profileDiscounts: {...record.AdditionalDiscounts.profileDiscounts, [profile]: new Discount()}
            })
        },
        [notify, record.AdditionalDiscounts, submit]
    )

    return {changeBaseDiscounts, changeProfileDiscounts, addProfileDiscounts}
}

export const usePartnerDeleteClientModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(values => dataProvider.partnerDeleteClient(record.id, values.comment), [dataProvider, record.id])
    const {values, submitForm, handleChange} = useFormik({
        initialValues: {comment: ''},
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Действительно удалить клиента?',
                body: (
                    <FormInput
                        multiline
                        size={'small'}
                        name={'comment'}
                        label="Причина удаления"
                        defaultValue={values.comment}
                        onChange={handleChange}
                    />
                ),
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [handleChange, mutate, show, values.comment]
    )
}
export const usePartnerUpdateInfoModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback((values: Partner) => dataProvider.partnerUpdateInfo(values), [dataProvider])
    const {values, submitForm, handleChange} = useFormik<Partner>({
        initialValues: record,
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить партнера',
                body: (
                    <>
                        <FormInput
                            sx={{m: '0.5em'}}
                            name={'name'}
                            defaultValue={values.name}
                            autoFocus
                            margin="dense"
                            label="Наименование"
                            type="text"
                            onChange={handleChange}
                            size={'small'}
                        />
                        <FormInput
                            sx={{m: '0.5em'}}
                            name={'contactInfo.phone'}
                            defaultValue={values.contactInfo.phone}
                            autoFocus
                            margin="dense"
                            label="Телефоны"
                            type="text"
                            onChange={handleChange}
                            size={'small'}
                        />
                    </>
                ),
                onSuccess: mutate
            }),
        [handleChange, mutate, show, values.contactInfo.phone, values.name]
    )
}
export const usePartnerChangeCityModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeCity(record.id, values), [dataProvider, record.id])
    const {submitForm, setFieldValue} = useFormik<City>({
        initialValues: new City(),
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const changeHandler = useCallback(
        (_, value) => {
            setFieldValue('id', value.id)
            setFieldValue('name', value.name)
        },
        [setFieldValue]
    )

    return useCallback(
        () =>
            show({
                title: 'Сменить город партнера',
                body: (
                    <Autocomplete
                        style={{width: 300}}
                        options={cities}
                        autoHighlight
                        getOptionLabel={option => option.name}
                        onChange={changeHandler}
                        renderInput={params => <FormSelector {...params} label="Выберите город" size={'small'} />}
                    />
                ),
                onSuccess: mutate
            }),
        [changeHandler, mutate, show]
    )
}
export const usePartnerChangeMainDealerModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeMainDealer(record.id, values), [dataProvider, record.id])
    const {submitForm, setFieldValue} = useFormik<Dealer>({
        initialValues: new Dealer(),
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const changeHandler = useCallback(
        (_, value) => {
            setFieldValue('id', value.id)
        },
        [setFieldValue]
    )

    return useCallback(
        () =>
            show({
                title: 'Изменить головного контрагента',
                body: (
                    <Autocomplete
                        style={{width: 300}}
                        options={record.dealers}
                        autoHighlight
                        getOptionLabel={option => option.name}
                        onChange={changeHandler}
                        renderInput={params => <FormSelector {...params} label="Выберите контрагента" size={'small'} />}
                    />
                ),
                onSuccess: mutate
            }),
        [record.dealers, changeHandler, mutate, show]
    )
}
export const usePartnerDiscountVisiblityModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isAdmin = isSuperAdmin || isChiefAdmin
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeDiscountVisibility(values), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<Partner>({
        initialValues: record,
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: `Видимость скидки для ${record?.name}`,
                body: (
                    <>
                        <RadioGroup
                            id={'showDiscountType'}
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={record?.showDiscountType}
                            name="radio-buttons-group"
                            onChange={e => setFieldValue('showDiscountType', e.target.value)}>
                            <FormControlLabel
                                disabled={values.showDiscountType === 'CanNotShow' && !isAdmin}
                                value="Show"
                                control={<Radio />}
                                label="Включить"
                            />
                            <FormControlLabel
                                disabled={values.showDiscountType === 'CanNotShow' && !isAdmin}
                                value="DoNotShow"
                                control={<Radio />}
                                label="Отключить"
                            />
                            <FormControlLabel disabled={!isAdmin} value="CanNotShow" control={<Radio />} label="Запретить изменение" />
                        </RadioGroup>
                    </>
                ),
                onSuccess: mutate
            }),
        [show, record?.name, record?.showDiscountType, values.showDiscountType, isAdmin, mutate, setFieldValue]
    )
}
export const usePartnerChangeSubDealerEnabledModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeSubDealerEnabled(record?.id, values.value), [dataProvider, record.id])
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: record?.isSubdealershipAvailable},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: `Субдилерство для ${record?.name}`,
                body: (
                    <>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={values.value}
                            name="radio-buttons-group"
                            onChange={e => setFieldValue('value', e.target.value === 'true')}>
                            <FormControlLabel value={true} control={<Radio />} label="Включить" />
                            <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                        </RadioGroup>
                    </>
                ),
                onSuccess: mutate
            }),
        [show, record?.name, values.value, mutate, setFieldValue]
    )
}
export const usePartnerChangeVilatermEnabledModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeVilatermEnabled(record?.id, values.value), [dataProvider, record.id])
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: record?.isVilatermShouldBeSet},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: `Принудительная установка вилатерма в заказах для ${record?.name}`,
                body: (
                    <>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={values.value}
                            name="radio-buttons-group"
                            onChange={e => setFieldValue('value', e.target.value === 'true')}>
                            <FormControlLabel value={true} control={<Radio />} label="Включить" />
                            <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                        </RadioGroup>
                    </>
                ),
                onSuccess: mutate
            }),
        [show, record?.name, values.value, mutate, setFieldValue]
    )
}
export const usePartnerChangeMetalImpostEnabledModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(
        values => dataProvider.partnerChangeMetalImpostEnabled(record?.id, values.value),
        [dataProvider, record.id]
    )
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: record?.isMetalKreplenieImposta},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: `Принудительная установка металлического крепления импоста в заказах для ${record?.name}`,
                body: (
                    <>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={values.value}
                            name="radio-buttons-group"
                            onChange={e => setFieldValue('value', e.target.value === 'true')}>
                            <FormControlLabel value={true} control={<Radio />} label="Включить" />
                            <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                        </RadioGroup>
                    </>
                ),
                onSuccess: mutate
            }),
        [show, record?.name, values.value, mutate, setFieldValue]
    )
}
export const usePartnerChangeGostEnabledModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Partner>()
    const submitHandler = useCallback(values => dataProvider.partnerChangeGostEnabled(record?.id, values.value), [dataProvider, record.id])
    const {values, submitForm, setFieldValue} = useFormik<{value: boolean}>({
        initialValues: {value: record?.isGostShouldBeSet},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: `Принудительная установка параметра ГОСТ в заказах для ${record?.name}`,
                body: (
                    <>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={values.value}
                            name="radio-buttons-group"
                            onChange={e => setFieldValue('value', e.target.value === 'true')}>
                            <FormControlLabel value={true} control={<Radio />} label="Включить" />
                            <FormControlLabel value={false} control={<Radio />} label="Отключить" />
                        </RadioGroup>
                    </>
                ),
                onSuccess: mutate
            }),
        [show, record?.name, values.value, mutate, setFieldValue]
    )
}
