import {Stack, Typography, useTheme} from '@mui/material'
import {ReferenceField, TextField, useRecordContext} from 'react-admin'
import {Fragment, useMemo} from 'react'
import {FormattedOrderCost} from '../../../entities/orders/item/components/formatted-order-cost/formatted-order-cost'
import {Order} from '../../../models/order'
import {RaRecord} from 'ra-core'

interface ICardHeadProps {
    title: string
    source?: string
    reference?: string
    referenceSource?: string
    link?: string
    type?: 'default' | 'cost' | 'func'
    render?: (record?: RaRecord, source?: string) => void
}

export const CardHead = (props: ICardHeadProps): JSX.Element => {
    const {title, source, reference, link, referenceSource, type, render} = props
    const theme = useTheme()
    const record = useRecordContext<Order>()
    const Wrapper = reference ? ReferenceField : Fragment

    const Component = useMemo(() => {
        switch (type) {
            case 'cost':
                return FormattedOrderCost as unknown as Function
            default:
                return TextField as unknown as Function
        }
    }, [type])

    return (
        <Stack alignItems={'center'} sx={{mb: 2}} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{fontSize: 16, color: theme.palette.grey['600']}}>{title}</Typography>
            <Wrapper sx={{lineHeight: 1}} reference={reference} source={referenceSource} link={link}>
                <Component render={render} source={source} sx={{fontWeight: 600, fontSize: '16px', textAlign: 'end'}} record={record} />
            </Wrapper>
        </Stack>
    )
}
