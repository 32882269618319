import {useCallback, useContext} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {useFormik} from 'formik'
import {Autocomplete, Stack, Typography} from '@mui/material'
import {cities} from '../consts/cities'
import {Alert} from '../models/alert'
import {factories} from '../consts/factories'
import {FormInput} from '../components/common/form-fields/form-input'
import {FormSelector} from '../components/common/form-fields/form-selector'
import {ColorInput} from '../components/common/form-fields/color-input'
import {useRequest} from './network'

export const useAlertChangeModal = (isCreate: boolean): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Alert>()
    const submitHandler = useCallback(
        values => (isCreate ? dataProvider.alertCreate(record?.id, values) : dataProvider.alertChange(record?.id, values)),
        [dataProvider, isCreate, record?.id]
    )
    const {submitForm, setFieldValue, values, handleChange} = useFormik<Alert>({
        initialValues: isCreate ? new Alert() : record,
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const changeFactory = useCallback(
        (_, value) => {
            setFieldValue('factoryId', value.id)
            setFieldValue('cityId', 0)
        },
        [setFieldValue]
    )
    const changeDeviceType = useCallback(
        (_, value) => {
            setFieldValue('showOnWeb', value.showOnWeb)
            setFieldValue('showOnMobile', value.showOnMobile)
        },
        [setFieldValue]
    )
    return useCallback(
        () =>
            show({
                title: 'Изменить оповещение',
                body: (
                    <Stack gap={2}>
                        <FormInput
                            size={'small'}
                            sx={{width: '500px'}}
                            label={'Заголовок'}
                            defaultValue={values.title}
                            onChange={handleChange}
                            name={'title'}
                        />
                        <FormInput
                            name={'description'}
                            sx={{width: '500px'}}
                            label={'Описание'}
                            fullWidth
                            multiline={true}
                            minRows={3}
                            defaultValue={values.description}
                            onChange={handleChange}
                        />
                        <ColorInput fullWidth label={'Цвет'} defaultValue={values.color} onChange={handleChange} name={'color'} />
                        <Stack direction={'row'} gap={2}>
                            <Autocomplete
                                fullWidth
                                options={factories}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                onChange={changeFactory}
                                defaultValue={factories.find(f => f.id === values?.factoryId)}
                                renderInput={params => <FormSelector {...params} label="Выберите завод" size={'small'} />}
                            />
                            <Autocomplete
                                fullWidth
                                options={cities}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                onChange={(_, v) => setFieldValue('cityId', v?.id)}
                                defaultValue={cities.find(f => f.id === values?.cityId)}
                                renderInput={params => <FormSelector {...params} label="Выберите город" size={'small'} />}
                            />
                        </Stack>
                        <Typography sx={{fontWeight: 600, fontSize: 16}}>Оповещение со ссылкой</Typography>
                        <Stack direction={'row'} gap={2}>
                            <Autocomplete
                                fullWidth
                                options={Alert.alertLinkTypes}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                onChange={(_, v) => setFieldValue('linkType', v?.id)}
                                defaultValue={Alert.alertLinkTypes.find(f => f.id === values?.linkType)}
                                renderInput={params => <FormSelector {...params} label="Тип ссылки" size={'small'} />}
                            />
                            <Autocomplete
                                fullWidth
                                options={Alert.alertDevices}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                onChange={changeDeviceType}
                                defaultValue={Alert.findAlertDevice(values)}
                                renderInput={params => <FormSelector {...params} label="Тип устройства" size={'small'} />}
                            />
                        </Stack>

                        <FormInput name={'link'} fullWidth size={'small'} label={'Ссылка'} onChange={handleChange} defaultValue={values.link} />
                        <FormInput
                            name={'linkText'}
                            fullWidth
                            size={'small'}
                            label={'Текст ссылки'}
                            defaultValue={values.linkText}
                            onChange={handleChange}
                        />
                    </Stack>
                ),
                onSuccess: mutate
            }),
        [changeDeviceType, changeFactory, handleChange, mutate, setFieldValue, show, values]
    )
}
export const useAlertRestoreModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.alertRestore(record?.id), [dataProvider, record?.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: `${record?.active ? 'Удаление' : 'Восстановление'} уведомления`,
                body: <>Действительно {record?.active ? 'удалить' : 'восстановить'} уведомление?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, record?.active, show]
    )
}
