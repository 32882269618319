import {Datagrid, DateField, FunctionField, List, ListProps, ReferenceField, ShowButton} from 'react-admin'
import {useChiefAdmin, useSuperAdmin} from '../../../hooks/common'
import {AlertsListFilter} from './filter'
import {AlertsListActions} from './actions'
import {Alert} from '../../../models/alert'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const AlertsList = (props: ListProps): JSX.Element => {
    const {exporter} = props

    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()

    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<AlertsListFilter />}
            sort={{field: 'createdAt', order: 'DESC'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            bulkActionButtons={false}
            title="Заказы"
            actions={<AlertsListActions />}>
            <Datagrid rowClick={() => 'show'} bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="deletedAt" showTime label="Дата удаления" />

                <ReferenceField source="factoryId" label="Завод" reference="factories" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <ReferenceField source="cityId" label="Город" reference="cities" link="show">
                    <ClipboardTextField source="name" />
                </ReferenceField>
                <FunctionField label="Активно" render={record => (record.active ? 'да' : 'нет')} />
                <ClipboardTextField source="title" label="Заголовок" />
                <ClipboardTextField source="description" label="Описание" />

                <ClipboardTextField source="linkType" label="Тип ссылки" />
                <ClipboardTextField source="link" label="Ссылка" />
                <ClipboardTextField source="linkText" label="Текст ссылки" />
                <ClipboardTextField source="color" label="Цвет (hex)" />

                <FunctionField label="Устройство" render={record => Alert.findAlertDevice(record)?.name} />
                <ShowButton />
            </Datagrid>
        </List>
    )
}
