import {SimpleForm, TextInput, SelectInput, ReferenceInput, CreateBase, Title, NumberInput} from 'react-admin'
import {Card, Stack} from '@mui/material'

export const FrameSpacerCreate = (): JSX.Element => (
    <CreateBase>
        <Card>
            <Title title={'Создание дистанционной рамки'} />
            <SimpleForm>
                <TextInput sx={{width: 510}} source="name" label="Название" required />
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <TextInput source="sku" label="Артикул" required />
                    <ReferenceInput source="factoryId" reference="factories">
                        <SelectInput label="Завод" optionText={'name'} required />
                    </ReferenceInput>
                </Stack>
                <NumberInput sx={{width: 510}} source="thickness" label="Толщина" required />
            </SimpleForm>
        </Card>
    </CreateBase>
)
