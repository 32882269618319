import {FieldProps} from 'react-admin'
import {Button} from '@mui/material'
import {useChangeAmegaEmployee} from '../../../../hooks/amega_employees'
export const ChangeAmegaEmployee = (props: FieldProps & {isEdit?: boolean}): JSX.Element => {
    const {label, isEdit} = props
    const openForm = useChangeAmegaEmployee(isEdit)

    return (
        <Button fullWidth={isEdit} variant={isEdit ? 'contained' : 'outlined'} color="inherit" onClick={openForm}>
            {label}
        </Button>
    )
}
