import {FunctionField, Show, ShowControllerProps} from 'react-admin'
import {Box, Card, Grid, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {AlertChange} from './components/alert-change/alert-change'
import {AlertRestore} from './components/alert-restore/alert-restore'
export const AlertsItem = (props: ShowControllerProps): JSX.Element => {
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Оповещение'} source={'id'} />
                        <BooleanField label={'Активно '} source={'active'} color={theme.palette.grey['200']} />
                        <ShowField reference={'factories'} referenceSource={'factoryId'} title={'Завод'} source={'name'} link={'show'} />
                        <ShowField sx={{mb: 1}} reference={'cities'} referenceSource={'cityId'} title={'Город'} source={'name'} link={'show'} />
                        <ShowField title={'Заголовок'} source={'title'} />
                        <ShowField sx={{mb: 1}} title={'Описание'} source={'description'} />
                        <FunctionField
                            render={record => <ShowField sx={{mb: 1}} title={'Цвет (hex)'} color={record?.color} source={'color'} />}
                        />
                        <ShowField title={'Тип ссылки'} source={'linkType'} />
                        <ShowField sx={{mb: 1}} title={'Ссылка'} source={'link'} />
                        <ShowField type={'date'} title={'Дата создания'} source={'createdAt'} />
                        <ShowField type={'date'} title={'Дата последнего обновления'} source={'updatedAt'} />
                        <ShowField type={'date'} title={'Дата удаления'} source={'deleteAt'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <AlertChange label={'Изменить оповещение'} />
                        <FunctionField render={record => <AlertRestore record={record} />} />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
