import {Show, ShowControllerProps} from 'react-admin'
import {Box, Card, Grid} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {StopWindrawService} from './components/stop_service/stop_service'
export const WindrawServicesItem = (props: ShowControllerProps): JSX.Element => {
    return (
        <Show sx={{overflow: 'auto'}} component={Box} {...props}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Сервис'} />
                        <ShowField source="id" title="CorrelationID" />
                        <ShowField source="serviceType" title="Тип сервиса" />
                        <ShowField source="createdAt" type={'date'} title="Дата старта" />
                        <ShowField source="lastHeartbeatAt" type={'date'} title="Дата последнего пинга" />
                        <ShowField source="groupId" title="Имя группы" />
                        <ShowField reference={'factories'} referenceSource={'factoryID'} title={'Завод'} source={'name'} link={'show'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <StopWindrawService label={'Остановить сервис'} />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
