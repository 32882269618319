import {Button} from '@mui/material'
import {useChiefAdmin, useRmAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useCreatePreorder} from '../../../../../hooks/orders'
export const CreatePreorder = (): JSX.Element => {
    const showModal = useCreatePreorder()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const isRmAdmin = useRmAdmin()
    if (!(isSuperAdmin || isChiefAdmin || isRmAdmin)) return <></>
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Создать предварительный заказ в Windraw</>
        </Button>
    )
}
