let instance: Request | null = null
export interface IHttpClient {
    status: number
    headers: Headers
    body: string
    json: Object
}
export enum RequestMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export interface IRequestOptions {
    method?: RequestMethods
    body?: string | FormData
    headers?: Headers
}

class Request {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _defaultOptions: IRequestOptions = {
        method: RequestMethods.GET
    }

    constructor() {
        if (!instance) {
            instance = this
        }

        return instance
    }
    isJson(response: Response): boolean {
        const contentType = response.headers.get('content-type')
        return contentType && contentType.indexOf('application/json') !== -1
    }
    apiFetch = async (url: string, options?: IRequestOptions): Promise<IHttpClient> => {
        options = {
            ...this._defaultOptions,
            ...options
        }
        const token = localStorage.getItem('access_token')
        options.headers = options.headers || new Headers()
        options.headers.set('Authorization', `Bearer ${token}`)
        const response = await fetch(url, options)
        const isJson = this.isJson(response)
        const body = isJson ? await response.json() : await response.text()
        if (response.ok)
            return {
                status: response.status,
                headers: response.headers,
                body: body,
                json: body
            }
        const error = isJson ? body.error : body
        throw Error(error ? error : 'Произошла ошибка')
    }
}

export const request = new Request()
