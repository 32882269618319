import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    ReferenceField,
    Show,
    ShowControllerProps,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin'
import {Box, Card, Divider, Grid, Stack, Typography, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {DealerType} from './components/dealer-type/dealer-type'
import {PaymentType} from './components/payment-type/payment-type'
import {DealerConfirmed} from './components/dealer-confirmed/dealer-confirmed'
import {ShowCostField} from '../../orders/item/components/show-cost-field/show-cost-field'
import {DealerUpdateInfo} from './components/dealer-update-info/dealer-update-info'
import {DealerChangePaymentType} from './components/dealer-change-payment-type/dealer-change-payment-type'
import {DealerRefund} from './components/dealer-refund/dealer-refund'
import {DealerUndoRefund} from './components/dealer-undo-refund/dealer-undo-refund'
import {FunctionalShowField} from '../../../components/common/functional-show-field/functional-show-field'
import {Dealer} from '../../../models/dealer'
import {FramerBonusesIcon} from '../../../components/common/framer-bonuses-icon/framer-bonuses-icon'
import {useParams} from 'react-router'
import {PayHistoryFilters} from './components/payhistory/filters'

export const DealerItem = (props: ShowControllerProps): JSX.Element => {
    const theme = useTheme()
    const {id} = useParams()
    return (
        <Show component={Box} sx={{overflow: 'auto'}} {...props}>
            <TabbedShowLayout>
                <Tab label="Основные">
                    <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                        <Grid item xs={4}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Контрагент'} source={'id'} />
                                <ShowField color={theme.palette.grey['200']} title={'ИД завода'} source={'factoryId'} />
                                <ShowField title={'Код 1С'} source={'externalId'} />
                                <ShowField title={'Наименование'} source={'name'} />
                                <DealerType title={'Тип'} />
                                <PaymentType title={'Тип пополнения'} />
                                <ShowField sx={{mb: 1}} title={'ИНН'} source={'inn'} />
                                <ShowField color={theme.palette.grey['200']} title={'Город'} source={'city'} />
                                <ShowField title={'Телефоны'} source={'contactInfo.phone'} type={'custom-text'} />
                                <ShowField title={'Почты'} source={'contactInfo.email'} type={'custom-email'} />
                                <ShowField title={'Партнер'} source={'name'} referenceSource={'partnerId'} reference="partners" link="show" />
                                <ShowField
                                    title={'Головной контрагент'}
                                    source={'name'}
                                    referenceSource={'partner.mainDealerId'}
                                    reference="dealers"
                                    link="show"
                                />
                                <ShowField title={'Дата создания'} type={'date'} source={'createdAt'} />
                                <ShowField title={'Дата последнего обновления'} type={'date'} source={'updatedAt'} />
                                <ShowField title={'Дата удаления'} type={'date'} source={'deletedAt'} />
                                <DealerConfirmed title={'Статус подтверждения'} />
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Счёт во Framer'} />
                                <ShowCostField color={theme.palette.grey['200']} title={'Баланс'} source={'account.balance'} />
                                <FunctionalShowField
                                    title={'Баланс в фантиках'}
                                    render={(r: Dealer) => (
                                        <>
                                            {(r?.framerAccount?.balance / 100).toLocaleString()} <FramerBonusesIcon />
                                        </>
                                    )}
                                />
                                <ShowCostField title={'Кредитный лимит'} source={'creditLimitInCents'} />
                                <ShowField type={'number'} title={'Доступные бонусы Energy'} source={'bonusCard.availableScore'} />
                                <ShowField type={'number'} title={'Потенциальные бонусы Energy'} source={'bonusCard.potentialScore'} />
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Скидки'} />
                                <ShowField color={theme.palette.grey['200']} title={'Скидка на конструкции/стандарт'} source={'discount'} />
                                <ShowField title={'Скидка на нестандарт'} source={'discountNonstandard'} />
                                <ShowField sx={{mb: 1}} title={'Скидка на ламинацию'} source={'discountLamination'} />
                                <ShowField source="discountWarmAl" title={'Скидка на теплый алюминий'} />
                                <ShowField source="discountColdAl" title={'Скидка на холодный алюминий'} />
                                <ShowField sx={{mb: 1}} source="discountMetal" title={'Скидка на метал'} />
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{p: 2, mb: 2}}>
                                <CardHead title={'Действия'} />
                                <Stack spacing={1}>
                                    <DealerUpdateInfo label={'Обновить информацию'} />
                                    <DealerChangePaymentType label={'Сменить тип оплаты'} />
                                    <Divider />
                                    <DealerRefund label={'Возврат средств'} />
                                    <DealerUndoRefund label={'Отмена возврата средств'} />
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Tab>
                <Tab label={'История операций'}>
                    <List
                        filters={<PayHistoryFilters />}
                        title={' (История операций)'}
                        empty={<Typography paragraph>Операции отсутствуют</Typography>}
                        filter={{dealerId: +id}}
                        resource={'payhistory'}>
                        <Datagrid
                            sx={{
                                '& .RaDatagrid-headerCell:nth-child(2)': {width: '100%'},
                                '& .RaDatagrid-rowCell': {whiteSpace: 'nowrap'}
                            }}
                            resource={'Operations'}
                            bulkActionButtons={false}>
                            <DateField showTime source={'createdAt'} label={'Дата транзакции'} />
                            <ReferenceField reference={'orders'} source={'orderId'} label={'Номер заказа'} link={'show'}>
                                <Stack>
                                    <TextField sx={{width: '50%'}} source={'orderNumber'} label={''} />
                                    <TextField sx={{width: '50%', fontSize: 10}} source={'windrawOrderNumber'} label={''} />
                                </Stack>
                            </ReferenceField>
                            <FunctionField
                                color={'green'}
                                label={'Поступление'}
                                render={r => (r.operationType === 1 ? `+${r.amount.toLocaleString()}` : '-')}
                            />
                            <FunctionField
                                color={'red'}
                                label={'Списание'}
                                render={r => (r.operationType === -1 ? `-${r.amount.toLocaleString()}` : '-')}
                            />
                            <FunctionField label={'Тип оплаты'} render={r => Dealer.getPaymentType(r?.paymentType)} />
                        </Datagrid>
                    </List>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
