import {Button} from '@mui/material'
import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useOrderSetShippingDateModal} from '../../../../../hooks/orders'
import {useRecordContext} from 'react-admin'
export const OrderSetShippingDate = (): JSX.Element => {
    const showModal = useOrderSetShippingDateModal()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const record = useRecordContext()
    if (!(isChiefAdmin || isSuperAdmin)) return <></>
    return (
        <Button disabled={!record?.windrawOrderNumber?.length} variant="contained" color="inherit" onClick={showModal}>
            <>Установить дату доставки</>
        </Button>
    )
}
