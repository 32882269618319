import {TextField, ShowButton, useRecordContext} from 'react-admin'
import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {IDealerJSON} from '../../../../models/dealer'
import {Partner} from '../../../../models/partner'

export const PartnerDealers = (): JSX.Element => {
    const record = useRecordContext<Partner>()
    return (
        <>
            <TableContainer component={Card}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ид</TableCell>
                            <TableCell>Код 1С</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Тип</TableCell>
                            <TableCell>Головной контрагент</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.dealers.map(dealer => (
                            <TableRow key={dealer.id}>
                                <TableCell>{dealer.id}</TableCell>
                                <TableCell>{dealer.externalId}</TableCell>
                                <TableCell>
                                    <a href={`#/dealers/${dealer.id}/show`}>{dealer.name}</a>
                                </TableCell>
                                <TableCell>{dealer.type == 0 ? 'Юр.лицо' : dealer.type == 1 ? 'Физ.лицо' : 'ИП'}</TableCell>
                                <TableCell>{dealer.id == record.mainDealerId ? 'Да' : 'Нет'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
