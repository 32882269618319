import {Card, Grid, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {useNotRMAdmin} from '../../../hooks/common'
import {ShowCostField} from './components/show-cost-field/show-cost-field'
import {OrderDiscountField} from './components/order-discount-field/order-discount-field'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {OrderSetDiscount} from './components/order-set-discount/order-set-discount'

export const OrderItemDiscounts = (): JSX.Element => {
    const theme = useTheme()
    const isNotRmAdmin = useNotRMAdmin()
    return (
        <Grid container spacing={2} columns={{xs: 3, sm: 6, md: 12}}>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Цены'} />
                    <ShowCostField color={theme.palette.grey['200']} title="Цена завода без скидки" source={'totalAmegaCostWithoutDiscount'} />
                    <ShowCostField title="Цена завода" source={'totalAmegaCost'} />
                    <ShowCostField title="Цена конструкций" source={'totalAmegaConstrCost'} />
                    <ShowCostField title="Цена допов" source={'totalAmegaGoodsCost'} />
                    <ShowCostField title="Цена завода на стандарт" source={'orderCostStandard'} />
                    <ShowCostField title="Цена завода на нестандарт" source={'orderCostNonstandard'} />
                    <ShowCostField title="Цена завода на ламинацию" source={'orderCostLamination'} />
                    <ShowCostField title="Цена завода на теплый алюминий" source={'orderCostWarmAl'} />
                    <ShowCostField title="Цена завода на холодный алюминий" source={'orderCostColdAl'} />
                    <ShowCostField title="Цена завода на метал" source={'orderCostMetal'} />
                    <ShowCostField title="Цена завода на стеклопакеты" source={'orderCostGlass'} />
                </Card>
            </Grid>
            {isNotRmAdmin && (
                <Grid item xs={3}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Скидки'} />
                        <OrderDiscountField title="Скидка на конструкции(в МСК)/стандарт(в Перми)" source={'st'} />
                        <OrderDiscountField title="Скидка на стеклопакеты" source={'gls'} />
                        <OrderDiscountField title="Скидка на допы" source={'gds'} />
                        <OrderDiscountField title="Скидка на метал" source={'met'} />
                        <OrderDiscountField title="Скидка на нестандарт" source={'nst'} />
                        <OrderDiscountField title="Скидка на ламинацию" source={'lam'} />
                        <OrderDiscountField title="Скидка на теплый алюминий" source={'wAl'} />
                        <OrderDiscountField title="Скидка на холодный алюминий" source={'cAl'} />
                        <ShowField source="manualDiscounts.comment" title="Комментарий" />
                    </Card>
                </Grid>
            )}
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Расчетные скидки'} />
                    <ShowField type={'number'} source="calcDiscounts.st" title="Расчитан со скидкой на конструкции(в МСК)/стандарт(в Перми)" />
                    <ShowField type={'number'} source="calcDiscounts.gls" title="Расчитан со скидкой на стеклопакеты" />
                    <ShowField type={'number'} source="calcDiscounts.gds" title="Расчитан со скидкой на допы" />
                    <ShowField type={'number'} source="calcDiscounts.met" title="Расчитан со скидкой на метал" />
                    <ShowField type={'number'} source="calcDiscounts.nst" title="Расчитан со скидкой на нестандарт" />
                    <ShowField type={'number'} source="calcDiscounts.lam" title="Расчитан со скидкой на ламинацию" />
                    <ShowField type={'number'} source="calcDiscounts.wAl" title="Расчитан со скидкой на теплый алюминий" />
                    <ShowField type={'number'} source="calcDiscounts.cAl" title="Расчитан со скидкой на холодный алюминий" />
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Действия'} />
                    <OrderSetDiscount />
                </Card>
            </Grid>
        </Grid>
    )
}
