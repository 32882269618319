export class AmegaEmployee {
    id: number | undefined
    createdAt = ''
    updatedAt = ''
    deletedAt = ''
    externalId = ''
    name = ''
    email = ''
    catalog1C = ''
    referralCode = ''
    departmentExternalID = ''
    cityId: number | undefined
    city = ''
}
