import {useChiefAdmin, useSuperAdmin} from '../../../../../hooks/common'
import {useRecordContext} from 'react-admin'
import {Button} from '@mui/material'
import {useSetHandleParams} from '../../../../../hooks/orders'
export const OrderSetHandle = (): JSX.Element => {
    const showModal = useSetHandleParams()
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const record = useRecordContext()
    const enabledStates = ['draft', 'needs_recalc', 'calc_errors']
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Button disabled={!enabledStates.includes(record.state)} variant="contained" color="inherit" onClick={showModal}>
            <>Установить параметры ручки</>
        </Button>
    )
}
