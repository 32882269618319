import {useCallback, useContext, useEffect, useState} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin'
import {useFormik} from 'formik'
import {Dealer} from '../models/dealer'
import {Autocomplete, FormControlLabel, Radio, RadioGroup, Stack} from '@mui/material'
import {Refund} from '../models/refund'
import {FormInput} from '../components/common/form-fields/form-input'
import {FormSelector} from '../components/common/form-fields/form-selector'
import {useRequest} from './network'

export const useDealerUpdateInfoModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Dealer>()
    const submitHandler = useCallback((values: Dealer) => dataProvider.dealerUpdateInfo(values), [dataProvider])
    const {values, submitForm, handleChange} = useFormik<Dealer>({
        initialValues: record,
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Обновить контрагента',
                body: (
                    <>
                        <FormInput
                            sx={{
                                m: '0.5em'
                            }}
                            name={'externalId'}
                            defaultValue={values?.externalId}
                            autoFocus
                            margin="dense"
                            label="Код 1С"
                            type="text"
                            onChange={handleChange}
                            size={'small'}
                        />
                        <FormInput
                            sx={{
                                m: '0.5em'
                            }}
                            name={'name'}
                            defaultValue={values?.name}
                            autoFocus
                            margin="dense"
                            label="Наименование"
                            type="text"
                            onChange={handleChange}
                            size={'small'}
                        />
                        <FormInput
                            sx={{
                                m: '0.5em'
                            }}
                            name={'contactInfo.phone'}
                            defaultValue={values?.contactInfo.phone}
                            autoFocus
                            margin="dense"
                            label="Телефоны"
                            type="text"
                            onChange={handleChange}
                            size={'small'}
                        />
                    </>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [handleChange, mutate, show, values?.contactInfo.phone, values?.externalId, values?.name]
    )
}
export const useDealerChangePaymentType = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Dealer>()
    const submitHandler = useCallback((values: Dealer) => dataProvider.dealerChangePaymentType(values), [dataProvider])
    const {values, submitForm, setFieldValue} = useFormik<Dealer>({
        initialValues: record,
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Изменение типа оплаты',
                body: (
                    <RadioGroup
                        id={'paymentType'}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={values?.paymentType}
                        name="radio-buttons-group"
                        onChange={e => setFieldValue('paymentType', e.target.value)}>
                        <FormControlLabel value="card" control={<Radio />} label="По карте" />
                        <FormControlLabel value="invoice" control={<Radio />} label="По счету" />
                    </RadioGroup>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [mutate, setFieldValue, show, values]
    )
}
export const useDealerRefund = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Dealer>()
    const submitHandler = useCallback((values: Refund) => dataProvider.dealerRefund(record?.id, values), [dataProvider, record?.id])
    const {values, submitForm, handleChange, setFieldValue} = useFormik<Refund>({
        initialValues: new Refund(record?.id),
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Возврат средств',
                body: (
                    <Stack gap={1.5}>
                        <Stack direction={'row'} gap={1.5}>
                            <Autocomplete
                                fullWidth
                                options={[
                                    {id: 1, name: 'Рубли'},
                                    {id: 2, name: 'Фантики'}
                                ]}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                onChange={(_, v) => setFieldValue('accountType', v.id)}
                                renderInput={params => <FormSelector {...params} label="Тип счета" size={'small'} />}
                            />
                            <FormInput
                                size={'small'}
                                name={'amount'}
                                defaultValue={values.amount}
                                autoFocus
                                label="Сумма, ₽"
                                type="text"
                                onChange={handleChange}
                                fullWidth
                            />
                        </Stack>
                        <FormInput
                            size={'small'}
                            name={'comment'}
                            defaultValue={values.comment}
                            autoFocus
                            label="Комментарий"
                            type="text"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [show, values.amount, values.comment, handleChange, mutate, setFieldValue]
    )
}
export const useDealerUndoRefund = (): (() => void) => {
    const [options, setOptions] = useState([])
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext<Dealer>()
    const getOptionsHandler = useCallback(() => dataProvider.dealerRefundOptions(record?.id), [dataProvider, record?.id])
    const submitHandler = useCallback(
        (values: {id: number}) => dataProvider.dealerUndoRefund(record?.id, values.id),
        [dataProvider, record?.id]
    )
    const {submitForm, setFieldValue} = useFormik<{id: number}>({
        initialValues: {id: undefined},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const {mutate: getOptions} = useRequest<{json: Refund[]}>(getOptionsHandler, {onSuccess: data => setOptions(data.json)})

    useEffect(() => {
        getOptions()
    }, [getOptions, record])

    return useCallback(
        () =>
            show({
                title: 'Отмена возврата средств',
                body: (
                    <>
                        <Autocomplete
                            fullWidth
                            sx={{minWidth: 400}}
                            options={(options?.map(Refund.getRefundOptions) ?? []).map(v => ({id: v.value, name: v.label}))}
                            autoHighlight
                            getOptionLabel={option => option.name}
                            onChange={(_, v) => setFieldValue('id', v.id)}
                            renderInput={params => <FormSelector {...params} label="Возвраты" size={'small'} />}
                        />
                    </>
                ),
                onSuccess: mutate,
                successText: 'Сохранить'
            }),
        [mutate, options, setFieldValue, show]
    )
}
