import {usePartnerChangeSubDealerEnabledModal} from '../../../../hooks/partners'
import {Button} from '@mui/material'
export const ChangeSubDealerEnabled = (): JSX.Element => {
    const showModal = usePartnerChangeSubDealerEnabledModal()
    return (
        <Button variant="contained" color="inherit" onClick={showModal}>
            <>Включить/Отключить субдилерство</>
        </Button>
    )
}
