import {DateInput, Filter, ReferenceInput, SelectInput, TextInput} from 'react-admin'
import {RecordAutocomplete} from '../../../components/common/record-autocomplete/record-autocomplete'
import {Dealer} from '../../../models/dealer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DealerListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput
                label="Статус подтверждения"
                source="confirmationStatus"
                emptyText="Все"
                alwaysOn
                choices={Dealer.confirmationStatusList}
            />
            <SelectInput label="Юр статус" emptyText="Все" source="type" alwaysOn choices={Dealer.typeList} />
            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                <RecordAutocomplete label={'Партнер'} />
            </ReferenceInput>
            <DateInput label="Дата создания от" source="dateFrom" alwaysOn />
            <DateInput label="Дата создания до" source="dateTo" alwaysOn />
        </Filter>
    )
}
