import {Show} from 'react-admin'
import {UserUpdatePassword} from './components/user-update-password'
import {UserUpdateInfo} from './components/user-update-info/user-update-info'
import {Box, Card, Grid, Stack, useTheme} from '@mui/material'
import {CardHead} from '../../components/common/card-head/card-head'
import {ShowField} from '../../components/common/card-text-field/show-field'
import {FunctionalShowField} from '../../components/common/functional-show-field/functional-show-field'
import {User} from '../../models/user'

export const UserItem = (): JSX.Element => {
    const theme = useTheme()
    return (
        <Show sx={{overflow: 'auto'}} component={Box}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Пользователь'} source={'id'} />
                        <ShowField color={theme.palette.grey['200']} title={'Логин'} source={'username'} />
                        <ShowField sx={{mb: 1}} type={'custom-email'} title={'Email'} source={'email'} />
                        <FunctionalShowField<User>
                            color={theme.palette.grey['200']}
                            title={'Роль'}
                            render={u => User.rolesToString(u.userRoles)}
                        />
                        <ShowField sx={{mb: 2}} title={'Город'} source={'filial.city.name'} />
                        <ShowField type={'date'} title={'Дата регистрации'} source={'createdAt'} />
                        <ShowField type={'date'} title={'Дата последнего обновления'} source={'updatedAt'} />
                        <ShowField type={'date'} title={'Дата удаления'} source={'deletedAt'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Партнер'} source={'name'} referenceSource={'partnerId'} reference="partners" link="show" />
                        <ShowField
                            reference={'dealers'}
                            link={'show'}
                            referenceSource={'selectedDealerId'}
                            color={theme.palette.grey['200']}
                            title={'Код 1С'}
                            source={'externalId'}
                            sx={{mb: 1}}
                        />
                        <ShowField title={'Филиал'} referenceSource={'filialId'} reference={'filials'} link={'show'} source={'name'} />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <Stack spacing={1}>
                            <UserUpdateInfo label="Обновить информацию" />
                            <UserUpdatePassword label="Обновить пароль" />
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
