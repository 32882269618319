export interface IBankAccountJSON {
    name: string
    address: string
    bic: string
    number: string
    corrAccount: string
}

export class BankAccount {
    static parse(bankAccountJSON: IBankAccountJSON): BankAccount {
        const bankAccount = new BankAccount()
        Object.assign(bankAccount, bankAccountJSON)
        return bankAccount
    }

    name!: string
    address!: string
    bic!: string
    number!: string
    corrAccount!: string
}
