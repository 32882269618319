import {Card, Stack, TextField} from '@mui/material'
import {Button} from 'react-admin'
import AddCardIcon from '@mui/icons-material/AddCard'
import {useChiefAdmin, useSuperAdmin} from '../../../../hooks/common'
import {useFormik} from 'formik'
import {useCallback} from 'react'
import * as Yup from 'yup'

interface IProfileAddProps {
    onSubmit: (profile: string) => void
}

export const ProfileAdd = (props: IProfileAddProps): JSX.Element => {
    const {onSubmit} = props
    const validationSchema = Yup.object().shape({profile: Yup.string().required('Обязательное поле').min(3, 'Не менее 3 символов')})
    const isSuperAdmin = useSuperAdmin()
    const isChiefAdmin = useChiefAdmin()
    const submitHandler = useCallback((values: {profile: string}) => onSubmit(values.profile), [onSubmit])
    const {values, handleChange, submitForm, errors} = useFormik<{profile: string}>({
        initialValues: {profile: ''},
        onSubmit: submitHandler,
        validationSchema
    })
    if (!(isSuperAdmin || isChiefAdmin)) return <></>
    return (
        <Stack component={Card} sx={{p: '10px', mt: 1}} direction={'row'} justifyContent={'flex-start'}>
            <TextField
                name={'profile'}
                sx={{maxWidth: '30%', m: 0, marginRight: '16px'}}
                defaultValue={values.profile}
                margin="dense"
                type="text"
                placeholder="Введите название профиля"
                size={'small'}
                onChange={handleChange}
                error={!!errors.profile}
                helperText={errors.profile}
            />
            <Button endIcon={<AddCardIcon />} size={'small'} sx={{maxHeight: '40px'}} variant="outlined" color="primary" onClick={submitForm}>
                <>Добавить скидку для профиля</>
            </Button>
        </Stack>
    )
}
