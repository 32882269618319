import {Box, Card, Grid} from '@mui/material'
import {CardHead} from '../../components/common/card-head/card-head'
import {UpdateModel} from './components/update_model/update_model'
import {UpdateTemplates} from './components/update_templates/update_templates'
import {FullRecalc} from './components/full_recalc/full_recalc'
export const UserParams = (): JSX.Element => {
    return (
        <Box component={Box} sx={{overflow: 'auto', p: 2}}>
            <Grid container spacing={2} columns={{xs: 3, sm: 8, md: 12}}>
                <Grid item xs={4}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Действия'} />
                        <UpdateModel label={'Обновить модель'} />
                        <UpdateTemplates label={'Обновить шаблоны'} />
                        <FullRecalc label={'Полный пересчет'} />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
