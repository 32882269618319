import {useSnackbar} from './snackbar'
import {errorHandler} from '../helpers/error'
import {useMutation} from 'react-query'
import {UseMutationOptions, UseMutationResult} from 'react-query/types/react/types'
import {MutationFunction} from 'react-query/types/core/types'

export const useRequest = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext> => {
    const notification = useSnackbar()
    return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
        ...options,
        onSuccess: (data: TData, variables: TVariables, context: TContext) => {
            if (typeof data === 'object' && 'body' in data && typeof data.body === 'string')
                notification({msg: data.body.toString(), type: 'success'})
            options.onSuccess(data, variables, context)
        },
        onError: (e: TError) => {
            notification({msg: errorHandler(e as Error), type: 'error'})
        }
    })
}
