import {Datagrid, DateField, List, ListProps} from 'react-admin'
import {OrderActionsListActions} from './actions'
import {OrderActionsListFilter} from './filter'
import {OrderActionsPlace} from './components/order_actions_place/order_actions_place'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'

export const OrderActionsList = (props: ListProps): JSX.Element => {
    const {exporter} = props

    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            filters={<OrderActionsListFilter />}
            sort={{field: 'time', order: 'DESC'}}
            exporter={exporter}
            bulkActionButtons={false}
            title="Действия в заказе"
            empty={false}
            actions={<OrderActionsListActions />}>
            <Datagrid bulkActionButtons={false}>
                <ClipboardTextField source="id" label="Ид" />
                <DateField source="time" showTime label="Время действия" />
                <OrderActionsPlace source="place" label="Место" />
                <ClipboardTextField source="action" label="Действие" />
                <ClipboardTextField source="type" label="Тип действия" />
                <ClipboardTextField source="user.username" label="Пользователь" />
            </Datagrid>
        </List>
    )
}
