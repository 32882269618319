import {Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, NumberInput} from 'react-admin'
import {Card, Stack} from '@mui/material'
import React from 'react'
import {GlassCustomToolbar} from '../../../components/common/glass-custom-toolbar/glass-custom-toolbar'

export const TintFilmEdit = (): JSX.Element => {
    return (
        <Edit title={'Редактирование тонировочной пленки'}>
            <Card>
                <SimpleForm toolbar={<GlassCustomToolbar />}>
                    <TextInput sx={{width: 510}} source="description" label="Описание" required multiline minRows={3} />
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <TextInput source="sku" label="Артикул" required />
                        <NumberInput source="numpos" label="Позиция" required />
                        <ReferenceInput source="factoryId" reference="factories">
                            <SelectInput label="Завод" optionText={'name'} required />
                        </ReferenceInput>
                    </Stack>
                </SimpleForm>
            </Card>
        </Edit>
    )
}
