import {createContext} from 'react'
import {FormikProps, FormikValues} from 'formik'

interface IDialogContext {
    data?: IDialogData
    show: (data: IDialogData) => void
    onClose: () => void
}
interface IDialogData {
    title: string
    initialValues?: FormikValues
    validationSchema?: Object
    successText?: string
    cancelText?: string
    onSuccess?: (values: FormikValues | void) => void
    body: ((values: FormikProps<FormikValues>) => JSX.Element) | JSX.Element
    withFormik?: boolean
    expanded?: boolean
}
const DialogContext = createContext<IDialogContext>({
    data: undefined,
    show: () => {},
    onClose: () => {}
})

export {DialogContext}
export type {IDialogData, IDialogContext}
