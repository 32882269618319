import {useRecordContext} from 'react-admin'
import {Card, Divider, Grid, Stack, useTheme} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {BooleanField} from '../../../components/common/boolean-field/boolean-field'
import {OrderState} from './components/order-state/order-state'
import {OrderBookingState} from './components/order-booking-state/order-booking-state'
import {OrderTags} from './components/order-tags/order-tags'
import {OrderSetTags} from './components/order-set-tags/order-set-tags'
import {OrderCancelReason} from './components/order-cancel-reason/order-cancel-reason'
import {ShowCostField} from './components/show-cost-field/show-cost-field'
import {ShippingType} from './components/shipping-type/shipping-type'
import {ShippingAddress} from './components/shipping-address/shipping-address'
import {ShippingWarehouse} from './components/shipping-warehouse/shipping-warehouse'
import {ForceShippingType} from './components/force-shipping-type/force-shipping-type'
import {ForceShippingAddress} from './components/force-shipping-address/force-shipping-address'
import {ForceDirectShipping} from './components/force-direct-shipping/force-direct-shipping'
import {ForceWeekdays} from './components/force-weekdays/force-weekdays'
import {CardHeadSubtitle} from '../../../components/common/card-head-subtitle/card-head-subtitle'
import {DownloadOffer} from './components/download-offer/download-offer'
import {CopyToDev} from './components/copy-to-dev/copy-to-dev'
import {CopyToMe} from './components/copy-to-me/copy-to-me'
import {Restore} from './components/restore/restore'
import {RestoreStatus} from './components/restore-status/restore-status'
import {CreateAtWindrawAnd1c} from './components/create-at-windraw-and-1c/create-at-windraw-and-1c'
import {CreateAt1c} from './components/create-at-1c/create-at-1c'
import {Booking} from './components/booking/booking'
import {BookInERP} from './components/book-in-erp/book-in-erp'
import {CreatePreorder} from './components/create-preorder/create-preorder'
import {RecalcOrder} from './components/recalc-order/recalc-order'
import {FullRecalcOrder} from './components/full-recalc-order/full-recalc-order'
import {ForcedRecalcOrder} from './components/forced-recalc-order/forced-recalc-order'
import {ProductSeparationStep} from './components/product-separation-step/product-separation-step'
import {OrderSetShippingDate} from './components/order-set-shipping-date/order-set-shipping-date'
import {RequestBookingCancel} from './components/request-booking-cancel/request-booking-cancel'
import {OrderUnbook, OrderAutoUnbook} from './components/order-unbook/order-unbook'
import {OrderSetGost} from './components/order-set-gost/order-set-gost'
import {OrderSetHandle} from './components/order-set-handle/order-set-handle'
import {useNotRMAdmin} from '../../../hooks/common'
import {CompleteOrder} from './components/complete-order/complete-order'
import {OrderSetGostMark} from './components/order-set-gost-mark/order-set-gost-mark'
import {OrderSetShippingType} from './components/order-set-shipping-type/order-set-shipping-type'
import {OrderSetShippingCost} from './components/order-set-shipping-cost/order-set-shipping-cost'
import {OrderSetShippingWeekdays} from './components/order-set-shipping-weekdays/order-set-shipping-weekdays'
import {OrderSetShippingWarehouse} from './components/order-set-shipping-warehouse/order-set-shipping-warehouse'
import {OrderSetDirectShipping} from './components/order-set-direct-shipping/order-set-direct-shipping'
import {OrderSetShippingAddress} from './components/order-set-shipping-address/order-set-shipping-address'
import {useOrderTotalCost} from '../../../hooks/orders'

export const OrderItemMain = (): JSX.Element => {
    const theme = useTheme()
    const isNotRmAdmin = useNotRMAdmin()
    const record = useRecordContext()
    const totalCost = useOrderTotalCost()
    return (
        <Grid container spacing={2} columns={{xs: 3, sm: 6, md: 12}}>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Заказ'} source={'id'} />
                    <ShowField color={theme.palette.grey['200']} title={'Номер Framer'} source={'orderNumber'} />
                    <ShowField title={'Номер предв. заказа WinDraw'} source={'windrawPreOrderNumber'} />
                    <ShowField title={'Номер WinDraw'} source={'windrawOrderNumber'} />
                    <ShowField title={'Номер WinDraw на допы'} source={'windrawGoodsOrderNumber'} />
                    <BooleanField sx={{mb: 1}} label={'Есть допы заводы'} source={'hasGoods'} />
                    <OrderState color={theme.palette.grey['200']} title={'Статус'} />
                    <OrderTags title={'Теги'} />
                    <OrderBookingState title={'Статус бронирования'} />
                    <OrderCancelReason sx={{mb: 1}} title={'Причина отмены бронирования'} />
                    <ShowField title={'Дата создания'} type={'date'} source={'createdAt'} />
                    <ShowField title={'Дата последнего пересчета'} type={'date'} source={'lastCalculatedAt'} />
                    <ShowField title={'Дата последнего обновления'} type={'date'} source={'updatedAt'} />
                    <ShowField title={'Дата удаления'} type={'date'} source={'deletedAt'} />
                    <ShowField sx={{mb: 1}} title={'Дата оплаты'} type={'date'} source={'paidAt'} />
                    <BooleanField label={'Неизменяем'} source={'readonly'} />
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Стоимость'} source={'totalAmegaCost'} type={'cost'} render={totalCost} />
                    <ShowCostField color={theme.palette.grey['200']} title={'Цена завода'} source={'totalAmegaCost'} />
                    <ShowCostField title={'Цена завода без скидки'} source={'totalAmegaCostWithoutDiscount'} />
                    <ShowField
                        options={{style: 'unit', unit: 'percent', maximumFractionDigits: 1}}
                        type={'number'}
                        title={'Процент маржи'}
                        source={'marginPercent'}
                        sx={{mb: 1}}
                    />
                    <ShowCostField
                        title={'Цена субдилера'}
                        source={record?.partner?.isSubdealershipAvailable ? 'totalAmegaCostToDispla' : '-'}
                    />
                    <ShowCostField title={'Цена клиента'} source={'totalCost'} />
                    <ShowCostField title={'Цена конструкций'} source={'totalAmegaConstrCost'} />
                    <ShowCostField title={'Цена допов'} source={'totalAmegaGoodsCost'} />
                    <ShowCostField title={'Цена доставки'} source={'shippingCost'} />
                    <ShowCostField
                        title={'Стоимость транспортировки негабарита до склада'}
                        source={'oversizedProductsTransportationServiceCost'}
                    />
                    <ShowCostField sx={{mb: 1}} title={'Стоимость подъема на этаж'} source={'shippingToFloorCost'} />
                    <ShowField
                        options={{style: 'unit', unit: 'percent', maximumFractionDigits: 1}}
                        type={'number'}
                        title={'Наценка на конструкции'}
                        source={'constrMarginPercent'}
                    />
                    <ShowField
                        options={{style: 'unit', unit: 'percent', maximumFractionDigits: 1}}
                        type={'number'}
                        title={'Наценка на допы'}
                        source={'goodsMarginPercent'}
                        sx={{mb: 1}}
                    />
                    <ShowField
                        options={{style: 'unit', unit: 'percent', maximumFractionDigits: 1}}
                        type={'number'}
                        title={'Процент скидки'}
                        source={'discount.percent'}
                    />
                    <ShowField
                        options={{style: 'currency', currency: 'RUB'}}
                        type={'number'}
                        title={'Скидка (₽)'}
                        source={'calcDiscounts.ttl'}
                    />
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Доставка'} />
                    <ShippingType title={'Тип доставки'} color={theme.palette.grey['200']} />
                    {record?.shippingType === 'delivery' && <ShippingAddress title={'Адрес доставки'} />}
                    {record?.shippingType === 'pickup' && <ShippingWarehouse title={'Склад самовывоза'} />}
                    {record?.shippingType === 'delivery' && <ShowField title={'Этаж доставки'} source={'shippingFloor'} />}
                    {record?.shippingType === 'delivery' && <BooleanField sx={{mb: 1}} label={'Прямая доставка'} source={'isDirectShipping'} />}
                    <ShowField title={'Контактное лицо'} source={'contactPerson.name'} />
                    <ShowField title={'Контактный номер телефона'} source={'contactPerson.phone'} />
                    <ShowField sx={{mb: 1}} direction={'column'} title={'Комментарий к доставке'} source={'shippingComment'} />
                    <ShowField type={'date'} title={'Дата доставки'} source={'shippingDate'} />
                    {isNotRmAdmin && <ShowField title={'Дата монтажа'} type={'date'} source={'mountingDate'} />}
                    <ForceShippingType title={'Принудительный тип доставки'} />
                    <ForceShippingAddress title={'Принудительный адрес доставки'} />
                    <ForceDirectShipping title={'Принудительная прямая доставка'} />
                    <ShowField
                        title={'Принудительный склад доставки'}
                        source={'name'}
                        reference={'warehouses'}
                        referenceSource={'forceWarehouseId'}
                    />
                    <ShowCostField title={'Принудительная цена доставки (₽)'} source={'forceShippingCost'} withFramerPoints={false} />
                    <ForceWeekdays title={'Принудительные дни недели доставки'} />
                </Card>
            </Grid>

            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'О заказе'} />
                    <ShowField title={'Площадь'} source={'totalSquare'} />
                    <ShowField sx={{mb: 1}} type={'number'} title={'Вес'} source={'totalWeight'} />
                    <ShowField direction={'column'} title={'Комментарий к заказу'} source={'comment'} />
                    <BooleanField label={'Показывать комментарий в КП'} source={'showCommentInKp'} />
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Действия'} />
                    <CardHeadSubtitle title={'Действия с заказом'} />
                    <Stack spacing={1}>
                        <DownloadOffer />
                        <CopyToDev />
                        <CopyToMe />
                        <Restore />
                        <RestoreStatus />
                        <CompleteOrder />
                        <OrderSetTags />
                    </Stack>
                    <Divider sx={{mt: '16px', mb: '16px'}} />
                    <CardHeadSubtitle title={'WinDraw и 1С'} />
                    <Stack spacing={1}>
                        <CreateAtWindrawAnd1c />
                        <CreateAt1c />
                        <Booking />
                        {record?.city.factoryId === 4 && <BookInERP />}
                        <CreatePreorder />
                    </Stack>
                    <Divider sx={{mt: '16px', mb: '16px'}} />
                    <CardHeadSubtitle title={'Пересчет'} />
                    <Stack spacing={1}>
                        <RecalcOrder />
                        <FullRecalcOrder />
                        <ForcedRecalcOrder />
                        <ProductSeparationStep />
                    </Stack>
                    <Divider sx={{mt: '16px', mb: '16px'}} />
                    <CardHeadSubtitle title={'Доставка'} />
                    <Stack spacing={1}>
                        <OrderSetShippingDate />
                        <OrderSetShippingType />
                        <OrderSetShippingCost />
                        <OrderSetShippingWeekdays />
                        <OrderSetShippingWarehouse />
                        <OrderSetDirectShipping />
                        <OrderSetShippingAddress />
                    </Stack>
                    <Divider sx={{mt: '16px', mb: '16px'}} />
                    <CardHeadSubtitle title={'Отмена бронирования'} />
                    <Stack spacing={1}>
                        <RequestBookingCancel />
                        <OrderUnbook />
                        <OrderAutoUnbook />
                    </Stack>
                    <Divider sx={{mt: '16px', mb: '16px'}} />
                    <CardHeadSubtitle title={'Параметры'} />
                    <Stack spacing={1}>
                        <OrderSetGost />
                        <OrderSetGostMark />
                        <OrderSetHandle />
                    </Stack>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Реквизиты'} source={'name'} referenceSource={'dealerId'} reference={'dealers'} link={'show'} />
                    <ShowField
                        color={theme.palette.grey['200']}
                        title={'Партнер'}
                        source={'name'}
                        referenceSource={'partnerId'}
                        reference={'partners'}
                        link={'show'}
                    />
                    <ShowField title={'Ид партнера'} source={'id'} referenceSource={'partnerId'} reference={'partners'} link={'show'} />
                    <ShowField
                        color={theme.palette.grey['200']}
                        title={'Контрагент'}
                        source={'name'}
                        referenceSource={'dealerId'}
                        reference={'dealers'}
                        link={'show'}
                    />
                    <ShowField title={'Ид контрагента'} source={'id'} referenceSource={'dealerId'} reference={'dealers'} link={'show'} />
                    <ShowField
                        title={'Код контрагента'}
                        source={'externalId'}
                        referenceSource={'dealerId'}
                        reference={'dealers'}
                        link={'show'}
                    />
                    <ShowField
                        color={theme.palette.grey['200']}
                        title={'Филиал'}
                        source={'name'}
                        referenceSource={'filialId'}
                        reference={'filials'}
                        link={'show'}
                    />
                    <ShowField title={'Ид филиала'} source={'id'} referenceSource={'filialId'} reference={'filials'} link={'show'} />

                    <ShowField
                        color={theme.palette.grey['200']}
                        title={'Пользователь'}
                        source={'email'}
                        referenceSource={'ownerId'}
                        reference={'users'}
                        link={'show'}
                        sx={{mb: 1}}
                    />
                    <ShowField title={'Ид пользователя'} source={'id'} referenceSource={'ownerId'} reference={'users'} link={'show'} />
                    <ShowField title={'Город'} source={'city.name'} />
                </Card>
            </Grid>
        </Grid>
    )
}
