import {useNotify} from 'react-admin'
import {useCallback} from 'react'
import {Alert} from '@mui/material'
import {AlertColor} from '@mui/material/Alert/Alert'

export interface ISnackBarProps {
    type: AlertColor
    msg: string
}
export const useSnackbar = (): ((props: ISnackBarProps) => void) => {
    const notify = useNotify()
    return useCallback(({type, msg}) => notify(<Alert severity={type}>{msg}</Alert>), [notify])
}
